import React, { useState, useEffect } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { updateEmployeeInfo } from "../../services/employeeService";
import { Form, Input, Button, DatePicker, Checkbox, Select } from "antd";
import { ImageDropzone, getCroppedImg } from "../Dropzone/ImageDropzone";
import { RefreshImage } from "../../actions/imageAction";
import avatar from "./default-employee.jpg";
import { putImage } from "../../services/imageService";
import { LeftOutlined } from "@ant-design/icons";
import moment from "moment";
import { getJobLocations, getCountries } from "../../services/locationService";
import LanguageMessages from "../Language/LanguageMessages";
import { withLoader } from "../../components/WithLoader/withLoader";
import { FormatPositions } from "../../Helpers/PositionHelper";
import { FormatCountries } from "../../Helpers/CountryHelper";
import { FormatLocations } from "../../Helpers/LocationHelper";
import "./EmployeeEdit.less";

const EmployeeEdit = props => {
    const languages = useSelector(state => state.languages);
    const [employee, setEmployee] = useState(props.employee);
    const [imageDropped, setImageDropped] = useState(false);
    const [crop, setCrop] = useState(false);
    const [refreshThumb, setRefreshThumb] = useState(false);
    const user = useSelector(state => state.user);
    const [languageOpen, setLanguageOpen] = useState(false);
    const dispatch = useDispatch();
    const [positionOpen, setPositionOpen] = useState(false);
    const [locationOpen, setLocationOpen] = useState(false);
    const positions = useSelector(state => state.positions);
    const employeePositionsIds = props.preferences.positions
        ? props.preferences.positions.map(({ id }) => id)
        : [];
    const [employeePositions, setEmployeePositions] = useState(employeePositionsIds);
    const employeeLocationsIds = props.preferences.locations
        ? props.preferences.locations.map(({ id }) => id)
        : [];
    const [employeeLocations, setEmployeeLocations] = useState(employeeLocationsIds);
    const [jobLocations, setJobLocations] = useState([]);
    const [countries, setCountries] = useState([]);
    const [countryId, setCountryId] = useState();

    const FormItem = Form.Item;
    const { Option } = Select;

    useEffect(() => {
        let fetchJobLocations = () => {
            getJobLocations(user).then(setJobLocations);
        };
        fetchJobLocations();
    }, [user]);

    useEffect(() => {
        props.setLoading(true);
        var country =
            countries.length !== 0 &&
            countries.find(country => country.name === props.employee.country);
        country && country.id && setCountryId(country.id);
        props.setLoading(false);
    }, [countries, props]);

    useEffect(() => {
        getCountries(user).then(res => setCountries(res));
    }, [user]);

    const handleInputChange = e => {
        const { id, value } = e.target;
        setEmployee(prevState => ({
            ...prevState,
            [id]: value,
        }));
    };

    let handleDateOfBirthChange = values => {
        setEmployee({
            ...employee,
            dateOfBirth: values.format("YYYY-MM-DD"),
        });
    };

    let handleVisibiltyChange = event =>
        setEmployee({ ...employee, isVisible: event.target.checked });

    let handleSubmit = async values => {
        if (imageDropped) {
            const imageData = await getCroppedImg(crop, user.info);
            const res = await putImage(user, imageData, null);
            if (res.ok) dispatch(RefreshImage(user.info));
        }
        employee.languages = values.languages;
        employee.positions = employeePositions;
        employee.locations = employeeLocations;
        const res = await updateEmployeeInfo(user, employee);
        if (res.ok) {
            props.refresh();
            props.handleClicks();
        }
    };

    let handleDrop = () => setImageDropped(true);

    let handleCrop = newCrop => setCrop(newCrop);

    let handleRefreshThumb = () => setRefreshThumb(refreshThumb);

    let handleLocationChange = value => {
        var country = countries.find(country => country.id === value);
        setEmployee({ ...employee, country: country.name });
    };

    let handlePositionDropdownOpening = () => {
        if (employeePositions.length <= 2) {
            setPositionOpen(!positionOpen);
        }
    };

    let handleGenderChange = value => setEmployee({ ...employee, gender: value });

    return (
        <div className="employee-edit-container">
            {countryId && (
                <Form
                    onFinish={values => handleSubmit(values)}
                    layout="vertical"
                    className="edit-employee-form"
                    initialValues={{
                        firstName: employee.firstName,
                        lastName: employee.lastName,
                        country: countryId,
                        city: employee.city,
                        postalCode: employee.postalCode,
                        address: employee.address,
                        dateOfBirth: moment(employee.dateOfBirth),
                        gender: employee.gender,
                        email: employee.email,
                        telephone: employee.telephone,
                        isVisible: employee.isVisible,
                        languages: employee.languages.sort(),
                        positions: employeePositionsIds,
                        locations: employeeLocationsIds,
                    }}
                >
                    <FormItem
                        name="firstName"
                        className="form-group-container"
                        label={props.intl.formatMessage({
                            id: "employee.details.firstName",
                            defaultMessage: "First name",
                        })}
                        rules={[
                            {
                                required: true,
                                message: (
                                    <FormattedMessage
                                        id="firstNameMandatory"
                                        defaultMessage="First name is mandatory"
                                    />
                                ),
                            },
                        ]}
                    >
                        <Input
                            className="edit-employee-input-field"
                            value={employee.firstName}
                            onChange={handleInputChange}
                        />
                    </FormItem>
                    <FormItem
                        className="form-group-container"
                        label={props.intl.formatMessage({
                            id: "employee.details.lastName",
                            defaultMessage: "Last name",
                        })}
                        name="lastName"
                        rules={[
                            {
                                required: true,
                                message: (
                                    <FormattedMessage
                                        id="lastNameMandatory"
                                        defaultMessage="Last name is mandatory"
                                    />
                                ),
                            },
                        ]}
                    >
                        <Input
                            className="edit-employee-input-field"
                            value={employee.lastName}
                            onChange={handleInputChange}
                        />
                    </FormItem>
                    <FormItem
                        className="form-group-container"
                        label={props.intl.formatMessage({
                            id: "employee.details.country",
                        })}
                        name="country"
                        rules={[
                            {
                                required: true,
                                message: (
                                    <FormattedMessage
                                        id="countryMandatory"
                                        defaultMessage="Country is mandatory"
                                    />
                                ),
                            },
                        ]}
                    >
                        <Select
                            placeholder={
                                <FormattedMessage
                                    id="selectLocation"
                                    defaultMessage="Select location"
                                />
                            }
                            showArrow={true}
                            onChange={handleLocationChange}
                            showSearch
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                            }
                        >
                            {countries &&
                                FormatCountries([...countries], props.intl).map(country => (
                                    <Option value={country.id} key={country.id}>
                                        {country.name}
                                    </Option>
                                ))}
                        </Select>
                    </FormItem>
                    <FormItem
                        className="form-group-container"
                        label={props.intl.formatMessage({
                            id: "employee.details.city",
                        })}
                        name="city"
                        rules={[
                            {
                                required: true,
                                message: (
                                    <FormattedMessage
                                        id="cityMandatory"
                                        defaultMessage="City is mandatory"
                                    />
                                ),
                            },
                        ]}
                    >
                        <Input
                            className="edit-employee-input-field"
                            value={employee.city}
                            onChange={handleInputChange}
                        />
                    </FormItem>
                    <FormItem
                        className="form-group-container"
                        label={props.intl.formatMessage({
                            id: "employee.details.postalCode",
                        })}
                        name="postalCode"
                        rules={[
                            {
                                required: true,
                                message: (
                                    <FormattedMessage
                                        id="postalCodeMandatory"
                                        defaultMessage="Postal code is mandatory"
                                    />
                                ),
                            },
                        ]}
                    >
                        <Input
                            className="edit-employee-input-field"
                            value={employee.postalCode}
                            onChange={handleInputChange}
                        />
                    </FormItem>

                    <FormItem
                        className="form-group-container"
                        label={props.intl.formatMessage({
                            id: "employee.details.address",
                        })}
                        name="address"
                    >
                        <Input
                            className="edit-employee-input-field"
                            value={employee.address}
                            onChange={handleInputChange}
                        />
                    </FormItem>
                    <FormItem
                        className="form-group-container"
                        name="dateOfBirth"
                        label={<FormattedMessage id="dateOfBirth" defaultMessage="Date of birth" />}
                        rules={[
                            {
                                required: true,
                                message: (
                                    <FormattedMessage
                                        id="dateOfbirthMandatory"
                                        defaultMessage="Date of birth is mandatory"
                                    />
                                ),
                            },
                        ]}
                    >
                        <DatePicker
                            className="dateOfBirth"
                            allowClear={false}
                            format="DD/MM/YYYY"
                            onChange={handleDateOfBirthChange}
                        />
                    </FormItem>
                    <FormItem
                        className="form-group-container"
                        label={<FormattedMessage id="gender" defaultMessage="Gender" />}
                        name="gender"
                    >
                        <Select onChange={handleGenderChange} showArrow={true}>
                            <Option id={0} value={0}>
                                <FormattedMessage id="gender-male" defaultMessage="Male" />
                            </Option>
                            <Option id={1} value={1}>
                                <FormattedMessage id="gender-female" defaultMessage="Female" />
                            </Option>
                            <Option id={2} value={2}>
                                <FormattedMessage
                                    id="gender-other"
                                    defaultMessage="Prefer not to disclose"
                                />
                            </Option>
                        </Select>
                    </FormItem>
                    <FormItem
                        className="form-group-container"
                        label={<FormattedMessage id="email" />}
                        name="email"
                        rules={[
                            {
                                type: "email",
                                required: true,
                                message: <FormattedMessage id="emailMandatory" />,
                            },
                        ]}
                    >
                        <Input
                            className="edit-employee-input-field"
                            value={employee.email}
                            onChange={handleInputChange}
                        />
                    </FormItem>
                    <FormItem
                        className="form-group-container"
                        label={props.intl.formatMessage({
                            id: "employee.details.telephone",
                            defaultMessage: "Telephone",
                        })}
                        name="telephone"
                    >
                        <Input
                            className="edit-employee-input-field"
                            value={employee.telephone}
                            onChange={handleInputChange}
                            type="tel"
                        />
                    </FormItem>

                    <FormItem
                        className="form-group-container"
                        label={props.intl.formatMessage({
                            id: "selectLanguages",
                        })}
                        name="languages"
                    >
                        <Select
                            className="select-container"
                            mode="multiple"
                            placeholder={
                                <FormattedMessage
                                    id="selectLanguages"
                                    defaultMessage="Select languages"
                                />
                            }
                            onClick={() => setLanguageOpen(!languageOpen)}
                            onBlur={() => setLanguageOpen(false)}
                            open={languageOpen}
                            showArrow={true}
                        >
                            {languages.sort().map(lang => (
                                <Option value={lang} key={lang}>
                                    {props.intl.formatMessage(LanguageMessages[lang])}
                                </Option>
                            ))}
                        </Select>
                    </FormItem>

                    <FormItem
                        className="form-group-container"
                        name="positions"
                        label={props.intl.formatMessage({
                            id: "selectPositions",
                        })}
                    >
                        <Select
                            className="select-container"
                            mode="multiple"
                            placeholder={<FormattedMessage id="selectPositions" />}
                            onChange={value => setEmployeePositions(value)}
                            onClick={() => handlePositionDropdownOpening()}
                            onBlur={() => setPositionOpen(false)}
                            open={positionOpen}
                            showArrow={true}
                            showSearch
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {positions &&
                                FormatPositions([...positions], props.intl).map(position => (
                                    <Option value={position.id} key={position.id}>
                                        {position.name}
                                    </Option>
                                ))}
                        </Select>
                    </FormItem>

                    <FormItem
                        className="form-group-container"
                        name="locations"
                        label={props.intl.formatMessage({
                            id: "selectLocation",
                        })}
                    >
                        <Select
                            className="select-container"
                            mode="multiple"
                            placeholder={
                                <FormattedMessage
                                    id="preferredLocation"
                                    defaultMessage="Select preferred locations"
                                />
                            }
                            onChange={value => setEmployeeLocations(value)}
                            onClick={() => setLocationOpen(!locationOpen)}
                            onBlur={() => setLocationOpen(false)}
                            open={locationOpen}
                            showArrow={true}
                            showSearch
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                            }
                        >
                            {jobLocations &&
                                FormatLocations([...jobLocations], props.intl).map(location => (
                                    <Option value={location.id} key={location.id}>
                                        {location.name}
                                    </Option>
                                ))}
                        </Select>
                    </FormItem>

                    <FormItem
                        className="form-group-container-horizontal"
                        name="isVisible"
                        label={
                            <FormattedMessage
                                id="isVisible"
                                defaultMessage="I want my profile to be visible to others"
                            />
                        }
                    >
                        <Checkbox
                            className="checkbox"
                            onChange={handleVisibiltyChange}
                            checked={employee.isVisible}
                        />
                    </FormItem>
                    <div className="image-buttons-container">
                        <ImageDropzone
                            imagePlaceholder={avatar}
                            crop={crop}
                            onDrop={handleDrop.bind(this)}
                            setCrop={handleCrop.bind(this)}
                            refreshThumb={refreshThumb}
                            clearRefreshThumb={handleRefreshThumb.bind(this, false)}
                            image={user.info}
                        />
                        <div className="edit-employee-button">
                            <Button className="plain-button" onClick={props.handleClicks}>
                                <LeftOutlined />
                                <FormattedMessage id="back" />
                            </Button>
                            <Button className="button" htmlType="submit">
                                <FormattedMessage id="submit" />
                            </Button>
                        </div>
                    </div>
                </Form>
            )}
        </div>
    );
};

export default withLoader(injectIntl(EmployeeEdit));
