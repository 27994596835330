import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Image from "../../containers/Image/Image";
import groupAvatar from "../../containers/Conversations/default-group.svg";
import employeeAvatar from "../../containers/Employee/default-employee.jpg";
import companyAvatar from "../../containers/Company/default-company.jpg";
import moment from "moment";
import { withLoader } from "../../components/WithLoader/withLoader";
import "./ConversationAvatar.less";

function formatDateTime(timestamp) {
    let utc = moment.utc(timestamp).toDate();
    let localDate = moment(utc).local();
    let date = new Date(localDate);
    return `${date.getDate()}.${
        date.getMonth() + 1
    }.${date.getFullYear()} ${date.getHours()}:${date.getMinutes().toString().padStart(2, "0")}`;
}

function allMembersOnline(props) {
    return props.appUsers.map(u => u.id).every(i => props.onlineStatus[i]);
}

function ConversationAvatar(props) {
    const info = useSelector(state => state.user.guid);
    let avatar = groupAvatar;
    let people = "";
    let typeOfUser = "";

    if (props.appUsers.length > 2) people = props.id;
    else if (props.appUsers.some(user => user.role === "Employee" && user.id !== info)) {
        if (props.appUsers.filter(user => user.id !== info)[0] !== undefined) {
            const employee = props.appUsers.filter(user => user.id !== info)[0];
            people = employee.employeeId;
            avatar = employeeAvatar;
            typeOfUser = "Employee";
            props.setLoading(false);
        }
    } else {
        if (props.appUsers.filter(user => user.id !== info)[0] !== undefined) {
            const company = props.appUsers.filter(user => user.id !== info)[0];
            people = company.companyId;
            avatar = companyAvatar;
            typeOfUser = "Company";
            props.setLoading(false);
        }
    }

    return (
        <Link to={`/Conversations/Messages/${props.id}`}>
            <div className={`conversation-avatar-container ${props.selected ? "selected" : ""}`}>
                <div
                    className={`conversation-avatar-image ${
                        allMembersOnline(props) ? "online" : ""
                    }`}
                >
                    {props.appUsers.length === 2 ? (
                        <Link to={`/${typeOfUser}/${people}`}>
                            <Image placeholder={avatar} imageId={people} />
                        </Link>
                    ) : (
                        <Image placeholder={avatar} imageId={people} />
                    )}

                    <div className="online-indicator"></div>
                </div>
                <div className="conversation-avatar-info">
                    <p className="conversation-avatar-title">{props.name}</p>
                    <div className="row-container">
                        <p className="last-message-container">
                            {props.lastMessage !== null &&
                            props.lastMessage === "LAST_MSG_DELETED" ? (
                                <em>This message has been deleted</em>
                            ) : props.lastMessage !== null && props.lastMessage.length > 200 ? (
                                `${props.lastMessage.substring(0, 100)}...`
                            ) : props.lastMessage !== null ? (
                                props.lastMessage
                            ) : (
                                ""
                            )}
                        </p>
                        <p className="date-container">
                            {props.lastActivity !== "0001-01-01T00:00:00"
                                ? formatDateTime(props.lastActivity)
                                : ""}
                        </p>
                    </div>
                </div>
            </div>
        </Link>
    );
}

export default withLoader(ConversationAvatar);
