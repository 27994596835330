import { getAuthorizationProperties } from './authorizationService';

export function imageExist(guid) {
    return fetch(`/api/Images/check/${guid}`);
}

export function putImage(user, data, guid) {
    if (guid === null) {
        guid = user.info;
    }
    return fetch(`/api/Images/${guid}`, {
        method: 'PUT',
        ...getAuthorizationProperties(user.token),
        body: data,
    });
}
