import LocationMessages from "../containers/Location/LocationMessages";

export const FormatLocations = (locations, intl) => {
    const newLocations = locations.map(location => {
        let name = intl.formatMessage(LocationMessages[location.name.replace(/\W/g, "")]);
        return { ...location, name };
    });

    return newLocations.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
};
