import { HubConnectionBuilder } from "@microsoft/signalr";
import { SET_USER, LOGOUT } from "../actions/authentificationActions";
import { STORE_CREATED } from "../actions/storeActions";
import {
    addUnreadMsg,
    editMsg,
    deleteMsg,
} from "../actions/conversationActions";

let connection = null;

function connectSignalr(token, dispatch) {
    connection = new HubConnectionBuilder()
        .withUrl("/messageHub", { accessTokenFactory: () => token })
        .withAutomaticReconnect()
        .build();

    connection.on("ReceiveMessage", (msg) => dispatch(addUnreadMsg(msg)));

    connection.on("UpdateMessage", (msg) => dispatch(editMsg(msg)));

    connection.on("DeleteMessage", (msg) => dispatch(deleteMsg(msg)));

    connection
        .start()
        .then(() => {
            connection
                .invoke("AddToUserMsgGroup")
                .catch((err) => console.error(err.toString()));
        })
        .catch((err) => console.error(err.toString()));
}

const signalRMiddleware = (store) => (next) => async (action) => {
    if (action.type === SET_USER && action.user) {
        connectSignalr(action.user.token, store.dispatch);
    }

    if (action.type === STORE_CREATED && sessionStorage.getItem("user")) {
        let user = JSON.parse(sessionStorage.getItem("user"));
        connectSignalr(user.token, store.dispatch);
    }

    if (action.type === LOGOUT && connection) {
        connection.stop();
    }

    return next(action);
};

export default signalRMiddleware;
