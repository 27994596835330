import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getEmployee } from '../../services/employeeService';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from 'antd';
import moment from 'moment';
import './ApplicantCard.less';

function ApplicantCard(props) {
    const [employee, setEmployee] = useState([]);

    useEffect(() => {
        let fetchEmployee = () => {
            getEmployee(props.user, props.employeeGuid).then(e => {
                setEmployee(e);
            });
        };

        fetchEmployee();
    }, [props.user, props.employeeGuid]);

    return (
        <div className="applicant-card-container">
            <div className="title">
                <Link to={`/JobOffer/${props.jobOffer.id}`}>
                    <h3>{props.jobOffer ? props.jobOffer.title : null}</h3>
                </Link>
            </div>
            <div className="applicant-card-info-container">
                <div className="applicant-info">
                    <Row wrap={false}>
                        <Col className="bolded-text" span={10}>
                            <FormattedMessage
                                id="firstAndLastName"
                                defaultMessage="Name"
                            />
                            :
                        </Col>
                        <Col span={10}>
                            <Link to={`/Employee/${props.employeeGuid}`}>
                                <p>
                                    {employee.firstName} {employee.lastName}
                                </p>
                            </Link>
                        </Col>
                    </Row>
                    <Row wrap={false}>
                        <Col className="bolded-text" span={10}>
                            <FormattedMessage
                                id="dateOfApplication"
                                defaultMessage="Date of application"
                            />
                            :
                        </Col>
                        <Col span={10}>
                            {moment(props.created).format('DD/MM/YYYY')}
                        </Col>
                    </Row>
                    <React.Fragment>
                        {props.description ? (
                            <Row wrap={false}>
                                <Col span={10} className="bolded-text">
                                    <FormattedMessage id="noteToEmployer" />:
                                </Col>
                                <Col>
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: props.description,
                                        }}
                                    />
                                </Col>
                            </Row>
                        ) : (
                            <Col></Col>
                        )}
                    </React.Fragment>
                </div>
            </div>
        </div>
    );
}

export default ApplicantCard;
