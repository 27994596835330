import { getAuthorizationProperties } from './authorizationService';

export function refresh(user) {
    const requestOptions = {
        method: "GET",
        ...getAuthorizationProperties(user.token),
    };

    return fetch("users/refresh", requestOptions).then((response) =>
        response.json()
    );
}

export default {
    refresh
};
