import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { Route, Switch } from "react-router-dom";
import Login from "./containers/Login/Login";
import PrivateRoute from "./components/PrivateRoute";
import "./index.less";
import App from "./App";
import configureStore from "./configureStore";
import * as serviceWorker from "./serviceWorker";
import ConnectedIntlProvider from "./components/ConnectedIntlProvider";
import { storeCreated } from "./actions/storeActions";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import ForgotPassword from "./containers/ForgotPassword/ForgotPassword";
import ResetPassword from "./containers/ResetPassword/ResetPassword";
import Registration from "./containers/Register/Forms/Registration";

// Setting up stripe account with public key
const stripePromise = loadStripe(
    "pk_test_51IJzcCCsVyPMoRuOLX7ZcE2aIXGkUWoJENlgAdht42Cm8aUZT5Sd7NHk5VZBiiMh0pEe3dZUFiJNkkM6f36PLssy00WVg4YHDc"
);

const store = configureStore();
store.dispatch(storeCreated());

ReactDOM.render(
    <Elements stripe={stripePromise}>
        <Provider store={store}>
            <ConnectedIntlProvider>
                <Router>
                    <Switch>
                        <Route exact={true} path="/Login" component={Login} />
                        <Route path="/Register" component={Registration} />
                        <Route path="/ForgotPassword" component={ForgotPassword} />
                        <Route path="/ResetPassword" component={ResetPassword} />
                        <PrivateRoute exact={true} path="/" component={App} />
                        <PrivateRoute component={App} />
                    </Switch>
                </Router>
            </ConnectedIntlProvider>
        </Provider>
    </Elements>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
