import React, { useState, useEffect, useCallback } from "react";
import { Badge } from "antd";
import { HubConnectionBuilder } from "@microsoft/signalr";
import { getAllApplicationsCompany } from "../../services/jobApplicationService";
import { useSelector } from "react-redux";
import { AddUnreadJobApplication } from "../../actions/jobApplicationAction";
import { useDispatch } from "react-redux";
import "./UnreadJobAppCounter.less";

function UnreadJobAppCounter(props) {
    const [connection, setConnection] = useState(null);
    const [counter, setCounter] = useState(0);
    const newApplications = useSelector(state => state.jobApplication);
    const user = useSelector(state => state.user);
    const dispatch = useDispatch();

    let getCompanyApplications = useCallback(() => {
        getAllApplicationsCompany(user).then(ja => {
            let unreadJobApps = ja.items.filter(j => j.read !== true);
            unreadJobApps.map(uja => dispatch(AddUnreadJobApplication(uja.guid)));
        });
    }, [user, dispatch]);

    useEffect(() => {
        const newConnection = new HubConnectionBuilder()
            .withUrl("/notificationHub", { accessTokenFactory: () => user.token })
            .withAutomaticReconnect()
            .build();

        setConnection(newConnection);

        getCompanyApplications();
    }, [getCompanyApplications, user.token]);

    useEffect(() => {
        if (connection) {
            connection
                .start()
                .then(() => {
                    connection
                        .invoke("AddToUserNotificationGroup")
                        .catch(err => console.error(err.toString()));
                    connection.on("Notification", guid => {
                        dispatch(AddUnreadJobApplication(guid));
                    });
                })
                .catch(err => console.error(err.toString()));
        }
    }, [connection, dispatch]);

    useEffect(() => {
        let newCount = 0;
        newCount = newApplications.length;
        setCounter(newCount);
    }, [newApplications]);

    return (
        <Badge className={props.className} count={counter}>
            {props.children}
        </Badge>
    );
}

export default UnreadJobAppCounter;
