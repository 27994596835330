export const SET_AUTH = 'SET_AUTH'
export const SENDING_LOGIN_REQUEST = 'SENDING_LOGIN_REQUEST'
export const SENDING_REGISTER_REQUEST = 'SENDING_REGISTER_REQUEST'
export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const REGISTER_REQUEST = 'REGISTER_REQUEST'
export const LOGOUT = 'LOGOUT'
export const REQUEST_LOGIN_ERROR = 'REQUEST_LOGIN_ERROR'
export const CLEAR_LOGIN_ERROR = 'CLEAR_LOGIN_ERROR'
export const SET_USER = 'SET_USER'
export const CLEAR_USER = 'CLEAR_USER'
export const SET_SUBSCRIBING = 'SET_SUBSCRIBING'
export const CLEAR_SUBSCRIBING = 'CLEAR_SUBSCRIBING'

/**
 * Sets the authentication state of the application
 * @param  {boolean} newAuthState True means a user is logged in, false means no user is logged in
 */
export function setAuthState (newAuthState) {
  return {type: SET_AUTH, newAuthState}
}

/**
 * Sets the `currentlySending` state, which displays a loading indicator during requests
 * @param  {boolean} sending True means we're sending a request, false means we're not
 */
export function sendingLoginRequest (sending) {
  return {type: SENDING_LOGIN_REQUEST, sending}
}

/**
 * Sets the `currentlySending` state, which displays a loading indicator during requests
 * @param  {boolean} sending True means we're sending a request, false means we're not
 */
export function sendingRegisterRequest (sending) {
  return {type: SENDING_REGISTER_REQUEST, sending}
}

/**
 * Tells the app we want to log in a user
 * @param  {object} data          The data we're sending for log in
 * @param  {string} data.username The username of the user to log in
 * @param  {string} data.password The password of the user to log in
 */
export function loginRequest (data) {
  return {type: LOGIN_REQUEST, data}
}

/**
 * Tells the app we want to log out a user
 */
export function logout () {
  return {type: LOGOUT}
}

/**
 * Tells the app we want to register a user
 * @param  {object} data          The data we're sending for registration
 * @param  {string} data.username The username of the user to register
 * @param  {string} data.password The password of the user to register
 */
export function registerRequest (data) {
  return {type: REGISTER_REQUEST, data}
}

/**
 * Sets the `error` state to the error received
 * @param  {object} error The error we got when trying to make the request
 */
export function requestError (error) {
  return {type: REQUEST_LOGIN_ERROR, error}
}

/**
 * Sets the `error` state as empty
 */
export function clearLoginError () {
  return {type: CLEAR_LOGIN_ERROR}
}

/**
 * Sets the `user` state
 */
export function setUser (user) {
  return {type: SET_USER, user}
}

/**
 * Sets the `user` state as empty
 */
export function clearUser () {
  return {type: CLEAR_USER}
}

export function setSubscribing() {
  return { type: SET_SUBSCRIBING }
}

export function clearSubscribing() {
  return { type: CLEAR_SUBSCRIBING }
}
