import React, { useState, useEffect } from "react";
import { registerRequest } from "../../../actions/authentificationActions";
import RedirectIfLoggedIn from "../../../components/RedirectIfLoggedIn";
import LoginHeader from "../../../components/LoginHeader/LoginHeader";
import { ReactComponent as UserIcon } from "../Icons/User.svg";
import { ReactComponent as CompanyIcon } from "../Icons/Company.svg";
import { Route, Switch, Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Button } from "antd";
import Employer from "./Employer";
import Employee from "./Employee";
import "../Register.less";

let UserTypeSelect = (
    <div className="user-type-select">
        <div className="registration-button-container">
            <Link to="/Register/User/1">
                <Button type="primary" className="login-button" size="large" block>
                    <UserIcon />
                    User
                </Button>
            </Link>
            <Link to="/Register/Employer/1">
                <Button type="primary" className="login-button" size="large" block>
                    <CompanyIcon />
                    Employer
                </Button>
            </Link>
        </div>
        <p>
            Already have an account? <Link to="/Login">Sign in!</Link>
        </p>
    </div>
);

function Registration() {
    const [text, setText] = useState("");
    const [userType, setUserType] = useState("");
    const [user, setUser] = useState({});
    const [employer, setEmployer] = useState({});
    const location = useLocation();
    const dispatch = useDispatch();

    function setHeaderText(currentPath) {
        if (currentPath.includes("User")) {
            setText("Register user");
            setUserType("User");
        } else if (currentPath.includes("Employer")) {
            setText("Register employer");
            setUserType("Employer");
        } else {
            setText("Register");
            setUserType("");
        }
    }

    useEffect(() => {
        setHeaderText(location.pathname);
    }, [location.pathname]);

    let handleSubmit = data => {
        if (userType === "User") {
            setUser({ ...user, ...data });
            sendForm();
        } else {
            setEmployer({ ...employer, ...data });
            sendForm();
        }
    };

    let sendForm = () => {
        if (userType === "User") {
            sessionStorage.setItem("fromRegister", "true");
            dispatch(registerRequest({ user: user }));
        } else {
            dispatch(registerRequest({ employer: employer }));
        }
    };

    return (
        <div className="container-center">
            <RedirectIfLoggedIn />
            <div id="register-form-container">
                <LoginHeader useIcon={true} texts={[text]} />
                <div className="register-content">
                    <Switch>
                        <Route
                            exact={true}
                            path="/Register/User/1"
                            render={() => <Employee onSubmit={handleSubmit} user={user} />}
                        />
                        <Route
                            exact={true}
                            path="/Register/Employer/1"
                            render={() => <Employer onSubmit={handleSubmit} employer={employer} />}
                        />
                        <Route render={() => UserTypeSelect} />
                    </Switch>
                </div>
            </div>
        </div>
    );
}

export default Registration;
