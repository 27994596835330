import React from 'react';
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import { message } from 'antd';

export function displayMessageToast(msg, success = true, intl = null) {
    let text = intl ? intl.formatMessage({id: msg}) : msg
    text = text.length > 200 ? `${text.substring(0, 150)}...` : text;
    let config = {
        content: (
            <span style={{ marginRight: '10px' }}>{text}</span>
        ),
        icon: success ? <CheckCircleTwoTone twoToneColor="#27aae1"/> : <CloseCircleTwoTone twoToneColor="#d87575"/>
    };
    success ? message.info(config) : message.error(config)
}