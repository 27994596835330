import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Input, Button, DatePicker, Select, Form, Modal, Popover } from 'antd';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import moment from 'moment';
import { LeftOutlined } from '@ant-design/icons';
import { InfoCircleOutlined } from '@ant-design/icons';
import { ReactComponent as Trash } from '../JobApplications/Trash_icon.svg';
import {
    updateEducation,
    deleteEducation,
} from '../../services/educationService';
import './Educations.less';

let messages = defineMessages({
    startDate: {
        id: 'startDate',
        defaultMessage: 'Start date',
    },
    endDate: {
        id: 'endDate',
        defaultMessage: 'End date',
    },
    educatioanlInstitution: {
        id: 'educationalInstitution',
        defaultMessage: 'Educational institution',
    },
    educatioanlInstitutionType: {
        id: 'educationalInstitutionType',
        defaultMessage: 'Type of education',
    },
    university: {
        id: 'university',
        defaultMessage: 'University',
    },
    college: {
        id: 'college',
        defaultMessage: 'College',
    },
    highSchool: {
        id: 'highSchool',
        defaultMessage: 'High school',
    },
    apprenticeship: {
        id: 'apprenticeship',
        defaultMessage: 'Apprenticeship',
    },
});

function EditEducation(props) {
    const user = useSelector(state => state.user);
    const [education, setEducation] = useState(props.education);
    const [visibility, setVisibility] = useState(false);
    const { Option } = Select;
    const FormItem = Form.Item;
    const { RangePicker } = DatePicker;
    const dateFormat = 'YYYY/MM/DD';

    const content = (
        <FormattedMessage
            id="professionalInfo"
            defaultMessage="Professional nurse as defined in Paragraph 71 of the German Social Code (SGB) stating Germany�s Social Law, book #XI, Paragraph 3
        i.e., care specialists, healthcare specialists, qualified nurse, etc. - meaning you earned your profession / specialization / qualification / certification 
        in an apprenticeship or by having studied at a college or university (or equivalent thereof)."
        />
    );

    let setModalVisibility = isVisible => setVisibility(isVisible);

    let onDateChange = values => {
        if (values !== null) {
            setEducation({
                ...education,
                start: values[0].format('YYYY-MM-DD'),
                end: values[1].format('YYYY-MM-DD'),
            });
        }
    };

    let getDate = param => {
        let date = param.split('T');
        return date[0];
    };

    let onInstitutionChange = event => {
        setEducation({
            ...education,
            educationalInstitution: event.target.value,
        });
    };

    let onTypeChange = value => {
        setEducation({
            ...education,
            educationalInstitutionType: value,
        });
    };

    let onProfessionalChange = value => {
        setEducation({
            ...education,
            isProfessional: value,
        });
    };

    let disabledDate = current => {
        return current && current > moment().endOf('day');
    };

    let handleSubmit = () => {
        updateEducation(user, education.guid, education).then(
            props.handleClicks
        );
    };

    let handleDelete = () =>
        deleteEducation(user, education.guid).then(props.handleClicks);

    let onFinish = values => handleSubmit(values);

    return (
        <div className="edit-education-container">
            <Form
                className="edit-education-form"
                onFinish={onFinish}
                layout="vertical"
                initialValues={{
                    institution: props.education.educationalInstitution,
                    professional: props.education.isProfessional,
                    type:
                        props.education.educationalInstitutionType === 0 ? (
                            <FormattedMessage
                                id="educationalInstitutionType.highSchool"
                                defaultMessage="High school"
                            />
                        ) : props.education.educationalInstitutionType === 1 ? (
                            <FormattedMessage
                                id="educationalInstitutionType.College"
                                defaultMessage="College"
                            />
                        ) : (
                            <FormattedMessage
                                id="educationalInstitutionType.University"
                                defaultMessage="University"
                            />
                        ),
                    date: [
                        moment(
                            education.start ? getDate(education.start) : '',
                            'YYYY-MM-DD'
                        ),
                        moment(
                            education.end ? getDate(education.end) : '',
                            'YYYY-MM-DD'
                        ),
                    ],
                }}
            >
                <FormItem
                    name="institution"
                    label={
                        <FormattedMessage
                            id="educationalInstitution"
                            defaultMessage="Educational institution"
                        />
                    }
                    rules={[
                        {
                            required: true,
                            message: (
                                <FormattedMessage id="educationalInstitutionTypeMandatory" />
                            ),
                        },
                    ]}
                >
                    <Input onChange={onInstitutionChange} />
                </FormItem>
                <FormItem
                    name="type"
                    label={
                        <FormattedMessage
                            id="educationalInstitutionType"
                            defaultMessage="Type of education"
                        />
                    }
                    rules={[
                        {
                            required: true,
                            message: (
                                <FormattedMessage
                                    id="educationalInstitutionTypeMandatory"
                                    defaultMessage="Type of institution is mandatory"
                                />
                            ),
                        },
                    ]}
                >
                    <Select
                        showArrow={true}
                        onChange={e => onTypeChange(e)}
                        placeholder={props.intl.formatMessage(
                            messages.educatioanlInstitutionType
                        )}
                    >
                        <Option id={0} value={0}>
                            {props.intl.formatMessage(messages.highSchool)}
                        </Option>
                        <Option id={1} value={1}>
                            {props.intl.formatMessage(messages.college)}
                        </Option>
                        <Option id={2} value={2}>
                            {props.intl.formatMessage(messages.university)}
                        </Option>
                        <Option id={3} value={3}>
                            {props.intl.formatMessage(messages.apprenticeship)}
                        </Option>
                    </Select>
                </FormItem>
                <FormItem
                    name="professional"
                    label={
                        <div>
                            <FormattedMessage
                                id="filter.proffesional"
                                defaultMessage="Proffesional"
                            />
                            <Popover content={content}>
                                <InfoCircleOutlined id="info-icon" />
                            </Popover>
                        </div>
                    }
                >
                    <Select
                        onChange={e => {
                            onProfessionalChange(e)
                        }}
                        showArrow={true}
                    >
                        <Option value={true}>
                            <FormattedMessage id="yes" />
                        </Option>
                        <Option value={false}>
                            <FormattedMessage id="no" />
                        </Option>
                    </Select>
                </FormItem>
                <FormItem
                    name="date"
                    label={<FormattedMessage id="date" />}
                    rules={[
                        {
                            required: true,
                            message: (
                                <FormattedMessage id="dateFieldMandatory" />
                            ),
                        },
                    ]}
                >
                    <RangePicker
                        format="DD/MM/YYYY"
                        value={[
                            moment(education.start, dateFormat),
                            moment(education.end, dateFormat),
                        ]}
                        onChange={onDateChange}
                        disabledDate={disabledDate}
                        allowClear={false}
                    />
                </FormItem>

                <div className="submit-container">
                    <Button
                        className="plain-button"
                        onClick={props.handleClicks}
                    >
                        <LeftOutlined />
                        <FormattedMessage id="back" />
                    </Button>
                    <Button className="button" htmlType="submit">
                        <FormattedMessage id="save" />
                    </Button>
                    <Button
                        className="button"
                        onClick={() => setModalVisibility(true)}
                    >
                        <FormattedMessage id="delete" />
                    </Button>
                    <Modal
                        title={
                            <div className="job-application-modal-title">
                                <Trash className="trash-icon" />
                                <p className="modal-title-paragraph">
                                    <FormattedMessage
                                        id="wantToDeleteEducation"
                                        defaultMessage="Do you want to delete this education?"
                                    />
                                </p>
                            </div>
                        }
                        footer={
                            <div className="job-application-modal-footer">
                                <Button
                                    className="cancel-button-modal"
                                    onClick={() => setModalVisibility(false)}
                                >
                                    <FormattedMessage id="conversations.cancel" />
                                </Button>
                                <Button
                                    className="button"
                                    onClick={handleDelete}
                                >
                                    <FormattedMessage id="delete" />
                                </Button>
                            </div>
                        }
                        bodyStyle={{ padding: '18px' }}
                        centered
                        closable={false}
                        visible={visibility}
                        className="job-application-modal"
                    >
                        <p>
                            <FormattedMessage
                                id="ifYouDeleteExperience"
                                defaultMessage="Deleting education will remove education
                                permanently"
                            />
                        </p>
                    </Modal>
                </div>
            </Form>
        </div>
    );
}

export default injectIntl(EditEducation);
