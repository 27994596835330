import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Row, Col } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import JobPrefenecesMessages from '../JobPreference/JobPrefenecesMessages';
import LocationMessages from '../Location/LocationMessages';
import './ExperienceCard.less';

function ExperienceCard(props) {
    const user = useSelector(state => state.user);

    return (
        <div className="experiences-container">
            <div
                className="experience-cards-container"
                key={props.experience.id}
            >
                <Row wrap={false}>
                    <Col span={7}>
                        <p className="bolded-text">
                            <FormattedMessage id="position" />:
                        </p>
                    </Col>
                    <Col>
                        <p>
                            {props.intl.formatMessage(
                                JobPrefenecesMessages[
                                    props.experience.positionName.replace(
                                        /\W/g,
                                        ''
                                    )
                                ]
                            )}
                        </p>
                    </Col>
                </Row>
                <Row wrap={false}>
                    <Col span={7}>
                        <p className="bolded-text">
                            <FormattedMessage
                                id="companyName"
                                defaultMessage="Company"
                            />
                            :
                        </p>
                    </Col>
                    <Col>
                        <p>{props.experience.companyName}</p>
                    </Col>
                </Row>
                <Row wrap={false}>
                    <Col span={7}>
                        <p className="bolded-text">
                            <FormattedMessage id="location" />:
                        </p>
                    </Col>
                    <Col>
                        <p>
                            {props.intl.formatMessage(
                                LocationMessages[
                                    props.experience.companyCity.replace(
                                        /\W/g,
                                        ''
                                    )
                                ]
                            )}
                        </p>
                    </Col>
                </Row>

                <div className="line" />
                <div className="edit-button">
                    <Button
                        className="button"
                        onClick={props.handleExperienceDetails.bind(
                            this,
                            props.experience.id
                        )}
                    >
                        <FormattedMessage
                            id="seeDetails"
                            defaultMessage="See details"
                        />
                    </Button>
                    {user.role === 'Employee' &&
                    user.info == props.employeeId ? (
                        <Button
                            className="button"
                            onClick={props.handleEdit.bind(
                                this,
                                props.experience.id
                            )}
                        >
                            <FormattedMessage
                                id="experience.card.editExperience"
                                defaultMessage="Edit"
                            />
                        </Button>
                    ) : (
                        ''
                    )}
                </div>
            </div>
        </div>
    );
}

export default injectIntl(ExperienceCard);
