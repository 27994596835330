import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../actions/authentificationActions";
import { Drawer, Select, Button, Modal, Input } from "antd";
import { ReactComponent as Logo } from "./Cogo_logo.svg";
import { ReactComponent as SignOutIcon } from "./SignOutIcon.svg";
import EmployeeAvatar from "../EmployeeAvatar/EmployeeAvatar";
import CompanyAvatar from "../CompanyAvatar/CompanyAvatar";
import { getEmployee } from "../../services/employeeService";
import { getCompany } from "../../services/companyService";
import { useHistory } from "react-router-dom";
import { RemoveUnreadJobApplications } from "../../actions/jobApplicationAction";
import { defineMessages, FormattedMessage, injectIntl } from "react-intl";
import {
    changeUserLanguage,
    deleteAccount,
    updatePassword,
} from "../../services/autentificationService";
import { setLanguage } from "../../actions/userActions";
import "./CustomHeader.less";
import { DeleteOutlined, LockOutlined } from "@ant-design/icons";
import { displayMessageToast } from "../../Helpers/ToastHelper";

const messages = defineMessages({
    oldPassword: {
        id: "oldPassword",
        defaultMessage: "Type in old password",
    },
    newPassword: {
        id: "newPassword",
        defaultMessage: "Type in new password",
    },
    repeatNewPassword: {
        id: "repeatNewPassword",
        defaultMessage: "Repeat new password",
    },
});

const CustomHeader = props => {
    const user = useSelector(state => state.user);
    const menuOpen = useSelector(state => state.menu);
    const dispatch = useDispatch();
    const history = useHistory();
    const [userName, setUserName] = useState();
    const [drawerVisibility, setDrawerVisibility] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(user.language);
    const [deleteAccountModalOpen, setDeleteAccountModalOpen] = useState(false);
    const [password, setPassword] = useState("");
    const [showDeleteAccountError, setShowDeleteAccountError] = useState(false);
    const [changePasswordModalOpen, setChangePasswordModalOpen] = useState(false);
    const [newPassword1, setNewPassword1] = useState("");
    const [newPassword2, setNewPassword2] = useState("");
    const [passwordsDontMatch, setPasswordsDontMatch] = useState(false);

    const handleUpdatePassword = async () => {
        const userDto = {
            username: user.username,
            password: password,
            newPassword: newPassword1,
        };
        const res = await updatePassword(user, userDto);
        if (!res.ok) setShowDeleteAccountError(true);
        else {
            displayMessageToast("passwordChanged", true, props.intl);
            handleCloseChangePasswordModal();
        }
    };

    const handleCloseChangePasswordModal = () => {
        setChangePasswordModalOpen(false);
        setPassword("");
        setNewPassword1("");
        setNewPassword2("");
    };

    const handleDeleteAccount = async () => {
        const userDto = { username: user.username, password: password };
        const res = await deleteAccount(user, userDto);
        if (!res.ok) {
            displayMessageToast("wrongPassword", false, props.intl);
            setShowDeleteAccountError(true);
        } else dispatch(logout());
    };

    const handleCloseDeleteProfileModal = () => {
        setDeleteAccountModalOpen(false);
        setPassword("");
    };

    let handleLogout = e => {
        e.preventDefault();
        dispatch(RemoveUnreadJobApplications());
        dispatch(logout());
    };

    let onLogoClick = e => {
        sessionStorage.setItem("activeComp", "Home");
        e.preventDefault();
        history.push("/Home");
    };

    let showDrawer = () => setDrawerVisibility(true);

    let closeDrawer = () => setDrawerVisibility(false);

    let handleLangugageChange = language => {
        setSelectedLanguage(language);
        if (language !== user.language)
            changeUserLanguage(user, language).then(() => {
                dispatch(setLanguage(language));
                sessionStorage.setItem("user", JSON.stringify({ ...user, language: language }));
            });
    };

    useEffect(() => {
        let fetchUser = () => {
            if (user.role === "Employee" || user.role === "Admin")
                getEmployee(user, user.info).then(e => setUserName(e.firstName + " " + e.lastName));
            else getCompany(user, user.info).then(c => setUserName(c.name));
        };
        fetchUser();
    }, [user]);

    useEffect(() => {
        if (showDeleteAccountError) setShowDeleteAccountError(false);
    }, [password, showDeleteAccountError]);

    useEffect(() => {
        if (newPassword1 !== "" && newPassword2 !== "" && newPassword1 !== newPassword2)
            setPasswordsDontMatch(true);
        else setPasswordsDontMatch(false);
    }, [newPassword1, newPassword2]);

    return (
        <div className={"flex-header " + (menuOpen ? "menu-open" : "")}>
            <div className="logo" onClick={onLogoClick}>
                <Logo id="header-logo" />
            </div>
            <div className="avatar-container">
                <div className="avatar-wrapper" onClick={showDrawer}>
                    <p id="header-username">{userName}</p>
                    <div id="header-avatar" className="avatar">
                        {user.role === "Employee" ? (
                            <EmployeeAvatar />
                        ) : (
                            <CompanyAvatar companyId={user.info} />
                        )}
                    </div>
                </div>
                <Drawer
                    placement="right"
                    closable
                    onClose={closeDrawer}
                    visible={drawerVisibility}
                    width={400}
                >
                    <div className="drawer">
                        <div className="custom-drawer-container">
                            <div className="drawer-avatar">
                                {user.role === "Employee" ? (
                                    <EmployeeAvatar />
                                ) : (
                                    <CompanyAvatar companyId={user.info} />
                                )}
                            </div>
                            <React.Fragment>
                                <p className="fullname">{userName}</p>
                            </React.Fragment>
                            <React.Fragment>
                                <p className="username">
                                    <FormattedMessage id="username" />: {user.username}
                                </p>
                            </React.Fragment>
                            <div className="language-selector-container">
                                <div className="row-data-header">
                                    <FormattedMessage
                                        id="employee.details.language"
                                        defaultMessage="Choose language"
                                    />
                                </div>
                                <Select
                                    className="language-selector-drawer"
                                    value={selectedLanguage}
                                    showArrow={true}
                                    onChange={handleLangugageChange}
                                >
                                    <Select.Option value="en" key="en">
                                        EN
                                    </Select.Option>
                                    <Select.Option value="de" key="de">
                                        DE
                                    </Select.Option>
                                    <Select.Option value="hr" key="hr">
                                        HR
                                    </Select.Option>
                                </Select>
                            </div>
                        </div>
                        <div className="account-options-container">
                            <Button
                                className="button"
                                size="large"
                                onClick={() => setChangePasswordModalOpen(true)}
                            >
                                <LockOutlined className="sign-out-icon" />
                                <FormattedMessage
                                    id="changePassword"
                                    defaultMessage="Change password"
                                />
                            </Button>
                            <Button
                                className="button"
                                size="large"
                                onClick={() => setDeleteAccountModalOpen(true)}
                            >
                                <DeleteOutlined className="sign-out-icon" />
                                <FormattedMessage
                                    id="deleteAccount"
                                    defaultMessage="Delete account"
                                />
                            </Button>
                        </div>
                        <div className="log-out-container">
                            <Button
                                className="button"
                                id="log-out-button"
                                size="large"
                                onClick={handleLogout}
                            >
                                <SignOutIcon className="sign-out-icon" />
                                <FormattedMessage id="logOut" defaultMessage="Log out" />
                            </Button>
                        </div>
                    </div>
                </Drawer>
            </div>

            <Modal
                title={
                    <p className="drawer-modal-title-paragraph">
                        <FormattedMessage id="changePassword" defaultMessage="Change password" />
                    </p>
                }
                footer={
                    <div className="job-application-modal-footer">
                        <Button
                            className="cancel-button-modal"
                            onClick={handleCloseChangePasswordModal}
                        >
                            <FormattedMessage id="conversations.cancel" />
                        </Button>
                        <Button className="button" onClick={handleUpdatePassword}>
                            <FormattedMessage id="save" />
                        </Button>
                    </div>
                }
                bodyStyle={{ padding: "18px" }}
                centered
                closable
                visible={changePasswordModalOpen}
                className="job-application-modal"
                onCancel={handleCloseChangePasswordModal}
            >
                <div className="change-password-inputs">
                    <Input.Password
                        onChange={e => setPassword(e.target.value)}
                        value={password}
                        placeholder={props.intl.formatMessage(messages.oldPassword)}
                    />
                    <Input.Password
                        onChange={e => setNewPassword1(e.target.value)}
                        value={newPassword1}
                        placeholder={props.intl.formatMessage(messages.newPassword)}
                    />
                    <Input.Password
                        onChange={e => setNewPassword2(e.target.value)}
                        value={newPassword2}
                        placeholder={props.intl.formatMessage(messages.repeatNewPassword)}
                    />
                </div>
                {passwordsDontMatch && (
                    <p id="delete-password-message">
                        <FormattedMessage
                            id="passDontMatch"
                            defaultMessage="New passwords don't match"
                        />
                    </p>
                )}
                {showDeleteAccountError && (
                    <p id="delete-password-message">
                        <FormattedMessage
                            id="wrongPassword"
                            defaultMessage="Password incorrect, please try again"
                        />
                    </p>
                )}
            </Modal>

            <Modal
                title={
                    <p className="drawer-modal-title-paragraph">
                        <FormattedMessage
                            id="deleteProfileTitle"
                            defaultMessage="Are you sure you want to delete your account?"
                        />
                    </p>
                }
                footer={
                    <div className="job-application-modal-footer">
                        <Button
                            className="cancel-button-modal"
                            onClick={handleCloseDeleteProfileModal}
                        >
                            <FormattedMessage id="conversations.cancel" />
                        </Button>
                        <Button className="button" onClick={handleDeleteAccount}>
                            <FormattedMessage id="delete" />
                        </Button>
                    </div>
                }
                bodyStyle={{ padding: "18px" }}
                centered
                closable
                visible={deleteAccountModalOpen}
                className="job-application-modal"
                onCancel={handleCloseDeleteProfileModal}
            >
                <p>
                    <FormattedMessage
                        id="deleteProfileMessage"
                        defaultMessage="To perform this action, please type in your password in the field below."
                    />
                </p>
                <Input.Password onChange={e => setPassword(e.target.value)} value={password} />
                {showDeleteAccountError && (
                    <p id="delete-password-message">
                        <FormattedMessage
                            id="wrongPassword"
                            defaultMessage="Password incorrect, please try again"
                        />
                    </p>
                )}
            </Modal>
        </div>
    );
};

export default injectIntl(CustomHeader);
