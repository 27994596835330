import React from 'react';
import { Switch } from 'react-router-dom'
import PrivateRoute from '../../components/PrivateRoute'
import ConversationsList from './ConversationsList';
import Conversation from './Conversation';
import './Conversations.less';

function Conversations(props) {
    return (
        <Switch>
            <PrivateRoute path='/Conversations/Messages/' component={Conversation} />
            <PrivateRoute component={ConversationsList} />
        </Switch>
    );
}

export default Conversations;
