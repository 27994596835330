import LocationMessages from '../containers/Location/LocationMessages';

export const FormatCountries = (countries, intl) => {
    const newCountries = countries.map(country => {
        let name = intl.formatMessage(
            LocationMessages[country.name.replace(/\W/g, '').replace('-', '')]
        );
        return { ...country, name };
    });

    return newCountries.sort((a, b) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase())
    );
};
