import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Row, Col, Modal, Tooltip } from "antd";
import CompanyAvatar from "../CompanyAvatar/CompanyAvatar";
import { FormattedMessage } from "react-intl";
import SubscriptionModal from "./SubscriptionModal";
import BuyUnlocksModal from "./BuyUnlocksModal";
import ModifyCreditCardModal from "./ModifyCreditCardModal";
import { withLoader } from "../../components/WithLoader/withLoader";
import { unsubscribe } from "../../actions/companyActions";
import "./CompanyDetails.less";

function CompanyDetails(props) {
    const [showSubscribeModal, setShowSubscribeModal] = useState(false);
    const [showUnsubscribeModal, setShowUnsubscribeModal] = useState(false);
    const [showBuyUnlocksModal, setShowBuyUnlocksModal] = useState(false);
    const [showModifyCreditCardModal, setShowModifyCreditCardModal] = useState(false);

    const user = useSelector(state => state.user);
    const login = useSelector(state => state.login);
    const dispatch = useDispatch();

    useEffect(() => {
        props.setLoading(login.currentlySubscribing);
    }, [login, props]);

    const handleUnsubscribe = () => {
        dispatch(unsubscribe(user, props.companyId));
        setShowUnsubscribeModal(false);
    };

    return (
        <div className="company-data-container">
            <div className="company-data">
                <div className="company-avatar-centered">
                    <h2>{props.company.name}</h2>
                    <CompanyAvatar companyId={props.companyId} />
                </div>
                <div className="company-info-container">
                    <div className="company-info">
                        <Row wrap={false}>
                            <Col span={7} className="bolded-text">
                                <FormattedMessage id="employee.details.country" />:
                            </Col>
                            <Col>{props.company.country}</Col>
                        </Row>
                        <Row wrap={false}>
                            <Col span={7} className="bolded-text">
                                <FormattedMessage id="employee.details.city" />:
                            </Col>
                            <Col>{props.company.city}</Col>
                        </Row>
                        <Row wrap={false}>
                            <Col span={7} className="bolded-text">
                                <FormattedMessage id="employee.details.address" />:
                            </Col>
                            <Col>{props.company.address}</Col>
                        </Row>
                        <Row wrap={false}>
                            <Col span={7} className="bolded-text">
                                <FormattedMessage
                                    id="employee.details.telephone"
                                    defaultMessage="Telephone"
                                />
                                :
                            </Col>
                            <Col>{props.company.telephone}</Col>
                        </Row>
                        <Row wrap={false}>
                            <Col span={7} className="bolded-text">
                                Email:
                            </Col>
                            <Col>{props.company.contactEmail}</Col>
                        </Row>
                        <Row wrap={false}>
                            <Col span={7} className="bolded-text">
                                <FormattedMessage id="website" defaultMessage="Website" />:
                            </Col>
                            <Col>
                                <a
                                    href={props.company.websiteLink}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {props.company.websiteLink}
                                </a>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="company-info-container">
                    <div className="company-info">
                        <Row wrap={false}>
                            <Col span={10} className="bolded-text">
                                <FormattedMessage id="subscription" defaultMessage="Subscription" />
                                :
                            </Col>
                            <Col>
                                {user.subscriptionStatus && user.subscription ? (
                                    user.subscription
                                ) : (
                                    <FormattedMessage
                                        id="noSubscription"
                                        defaultMessage="no subscription"
                                    />
                                )}
                            </Col>
                        </Row>
                        {user.subscriptionStatus && user.subscriptionStatus !== "no_subscription" && (
                            <Row wrap={false}>
                                <Col span={10} className="bolded-text">
                                    <FormattedMessage
                                        id="subscriptionStatus"
                                        defaultMessage="Subscription status"
                                    />
                                    :
                                </Col>
                                <Col>
                                    {user.subscriptionStatus === "incomplete" ||
                                    user.subscriptionStatus === "past_due" ? (
                                        <Tooltip
                                            title={
                                                <FormattedMessage
                                                    id="goToPayments"
                                                    defaultMessage="Go to payments tab to complete subscription"
                                                />
                                            }
                                        >
                                            <span className="hoverable" onClick={props.goPayments}>
                                                {user.subscriptionStatus}
                                            </span>
                                        </Tooltip>
                                    ) : (
                                        user.subscriptionStatus
                                    )}
                                </Col>
                            </Row>
                        )}
                        {user.hasCreditCardInfo === "True" && props.company && (
                            <Row wrap={false}>
                                <Col span={10} className="bolded-text">
                                    <FormattedMessage
                                        id="creditCard"
                                        defaultMessage="Credit card"
                                    />
                                    :
                                </Col>
                                <Col>
                                    {user.needsConfirmation === "True" ? 
                                        <Tooltip
                                            title={
                                                <FormattedMessage
                                                    id="completeCardSetupInstructions"
                                                    defaultMessage="Go to 'Modify credit card' to complete the setup for your new card"
                                                />
                                            }
                                        >
                                            <span className="hoverable error-text">
                                                <FormattedMessage
                                                    id="setupIncomplete"
                                                    defaultMessage="setup incloplete"/>
                                            </span>
                                        </Tooltip> :
                                        `${props.company.cardBrand} **** ${props.company.lastDigits}`
                                    }
                                </Col>
                            </Row>
                        )}
                        {user.subscriptionStatus === "active" && props.company && (
                            <React.Fragment>
                                <Row wrap={false}>
                                    <Col span={10} className="bolded-text">
                                        <FormattedMessage
                                            id="nextPayment"
                                            defaultMessage="Next payment"
                                        />
                                        :
                                    </Col>
                                    <Col>{user.nextPayment}</Col>
                                </Row>
                                <Row wrap={false}>
                                    <Col span={10} className="bolded-text">
                                        <FormattedMessage
                                            id="spentUnlocks"
                                            defaultMessage="Spent unlocks"
                                        />
                                        :
                                    </Col>
                                    <Col>
                                        {props.company.spentUnlocks} / {props.company.totalUnlocks}
                                    </Col>
                                </Row>
                            </React.Fragment>
                        )}
                    </div>
                </div>
            </div>
            <div className="line" />
            {props.companyId === user.info ? (
                <div className="edit-profile-container">
                    <Button className="button" onClick={props.handleClicks}>
                        <FormattedMessage id="edit" />
                    </Button>
                    {user.subscriptionStatus && user.subscription ? (
                        <React.Fragment>
                            {user.subscriptionStatus === "active" && (
                                <React.Fragment>
                                    <Button
                                        className="button"
                                        onClick={setShowSubscribeModal.bind(null, true)}
                                    >
                                        <FormattedMessage
                                            id="editSubscription"
                                            defaultMessage="Edit subscription"
                                        />
                                    </Button>
                                    <Button
                                        className="button"
                                        onClick={setShowBuyUnlocksModal.bind(null, true)}
                                    >
                                        <FormattedMessage
                                            id="buyUnlocks"
                                            defaultMessage="Buy unlocks"
                                        />
                                    </Button>
                                </React.Fragment>
                            )}
                            <Button
                                loading={login.currentlySubscribing}
                                className="button"
                                onClick={setShowUnsubscribeModal.bind(null, true)}
                            >
                                <FormattedMessage id="unsubscribe" defaultMessage="Unsubscribe" />
                            </Button>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <Button
                                className="button"
                                onClick={setShowSubscribeModal.bind(null, true)}
                            >
                                <FormattedMessage id="subscribe" defaultMessage="Subscribe" />
                            </Button>
                        </React.Fragment>
                    )}
                    {user.hasCreditCardInfo === "True" && (
                        <Button className="button" onClick={setShowModifyCreditCardModal.bind(null, true)}>
                            <FormattedMessage
                                id="modifyCreditCard"
                                defaultMessage="Modify credit card"
                            />
                        </Button>
                    )}
                </div>
            ) : (
                ""
            )}
            {!showModifyCreditCardModal && <SubscriptionModal
                show={showSubscribeModal}
                close={setShowSubscribeModal.bind(null, false)}
                company={props.company}
            />}
            <BuyUnlocksModal
                show={showBuyUnlocksModal}
                close={setShowBuyUnlocksModal.bind(null, false)}
                companyId={props.company.id}
                setLoading={props.setLoading}
                setCompany={props.setCompany}
            />
            {!showSubscribeModal && <ModifyCreditCardModal
                show={showModifyCreditCardModal}
                close={setShowModifyCreditCardModal.bind(null, false)}
                needsConfirmation={user.needsConfirmation === "True"}
                companyId={props.company.id}
            />}
            <Modal
                visible={showUnsubscribeModal}
                title={<FormattedMessage id="unsubscribeModalTitle" defaultMessage="Unsubscribe" />}
                centered
                closable
                onCancel={setShowUnsubscribeModal.bind(null, false)}
                onOk={handleUnsubscribe}
            >
                <FormattedMessage
                    id="unsubscribeModalContent"
                    defaultMessage="Are you sure you want to cancel your subscription?"
                />
            </Modal>
        </div>
    );
}

export default withLoader(CompanyDetails);
