import React, { useState, useEffect, createRef } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import { LoadingIndicator } from "../../components/WithLoader/withLoader";
import InfiniteScroll from "react-infinite-scroller";
import moment from "moment";
import "moment/locale/hr";
import "moment/locale/de";
import {
    getMessages,
    postMessage,
    getOlderMessages,
    getNewerMessages,
    deleteMessage,
} from "../../services/messagesService";
import { getConversation } from "../../services/conversationsService";
import {
    clearConversationUnreadMsg
} from "../../actions/conversationActions";
import { ReactComponent as Send } from "./Send_icon.svg";
import Message from "../Message/Message";
import ConversationsList from "./ConversationsList";
import "./Conversations.less";

const CHAT_MSG_BATCH_SIZE = 50;

function Conversation() {
    const [conversation, setConversation] = useState({});
    const [conversationsLoaded, setConversationsLoaded] = useState(false);
    const [moreMessagesExist, setMoreMessagesExist] = useState(true);
    const [bottomScrollLock, setBottomScrollLock] = useState(true);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState("");
    const user = useSelector((state) => state.user);
    const conversations = useSelector((state) => state.conversations);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [selectedMessage, setSelectedMessage] = useState();

    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();

    const scrollParentRef = createRef();

    let sendMsg = () => {
        if (newMessage.length > 0) {
            let msg = { message: newMessage };
            postMessage(user, conversation.id, msg)
                .then(() => getMessages(user, conversation.id))
                .then((res) => {
                    setMessages(res);
                    dispatch(
                        clearConversationUnreadMsg(
                            location.pathname.split("/")[3]
                        )
                    );
                });
            setNewMessage("");
        }
    };

    let onEnterPress = (e) => {
        if (e.keyCode === 13 && e.shiftKey === false) {
            e.preventDefault();
            sendMsg();
        }
    };

    let onNewMsgChange = (e) => setNewMessage(e.target.value);

    let datetime = (lang) => {
        moment.locale(lang);
        let utc = moment.utc(conversation.created).toDate();
        let timestamp = moment(utc).local();
        if (lang == "en") return timestamp.format("MMMM Do YYYY, h:mm a");
        else if (lang === "hr") return timestamp.format("DD. MMMM YYYY. HH:mm");
        else if (lang === "de")
            return timestamp.format("DD. MMMM YYYY. HH:mm") + " Uhr";
    };

    useEffect(() => {
        let convo = location.pathname.split("/")[3];
        getMessages(user, convo)
            .then((data) => {
                setMessages(data);
                setTimeout(() => {
                    setConversationsLoaded(true);
                }, 2000);

                if (data.length < CHAT_MSG_BATCH_SIZE)
                    setMoreMessagesExist(false);
            })
            .then(() => dispatch(clearConversationUnreadMsg(convo)));
        getConversation(user, convo).then((c) => {
            setConversation(c);
            setMoreMessagesExist(true);
        });
    }, [user, location.pathname]);

    useEffect(() => {
        let convo = location.pathname.split("/")[3];
        if (conversations[convo] && messages.length > 0) {
            if (conversations[convo].some((x) => !x.deleted && !x.edited)) {
                getNewerMessages(
                    user,
                    convo,
                    messages[messages.length - 1].id
                ).then((data) => {
                    let newMessages = [...messages, ...data];
                    setMessages(newMessages);
                    dispatch(clearConversationUnreadMsg(convo));
                });
            } else if (conversations[convo].some((x) => x.edited)) {
                let newMessages = [...messages];
                const editedMsgs = conversations[convo].filter((x) => x.edited);
                editedMsgs.forEach((editedMsg) => {
                    let msg = newMessages.find((x) => x.id === editedMsg.id);
                    if (msg) {
                        msg.edited = true;
                        msg.message = editedMsg.message;
                    }
                });
                setMessages(newMessages);
                dispatch(clearConversationUnreadMsg(convo));
            } else if (conversations[convo].some((x) => x.deleted)) {
                let newMessages = [...messages];
                const deletedMsgs = conversations[convo].filter(
                    (x) => x.deleted
                );
                deletedMsgs.forEach((deletedMsg) => {
                    let msg = newMessages.find((x) => x.id == deletedMsg.id);
                    if (msg) msg.deleted = true;
                });
                setMessages(newMessages);
                dispatch(clearConversationUnreadMsg(convo));
            }
        }
    }, [conversations]);

    useEffect(() => {
        if (bottomScrollLock && scrollParentRef.current) {
            scrollParentRef.current.scrollTop =
                scrollParentRef.current.scrollHeight;
        }
    }, [messages, scrollParentRef.current]);

    let loadMoreMsg = () => {
        if (conversationsLoaded) {
            let convo = location.pathname.split("/")[3];
            let first = messages[0] ? messages[0].id : null;

            if (first) {
                getOlderMessages(user, convo, messages[0].id).then((data) => {
                    let newMessages = [...data, ...messages];
                    setMessages(newMessages);

                    if (data.length < CHAT_MSG_BATCH_SIZE)
                        setMoreMessagesExist(false);
                });
            }
        }
    };

    let handleScroll = (e) => {
        const bottom =
            e.target.scrollHeight - e.target.scrollTop ===
            e.target.clientHeight;
        if (bottom) setBottomScrollLock(true);
        else setBottomScrollLock(false);
    };

    let CogoLoader = () => (
        <div className="cogo-loader-container">
            <LoadingIndicator width={150} />
        </div>
    );

    const handleSelectMessage = (message) => {
        setSelectedMessage(message);
        setDeleteModalOpen(true);
    };

    const handleDeleteMessage = async (message) => {
        await deleteMessage(user, conversation.id, message);
        let newMessages = [...messages];
        let msg = newMessages.find((x) => x.id == message.id);
        if (msg) {
            msg.edited = false;
            msg.deleted = true;
        }
        setMessages([...newMessages]);
        setDeleteModalOpen(false);
    };

    const handleCancelDeleteMessage = () => {
        setSelectedMessage(undefined);
        setDeleteModalOpen(false);
    };

    return (
        <div className="conversation-container">
            <div className="conversations-list-part">
                <ConversationsList
                    sidebar={true}
                    selected={location.pathname.split("/")[3]}
                />
                <Button
                    className="plain-button"
                    onClick={() => history.push("/Conversations")}
                >
                    <LeftOutlined />
                    <FormattedMessage id="back" />
                </Button>
            </div>
            <div className="conversation-messages-part">
                <div className="title-container">
                    <h2 id="conversation-title">{conversation.name} </h2>
                    <Button
                        className="plain-button"
                        onClick={() => history.push("/Conversations")}
                    >
                        <LeftOutlined />
                        <FormattedMessage id="back" />
                    </Button>
                </div>
                <div
                    className="messages-container"
                    onScroll={handleScroll}
                    ref={scrollParentRef}
                >
                    <InfiniteScroll
                        loadMore={loadMoreMsg}
                        hasMore={moreMessagesExist}
                        useWindow={false}
                        isReverse={true}
                        initialLoad={true}
                        loader={<CogoLoader key={new Date().getTime()} />}
                    >
                        {!moreMessagesExist && (
                            <p className="conversation-start-indicator">
                                {datetime(user.language)}
                            </p>
                        )}
                        {messages.map((message, i) => (
                            <Message
                                {...message}
                                key={message.id}
                                isGroup={conversation.appUsers.length}
                                showSender={
                                    i - 1 >= 0 &&
                                    messages[i - 1].senderName !==
                                        message.senderName
                                }
                                handleSelectMessage={handleSelectMessage}
                            />
                        ))}
                    </InfiniteScroll>
                </div>
                <div className="send-message-container">
                    <textarea
                        onChange={onNewMsgChange}
                        value={newMessage}
                        onKeyDown={onEnterPress}
                    />
                    <div className="send-icon">
                        <Send
                            className={`send-icon-available ${
                                newMessage.length === 0 ? "disabled" : ""
                            }`}
                            onClick={sendMsg}
                        />
                    </div>
                </div>
            </div>
            <Modal
                title={
                    <div className="delete-conversation-modal-container">
                        <div className="modal-title-paragraph">
                            <FormattedMessage
                                id="confirmDeleteTitle"
                                defaultMessage="Are you sure?"
                            />
                        </div>
                    </div>
                }
                footer={
                    <div className="delete-conversation-modal-footer">
                        <Button
                            className="cancel-button-modal"
                            onClick={handleCancelDeleteMessage}
                        >
                            <FormattedMessage
                                id="conversations.cancel"
                                defaultMessage="Delete"
                            />
                        </Button>
                        <Button
                            className="button"
                            onClick={() => handleDeleteMessage(selectedMessage)}
                        >
                            <FormattedMessage
                                id="delete"
                                defaultMessage="Delete"
                            />
                        </Button>
                    </div>
                }
                visible={deleteModalOpen}
                centered
                closable={false}
                className="delete-conversation-modal"
            >
                <p>
                    <FormattedMessage
                        id="confirmDeleteMessage"
                        defaultMessage="Delete"
                    />
                </p>
            </Modal>
        </div>
    );
}

export default injectIntl(Conversation);
