import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Row, Col, Card, Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { addPaymentInfo } from '../../services/companyService';
import { modifySubscription, subscribe } from '../../actions/companyActions';
import CardSection from '../../components/CardSection/CardSection';
import './SubscriptionModal.less';
const { Meta } = Card;

export default function SubscriptionModal(props) {
    const [subscription, setSubscription] = useState('');
    const [period, setPeriod] = useState("Monthly");
    const [creditCardError, setCreditCardError] = useState('');
    const [chooseSub, setChooseSub] = useState('');
    const user = useSelector(state => state.user);
    const login = useSelector(state => state.login);
    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useDispatch();

    useEffect(() => {
        if (user && user.subscription) {
            setSubscription(user.subscription);
            setPeriod(user.subscriptionPeriod);
        }
    }, [user]);

    const canSubmit = () => {
        return user.subscriptionStatus !== "active" || user.subscription !== subscription || user.subscriptionPeriod !== period;
    }

    const setSubscriptionAndPeriod = (sub, per) => {
        setSubscription(sub);
        setPeriod(per);
    }

    const makeSubscriptionOptions = (sub) => {
        const prices = {
            "Premium": [999, 10000],
            "Classic": [749, 8250],
            "Basic": [249, 2750]
        }
        return <React.Fragment>
            <div className="fee-container">
                <div 
                    className={`subscription-option ${subscription === sub && period === "Daily" ? " active" : ""}`}
                    onClick={setSubscriptionAndPeriod.bind(null, sub, "Daily")}
                >
                    <h4><strong>{prices[sub][0]}€</strong></h4>
                </div>
                <div className="line"></div>
                <div 
                    className={`subscription-option ${subscription === sub && period === "Weekly" ? " active" : ""}`}
                    onClick={setSubscriptionAndPeriod.bind(null, sub, "Weekly")}
                >
                    <h4><strong>{prices[sub][1]}€</strong></h4>
                </div>
            </div>
        </React.Fragment>
    }

    const handleSubscribe = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        try {
            if (user.hasCreditCardInfo !== "True") {

                console.log(subscription);

                if (!subscription) {
                    setChooseSub("chooseSubscription");
                    throw Error();
                }
                else {
                    setChooseSub('');
                }

                const result = await stripe.createPaymentMethod({
                    type: 'card',
                    card: elements.getElement(CardElement)
                });

                if (result.error) {
                    throw Error(result.error.message);
                }

                await addPaymentInfo(user, props.company.id, result.paymentMethod.id)
                setCreditCardError('');
            }

            let lvl = `${subscription}_${period}`;
            let action = user.subscriptionStatus === "active" ? 
                modifySubscription(user, props.company.id, lvl) :
                subscribe(user, props.company.id, lvl);

            dispatch(action);
            props.close();
        } catch (ex) {
            setCreditCardError(ex.message);
        }
    };

    return (
        <Modal
            title={
                <FormattedMessage
                    id="subscribeModalTitle"
                    defaultMessage="Choose subscription" />
            }
            footer={[
                <Button key="back" onClick={props.close}>
                  Close
                </Button>,
                <Button 
                    key="submit" 
                    type="primary" 
                    disabled={!canSubmit()}
                    loading={login.currentlySubscribing} onClick={handleSubscribe}>
                  Submit
                </Button>,
              ]}
            onCancel={props.close}
            onOk={handleSubscribe}
            visible={props.show}
            centered
            closable
            width="60%"
            className="subscription-modal"
        >
            {user.subscriptionStatus === "active" && 
                <p className="warning-text">
                    <FormattedMessage
                        id="changeSubscriptionWarning"
                        defaultMessage="Changing your subscription level will result in a new payment!" />
                </p>
            }
            <Row>
                <Col span={6}>
                    <Card>
                        <Meta title="Features" />
                    </Card>
                    <Card>
                        <FormattedMessage
                            id="payment.fee"
                            defaultMessage="Fee (monthly & annually)" />
                    </Card>
                    <Card>
                        <FormattedMessage
                            id="payment.jobPostings"
                            defaultMessage="Job postings" />
                    </Card>
                    <Card>
                        <FormattedMessage
                            id="payment.usersIncluded"
                            defaultMessage="Users included" />
                    </Card>
                    <Card>
                        <FormattedMessage
                            id="payment.additionalUsers"
                            defaultMessage="Additional users" />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card>
                        <Meta title="Cogo Premium"/>                     
                    </Card>
                    <Card>
                        {makeSubscriptionOptions("Premium")}
                    </Card>
                    <Card>
                        <FormattedMessage
                            id="payment.unlimited"
                            defaultMessage="Unlimited" />
                    </Card>
                    <Card>
                        200*
                    </Card>
                    <Card>
                        25€
                    </Card>
                </Col>
                <Col span={6}>
                    <Card>
                        <Meta title="Cogo Classic"/>
                    </Card>
                    <Card>
                        {makeSubscriptionOptions("Classic")}
                    </Card>
                    <Card>
                        <FormattedMessage
                            id="payment.unlimited"
                            defaultMessage="Unlimited" />
                    </Card>
                    <Card>
                        25
                    </Card>
                    <Card>
                        25€
                    </Card>
                </Col>
                <Col span={6}>
                    <Card>
                        <Meta title="Cogo Basic"/>
                    </Card>
                    <Card>
                        {makeSubscriptionOptions("Basic")}
                    </Card>
                    <Card>
                        <FormattedMessage
                            id="payment.unlimited"
                            defaultMessage="Unlimited" />
                    </Card>
                    <Card>
                        5
                    </Card>
                    <Card>
                        50€
                    </Card>
                </Col>
            </Row>
            {user.hasCreditCardInfo !== "True"  && <CardSection />}
            {creditCardError && <p className="error-text">{creditCardError}</p>}
            {chooseSub && <p className="error-text">{<FormattedMessage id={chooseSub} defaultMessage="Please choose subscription plan!" />}</p>}
        </Modal>
    )
}