import messages from './SiderMessages';

export default [
    {
        name: messages.home,
        link: '/Home',
        icon: 'Home',
    },
    {
        name: messages.profile,
        link: '/Company',
        icon: 'UserProfile',
    },
    {
        name: messages.jobOffers,
        link: '/CompanyJobOffers',
        icon: 'Opportunities',
    },
    {
        name: messages.applicationsDatabase,
        link: '/JobApplications',
        icon: 'JobApplications',
    },
    {
        name: messages.findEmployee,
        link: '/FindEmployee',
        icon: 'FindEmployee',
    },
    {
        name: messages.conversations,
        link: '/Conversations',
        icon: 'Conversations',
    },
    {
        name: messages.usefulLinks,
        link: '/UsefulLinks',
        icon: 'UsefulLinks',
    }
];
