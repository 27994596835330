import { Button, Input } from "antd";
import React, { Fragment } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import LoginHeader from "../../components/LoginHeader/LoginHeader";
import RedirectIfLoggedIn from "../../components/RedirectIfLoggedIn";
import {
    checkPasswordToken,
    resetPassword,
} from "../../services/autentificationService";
import "./ResetPassword.less";

const ResetPassword = (props) => {
    const [token, setToken] = useState("");
    const [password1, setPassword1] = useState("");
    const [password2, setPassword2] = useState("");
    const [passwordsDontMatch, setPasswordsDontMatch] = useState(false);
    const [tokenValid, setTokenValid] = useState(false);
    const [success, setSuccess] = useState(false);

    const handleResetPassword = async () => {
        const userDto = { token: token, password: password1 };
        const res = await resetPassword(userDto);
        if (res.ok) setSuccess(true);
    };

    useEffect(() => {
        const tokenString = props.location.search;
        const newToken = tokenString.substring(tokenString.indexOf("=") + 1);
        setToken(newToken);
        checkPasswordToken(newToken).then((res) => {
            if (res.ok) setTokenValid(true);
        });
    }, [props.location.search]);

    useEffect(() => {
        if (password1 !== "" && password2 !== "" && password1 !== password2)
            setPasswordsDontMatch(true);
        else setPasswordsDontMatch(false);
    }, [password1, password2]);

    return (
        <div className="container-center">
            <RedirectIfLoggedIn />
            <div id="login-form-container">
                <LoginHeader />
                <div id="reset-password-form">
                    {success ? (
                        <Fragment>
                            <h4>
                                Password successfully reset. Try logging in with
                                your new password.
                            </h4>
                            <Link to="/Login">
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    className="login-button"
                                    size="large"
                                    block
                                >
                                    Back to login page
                                </Button>
                            </Link>
                        </Fragment>
                    ) : tokenValid ? (
                        <Fragment>
                            <h4>Reset your password</h4>
                            <Input.Password
                                type="password"
                                placeholder="New password"
                                value={password1}
                                onChange={(e) => setPassword1(e.target.value)}
                            />
                            <Input.Password
                                type="password"
                                placeholder="Confirm new password"
                                value={password2}
                                onChange={(e) => setPassword2(e.target.value)}
                            />
                            {passwordsDontMatch && (
                                <p id="reset-password-error">
                                    Passwords don't match
                                </p>
                            )}
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="login-button"
                                size="large"
                                block
                                disabled={
                                    passwordsDontMatch ||
                                    password1 === "" ||
                                    password2 === ""
                                }
                                onClick={handleResetPassword}
                            >
                                Reset password
                            </Button>
                        </Fragment>
                    ) : (
                        <h4>
                            Password reset token has expired or is invalid.
                            Please try resetting your password again.
                        </h4>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
