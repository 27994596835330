import React, { useState, useEffect, useCallback } from "react";
import { getApplicationsEmployee } from "../../services/jobApplicationService";
import JobApplicationCard from "./JobApplicationCard";
import { Pagination } from "antd";
import { useSelector } from "react-redux";
import { withLoader } from "../../components/WithLoader/withLoader";
import "./JobApplications.less";

function JobApplicationsEmployee({ setLoading }) {
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState();
    const [jobApplications, setJobApplications] = useState([]);
    const user = useSelector(state => state.user);

    let fetchJobOffers = useCallback(async () => {
        setLoading(true);
        const ja = await getApplicationsEmployee(user, page);
        setJobApplications(ja.items);
        setTotal(ja.total);
        setLoading(false);
    }, [user, page, setLoading]);

    useEffect(() => {
        fetchJobOffers();
    }, [fetchJobOffers]);

    let onPageChange = page => {
        setPage(page);
    };

    return (
        <React.Fragment>
            <div>
                <div className="job-applications-container">
                    {jobApplications.map(jobApplication => (
                        <JobApplicationCard
                            {...jobApplication}
                            key={jobApplication.created}
                            refresh={fetchJobOffers}
                        />
                    ))}
                </div>
                <div className="pagination-container">
                    <Pagination
                        current={page}
                        total={total}
                        hideOnSinglePage={false}
                        onChange={onPageChange}
                    />
                </div>
            </div>
        </React.Fragment>
    );
}

export default withLoader(JobApplicationsEmployee);
