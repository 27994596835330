import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Select, Spin } from 'antd';
import debounce from 'lodash/debounce';
import { searchCompany } from '../../services/companyService';
import { FormattedMessage } from 'react-intl';
import './CompanySelector.css';

const { Option } = Select;

function getStartValue(props) {
    if (props && props.value) {
        return props.value.map(v => ({ value: v.id, label: v.name }));
    }
    else {
        return [];
    }
}

function CompanySelector(props) {
    let lastFetchId = 0;
    const [data, setData] = useState([]);
    const [value, setValue] = useState(getStartValue(props));
    const [fetching, setFetching] = useState(false);
    const user = useSelector((state) => state.user);

    let fetchCompany = searchString => {
        lastFetchId += 1;
        const fetchId = lastFetchId;

        setData([]);
        setFetching(true);

        searchCompany(user, searchString)
            .then(data => {
                if (fetchId !== lastFetchId) {
                    return;
                }

                setData(data);
                setFetching(false);
            });
    };

    fetchCompany = debounce(fetchCompany, 800);

    let handleChange = newValues => {
        if (props.onChange) {
            props.onChange(newValues.map(n => ({ id: n.value, name: n.label })));
        }
        setValue(newValues);
        setData([]);
        setFetching(false);
    };

    return (
        <Select
            className="company-select"
            mode="multiple"
            labelInValue
            value={value}
            placeholder={<FormattedMessage
                id="enterCompany"
                defaultMessage="Enter company"
            />}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            filterOption={false}
            onSearch={fetchCompany}
            onChange={handleChange}
        >
            {data.map(d => (
                <Option key={d.id}>{d.name}</Option>
            ))}
        </Select>
    );

}

export default CompanySelector;