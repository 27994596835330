import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import { getCompany } from "../../services/companyService";
import { Row, Col, Button, Modal } from "antd";
import { deleteJobApplication } from "../../services/jobApplicationService";
import { RemoveJobOfferId } from "../../actions/jobApplicationAction";
import { ReactComponent as Trash } from "./Trash_icon.svg";
import moment from "moment";
import "./JobApplicationCard.less";
import { displayMessageToast } from "../../Helpers/ToastHelper";

function JobApplicationCard(props) {
    const [company, setCompany] = useState([]);
    const user = useSelector(state => state.user);
    const [visibility, setVisibility] = useState(false);
    const jobApplications = useSelector(state => state.jobApplication);
    const dispatch = useDispatch();

    useEffect(() => {
        let fetchCompany = () => {
            getCompany(user, props.jobOffer.companyId).then(setCompany);
        };
        fetchCompany();
    }, [user, props.jobOffer.companyId]);

    let handleDeleteJobApplication = () => {
        deleteJobApplication(user, props.guid)
            .then(() => setModalVisibility(false))
            .then(props.refresh)
            .then(() => {
                if (jobApplications.length !== 0) dispatch(RemoveJobOfferId(props.jobOffer.id));
            })
            .then(() => displayMessageToast("messageDelete", true, props.intl));
    };

    let setModalVisibility = isVisible => {
        setVisibility(isVisible);
    };

    return (
        <div className="job-application-card-container">
            <div className="title">
                <Link to={`/JobOffer/${props.jobOffer.id}`}>
                    <h3>{props.jobOffer.title}</h3>
                </Link>
            </div>
            <div className="jobApplication-info-container">
                <div className="jobApplication-info">
                    <Row>
                        <Col span={10} className="bolded-text">
                            <FormattedMessage id="companyName" />:
                        </Col>
                        <Col>
                            <Link to={`/Company/${company.id}`}>
                                <p>{company.name}</p>
                            </Link>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={10} className="bolded-text">
                            <FormattedMessage id="dateOfApplication" />:
                        </Col>
                        <Col>
                            <p>{moment(props.created).format("DD/MM/YYYY")}</p>
                        </Col>
                    </Row>
                </div>
                <div className="line" />
                <div className="job-application-buttons-container">
                    <Button className="button" onClick={() => setModalVisibility(true)}>
                        <FormattedMessage id="delete" />
                    </Button>
                    <Modal
                        title={
                            <div className="job-application-modal-title">
                                <Trash className="trash-icon" />
                                <p className="modal-title-paragraph">
                                    <FormattedMessage
                                        id="wantoToDelete"
                                        defaultMessage="Do you want to delete the application for this
                                    job offer?"
                                    />
                                </p>
                            </div>
                        }
                        footer={
                            <div className="job-application-modal-footer">
                                <Button
                                    className="cancel-button-modal"
                                    onClick={() => setModalVisibility(false)}
                                >
                                    <FormattedMessage id="conversations.cancel" />
                                </Button>
                                <Button className="button" onClick={handleDeleteJobApplication}>
                                    <FormattedMessage id="delete" />
                                </Button>
                            </div>
                        }
                        bodyStyle={{ padding: "18px" }}
                        centered
                        closable={false}
                        visible={visibility}
                        className="job-application-modal"
                    >
                        <p>
                            <FormattedMessage
                                id="ifYouDelete"
                                defaultMessage="If you delete, potential employers
                            will not able to see your interest in this job offer."
                            />
                        </p>
                    </Modal>
                </div>
            </div>
        </div>
    );
}

export default injectIntl(JobApplicationCard);
