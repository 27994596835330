import JobPrefenecesMessages from '../containers/JobPreference/JobPrefenecesMessages';

export const FormatPositions = (positions, intl) => {
    const newPositions = positions.map(position => {
        let name = intl.formatMessage(
            JobPrefenecesMessages[position.name.replace(/\W/g, '')]
        );
        return { ...position, name };
    });

    return newPositions.sort((a, b) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase())
    );
};
