import CityMessages from "./CityMessages";

export const FormatCities = (cities, intl) => {
    const newCities = cities.map(city => {
        let name = intl.formatMessage(CityMessages[city.name.replace(/\W/g, "")]);
        return { ...city, name };
    });

    return newCities.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
};
