import { ADD_FILTER_DATA } from '../actions/filterAction';

var savedState = sessionStorage.getItem('filterData') ? JSON.parse(sessionStorage.getItem('filterData')) : null;

const initialState = [];

function filterReducer(state = savedState || initialState, action) {
    switch (action.type) {
        case ADD_FILTER_DATA:
            return { ...action.filterData }
        default:
            return state;
    }
}

export default filterReducer;
