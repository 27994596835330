import { getAuthorizationProperties } from "./authorizationService";

export function sendEmail(user, form) {
    return fetch("/api/Contact", {
        method: "POST",
        ...getAuthorizationProperties(user.token, {
            "Content-Type": "application/json",
        }),
        body: JSON.stringify(form),
    });
}
