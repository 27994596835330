import React from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Tabs } from 'antd';
import Employee from '../Employee/Employee';
import Experience from '../Experience/Experience';
import Education from '../Education/Education';
import { FormattedMessage } from 'react-intl';
import './EmployeeProfile.css';

function EmployeeProfile(props) {
    const { TabPane } = Tabs;
    const user = useSelector(state => state.user);
    let employeeId = user.info;
    let redirect = false;

    if (props.location.pathname) {
        var parts = props.location.pathname.split('/');
        if (parts.length === 3 && parts[2].length === 36) {
            employeeId = parts[2];
        } else if (user.role === 'Employer') {
            redirect = true;
        }
    }

    return (
        <div className="employee-profile-container">
            {redirect && <Redirect to={{ pathname: '/Home' }} />}
            <Tabs>
                <TabPane
                    tab={
                        <FormattedMessage
                            id="personalData"
                            defaultMessage="Personal data"
                        />
                    }
                    key="employee"
                >
                    <Employee employeeId={employeeId} />
                </TabPane>
                <TabPane
                    tab={
                        <FormattedMessage
                            id="education"
                            defaultMessage="Education"
                        />
                    }
                    key="education"
                >
                    <Education employeeId={employeeId}/>
                </TabPane>
                <TabPane
                    tab={
                        <FormattedMessage
                            id="experience"
                            defaultMessage="Experience"
                        />
                    }
                    key="experience"
                >
                    <Experience employeeId={employeeId} />
                </TabPane>
            </Tabs>
        </div>
    );
}

export default EmployeeProfile;
