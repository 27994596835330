import { ReactComponent as Home } from './Home_icon.svg';
import { ReactComponent as JobApplications } from './JobsApplications_icon.svg';
import { ReactComponent as Opportunities } from './Opportunities_icon.svg';
import { ReactComponent as UserProfile } from './UserProfile_icon.svg';
import { ReactComponent as Conversations } from './Conversation_icon.svg';
import { ReactComponent as FindEmployee } from './FindEmployee_icon.svg';
import { GlobalOutlined as UsefulLinks} from '@ant-design/icons';

export default {
    Home,
    JobApplications,
    Opportunities,
    UserProfile,
    Conversations,
    FindEmployee,
    UsefulLinks
};

