import React from "react";
import CookieConsent from "react-cookie-consent";
import { FormattedMessage } from "react-intl";
import "./CustomCookies.less";

function CustomCookies() {
    return (
        <CookieConsent
            debug={true}
            cookieValue={false}
            buttonText={<FormattedMessage id="accept" defaultMessage="Accept" />}
        >
            <FormattedMessage
                id="cookieText"
                defaultMessage="We use cookies to improve your experience and support our mission. Read more about it in our privacy policy. By using our sites, you agree to our use of cookies."
            />
        </CookieConsent>
    );
}

export default CustomCookies;
