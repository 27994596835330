import React from "react";
import { FormattedMessage } from "react-intl";
import "../UsefulLinks/UsefulLinks.css";

export default function UsefulLinks() {
    return (
        <div>
            <h2 className="title-container">
                <FormattedMessage id="usefulLinks" defaultMessage="Useful Links" />
            </h2>
            <div className="links-container">
                <p>
                    <a
                        href="https://www.make-it-in-germany.com/en/jobs/professions-in-demand/nursing "
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Nursing professionals
                    </a>
                </p>
                <p>
                    <a
                        href="https://www.bundesgesundheitsministerium.de/themen/pflege/pflegekraefte/beschaeftigte.html "
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Beschäftigte in der Pflege
                    </a>
                </p>
                <p>
                    <a
                        href="https://www.anerkennung-in-deutschland.de/html/de/index.php "
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Anerkennungsportal
                    </a>
                </p>
                <p>
                    <a
                        href="https://www.arbeitsagentur.de/ "
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Arbeitsagentur
                    </a>
                </p>
                <p>
                    <a
                        href="https://www.defa-agentur.de/en/background/ "
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Defa-agentur
                    </a>
                </p>
                <p>
                    <a
                        href="https://fachkraefteeinwanderungsgesetz.de/ "
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Fachkraefteeinwanderungsgesetz
                    </a>
                </p>
            </div>
        </div>
    );
}
