import React from "react";
import { withRouter } from "react-router";
import { Button, Divider } from "antd";

function FormButtons(props) {
    return (
        <React.Fragment>
            <div className="form-buttons-container">
                {props.buttons.map((btn, index) => (
                    <Button
                        className="button"
                        key={index}
                        type={btn.type === "back" ? null : btn.type}
                        htmlType={btn.type === "submit" ? "submit" : "button"}
                        onClick={btn.type === "back" ? props.history.goBack : null}
                        loading={btn.loading}
                    >
                        {btn.text}
                    </Button>
                ))}
            </div>
        </React.Fragment>
    );
}

export default withRouter(FormButtons);
