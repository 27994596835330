import { getAuthorizationProperties } from "./authorizationService";

export function getMessages(user, conversation) {
    return fetch(`/api/messages/${conversation}`, {
        method: "GET",
        ...getAuthorizationProperties(user.token, {
            "Content-Type": "application/json",
        }),
    }).then((response) => response.json());
}

export function getOlderMessages(user, conversation, message) {
    return fetch(`/api/messages/${conversation}/older/${message}`, {
        method: "GET",
        ...getAuthorizationProperties(user.token, {
            "Content-Type": "application/json",
        }),
    }).then((response) => response.json());
}

export function getNewerMessages(user, conversation, message) {
    return fetch(`/api/messages/${conversation}/newer/${message}`, {
        method: "GET",
        ...getAuthorizationProperties(user.token, {
            "Content-Type": "application/json",
        }),
    }).then((response) => response.json());
}

export function getUnreadMessages(user) {
    return fetch("/api/messages/unread", {
        method: "GET",
        ...getAuthorizationProperties(user.token, {
            "Content-Type": "application/json",
        }),
    }).then((response) => response.json());
}

export function postMessage(user, conversation, message) {
    return fetch(`/api/messages/${conversation}`, {
        method: "POST",
        ...getAuthorizationProperties(user.token, {
            "Content-Type": "application/json",
        }),
        body: JSON.stringify(message),
    }).then((response) => response.json());
}

export function editMessage(user, conversation, message) {
    return fetch(`/api/messages/${conversation}`, {
        method: "PUT",
        ...getAuthorizationProperties(user.token, {
            "Content-Type": "application/json",
        }),
        body: JSON.stringify(message),
    });
}

export function deleteMessage(user, conversation, message) {
    return fetch(`/api/messages/${conversation}`, {
        method: "DELETE",
        ...getAuthorizationProperties(user.token, {
            "Content-Type": "application/json",
        }),
        body: JSON.stringify(message),
    });
}
