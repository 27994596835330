import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { Button } from "antd";
import Educations from "./Educations";
import NewEducation from "./NewEducation";
import EditEducation from "./EditEducation";
import { getEducation, getEducations } from "../../services/educationService";
import { FormattedMessage } from "react-intl";

function Education({ employeeId }) {
    const [activeComponent, setActiveComponent] = useState("details");
    const [education, setEducation] = useState([]);
    const [educations, setEducations] = useState([]);
    const [error, setError] = useState("");
    const user = useSelector(state => state.user);

    let fetchEducations = useCallback(() => {
        getEducations(user, employeeId)
            .then(c => {
                setEducations(c);
                if (c.length === 0) setActiveComponent("new");
            })
            .catch(e => setError(e.message));
    }, [user, employeeId]);

    useEffect(() => {
        fetchEducations();
    }, [fetchEducations]);

    let handleNewClick = () => {
        setActiveComponent("new");
    };

    let handleEditClick = id => {
        getEducation(user, id)
            .then(e => {
                setEducation(e);
            })
            .then(() => setActiveComponent("edit"));
    };

    let handleEducationDetails = id => {
        getEducation(user, id)
            .then(e => {
                setEducation(e);
            })
            .then(() => setActiveComponent("educationDetails"));
    };

    let handleBackAndSubmitClick = () => {
        setActiveComponent("details");
        fetchEducations();
    };
    return (
        <div>
            {error && <p>{error}</p>}
            {activeComponent === "details" && !error ? (
                <div>
                    <Educations
                        handleEdit={handleEditClick}
                        handleEducationDetails={handleEducationDetails}
                        employeeId={employeeId}
                    />

                    {user.role === "Employee" && user.info === employeeId ? (
                        <Button id="new-education-btn" className="button" onClick={handleNewClick}>
                            {<FormattedMessage id="addEducation" defaultMessage="Add education" />}
                        </Button>
                    ) : (
                        ""
                    )}
                </div>
            ) : (
                ""
            )}
            {activeComponent === "new" ? (
                <div>
                    <NewEducation
                        handleClicks={handleBackAndSubmitClick}
                        numOfEducations={educations.length}
                    />
                </div>
            ) : (
                ""
            )}
            {activeComponent === "edit" ? (
                <div>
                    <EditEducation handleClicks={handleBackAndSubmitClick} education={education} />
                </div>
            ) : (
                /* (
                    ''
                )}
            {activeComponent === 'educationDetails' ? (
                <EducationDetails
                    handleClicks={handleBackAndSubmitClick}
                    education={education}
                />
            ) : */ ""
            )}
        </div>
    );
}

export default Education;
