import { getAuthorizationProperties } from './authorizationService';

export function postJobApplication(user, state) {
    return fetch('/api/JobApplications', {
        method: 'POST',
        ...getAuthorizationProperties(user.token, {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }),
        body: state,
    }).then(response => response.json());
}

export function getEmployeeJobApplications(user, employeeGuid) {
    return fetch(`/api/JobApplications/Employee/${employeeGuid}`, {
        method: 'GET',
        ...getAuthorizationProperties(user.token, {
            'Content-Type': 'application/json',
        }),
    }).then(response => response.json());
}

export function getJobOfferJobApplications(user, jobOfferGuid, page) {
    var searchParams = new URLSearchParams();
    var skip = (page - 1) * 10;
    var take = 10;
    searchParams.append('skip', skip);
    searchParams.append('take', take);

    return fetch(
        `/api/JobApplications/JobOffer/${jobOfferGuid}?${searchParams}`,
        {
            method: 'GET',
            ...getAuthorizationProperties(user.token, {
                'Content-Type': 'application/json',
            }),
        }
    ).then(response => response.json());
}

export function getApplicationsEmployee(user, page) {
    var searchParams = new URLSearchParams();
    var skip = (page - 1) * 10;
    var take = 10;

    searchParams.append('skip', skip);
    searchParams.append('take', take);
    return fetch(`/api/JobApplications/Employee/${user.info}?${searchParams}`, {
        method: 'GET',
        ...getAuthorizationProperties(user.token, {
            'Content-Type': 'application/json',
        }),
    }).then(response => response.json());
}

export function getApplicationsCompany(user, page, searchParams) {
    var skip = (page - 1) * 12;
    var take = 12;

    searchParams.append('skip', skip);
    searchParams.append('take', take);

    return fetch(`/api/JobApplications/Company/${user.info}?${searchParams}`, {
        method: 'GET',
        ...getAuthorizationProperties(user.token, {
            'Content-Type': 'application/json',
        }),
    }).then(response => response.json());
}

export function getAllApplicationsCompany(user) {
    return fetch(`/api/JobApplications/Company/${user.info}`, {
        method: 'GET',
        ...getAuthorizationProperties(user.token, {
            'Content-Type': 'application/json',
        }),
    }).then(response => response.json());
}

export function markAllApplicationsCompanyAsRead(user) {
    return fetch(`/api/JobApplications/Company/${user.info}`, {
        method: 'PUT',
        ...getAuthorizationProperties(user.token, {
            'Content-Type': 'application/json',
        }),
    })
}

export function deleteJobApplication(user, jobApplicationGuid) {
    return fetch(`api/JobApplications/${jobApplicationGuid}`, {
        method: 'DELETE',
        ...getAuthorizationProperties(user.token),
    });
}
