export const SUBSCRIBE = "SUBSCRIBE";
export const UNSUBSCRIBE = "UNSUBSCRIBE";
export const MODIFY_SUBSCRIPTION = "MODIFY_SUBSCRIPTION";
export const MODIFY_PAYMENT_METHOD = "MODIFY_PAYMENT_METHOD";

export function subscribe(user, companyId, lvl) {
    return { type: SUBSCRIBE, user, companyId, lvl }
}

export function unsubscribe(user, companyId, lvl = null) {
    return { type: UNSUBSCRIBE, user, companyId, lvl}
}

export function modifySubscription(user, companyId, newLvl) {
    return { type: MODIFY_SUBSCRIPTION, user, companyId, newLvl }
}

export function modifyPaymentMethod(user, companyId, paymentMethod) {
    return { type: MODIFY_PAYMENT_METHOD, user, companyId, paymentMethod }
}