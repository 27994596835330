import { ADD_LOCATIONS } from '../actions/locationActions';

var savedState = sessionStorage.getItem('locations') ? JSON.parse(sessionStorage.getItem('locations')) : null;

const initialState = [];

function locationsReducer(state = savedState || initialState, action) {
    switch (action.type) {
        case ADD_LOCATIONS:
            return [...action.locations]
        default:
            return state;
    }
}

export default locationsReducer;
