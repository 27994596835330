import { SET_USER, CLEAR_USER } from '../actions/authentificationActions';
import { SET_LANGUAGE } from '../actions/userActions';

var savedState = sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user')) : null;

const initialState = {
    id: null,
    username: null,
    email: null,
    token: null,
    language: 'en'
}

function userReducer(state = savedState || initialState, action) {
    switch (action.type) {
        case SET_USER:
            return { ...action.user }
        case CLEAR_USER:
            return { ...initialState }
        case SET_LANGUAGE:
            return { ...state, language: action.language }
        default:
            return state
    }
}

export default userReducer;
