import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Input, Button, Select } from "antd";
import { ImageDropzone, getCroppedImg } from "../Dropzone/ImageDropzone";
import { RefreshImage } from "../../actions/imageAction";
import avatar from "./default-company.jpg";
import { putImage } from "../../services/imageService";
import { updateCompanyInfo } from "../../services/companyService";
import { FormattedMessage, injectIntl, defineMessages } from "react-intl";
import { LeftOutlined } from "@ant-design/icons";
import { getCountries } from "../../services/locationService";
import { FormatCountries } from "../../Helpers/CountryHelper";
import "./CompanyEdit.less";

function CompanyEdit(props) {
    const [company, setCompany] = useState(props.company);
    const user = useSelector(state => state.user);
    const [imageDropped, setImageDropped] = useState(false);
    const [crop, setCrop] = useState(false);
    const [refreshThumb, setRefreshThumb] = useState(false);
    const [countries, setCountries] = useState([]);
    const dispatch = useDispatch();

    const { Option } = Select;
    const FormItem = Form.Item;

    let onCountryChange = value => {
        var country = countries.find(country => country.id === value);
        setCompany({ ...company, country: country.name });
    };

    useEffect(() => {
        getCountries(user).then(res => setCountries(res));
    }, [user]);

    const handleInputChange = e => {
        const { id, value } = e.target;
        setCompany(prevState => ({
            ...prevState,
            [id]: value,
        }));
    };

    let handleSubmit = () => {
        if (imageDropped)
            getCroppedImg(crop, user.info).then(imageData => {
                putImage(user, imageData, null).then(() => {
                    dispatch(RefreshImage(user.info));
                });
            });

        updateCompanyInfo(user, company).then(props.refresh).then(props.handleClicks);
    };

    let onFinish = values => handleSubmit(values);

    let handleDrop = () => setImageDropped(true);

    let handleCrop = newCrop => setCrop(newCrop);

    let handleRefreshThumb = () => setRefreshThumb(refreshThumb);

    return (
        <div className="company-edit-container">
            <Form
                layout="vertical"
                onFinish={onFinish}
                className="edit-company-form"
                initialValues={{
                    name: company.name,
                    identificationNumber: company.identificationNumber,
                    country: company.country,
                    city: company.city,
                    postalCode: company.postalCode,
                    address: company.address,
                    telephone: company.telephone,
                    contactEmail: company.contactEmail,
                    websiteLink: company.websiteLink,
                }}
            >
                <FormItem
                    className="form-group-container"
                    label={<FormattedMessage id="companyNameReg" />}
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: (
                                <FormattedMessage
                                    id="nameMandatory"
                                    defaultMessage="Name is mandatory"
                                />
                            ),
                        },
                    ]}
                >
                    <Input
                        className="edit-company-input-field"
                        value={company.name ? company.name : ""}
                        onChange={handleInputChange}
                    />
                </FormItem>
                <FormItem
                    className="form-group-container"
                    label={<FormattedMessage id="company.id" />}
                    name="identificationNumber"
                    rules={[
                        {
                            max: 11,
                            message: (
                                <FormattedMessage
                                    id="idNumberCantBe"
                                    defaultMessage="Identification number can't be longer then 11 characters"
                                />
                            ),
                        },
                    ]}
                >
                    <Input
                        className="edit-company-input-field"
                        value={company.identificationNumber ? company.identificationNumber : ""}
                        onChange={handleInputChange}
                        autoComplete="off"
                    />
                </FormItem>
                <FormItem
                    className="form-group-container"
                    label={<FormattedMessage id="employee.details.country" />}
                    name="country"
                    rules={[
                        {
                            required: true,
                            message: <FormattedMessage id="countryMandatory" />,
                        },
                    ]}
                >
                    <Select
                        placeholder={
                            <FormattedMessage
                                id="selectLocation"
                                defaultMessage="Select location"
                            />
                        }
                        showArrow={true}
                        onChange={onCountryChange}
                        showSearch
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                        }
                    >
                        {countries &&
                            FormatCountries([...countries], props.intl).map(country => (
                                <Option value={country.id} key={country.id}>
                                    {country.name}
                                </Option>
                            ))}
                    </Select>
                </FormItem>
                <FormItem
                    className="form-group-container"
                    label={<FormattedMessage id="employee.details.city" />}
                    name="city"
                    rules={[
                        {
                            required: true,
                            message: <FormattedMessage id="cityMandatory" />,
                        },
                    ]}
                >
                    <Input
                        className="edit-company-input-field"
                        value={company.city ? company.city : ""}
                        onChange={handleInputChange}
                    />
                </FormItem>

                <FormItem
                    className="form-group-container"
                    label={<FormattedMessage id="employee.details.postalCode" />}
                    name="postalCode"
                >
                    <Input
                        className="edit-company-input-field"
                        value={company.postalCode ? company.postalCode : ""}
                        onChange={handleInputChange}
                    />
                </FormItem>
                <FormItem
                    className="form-group-container"
                    label={<FormattedMessage id="employee.details.address" />}
                    name="address"
                >
                    <Input
                        className="edit-company-input-field"
                        value={company.address ? company.address : ""}
                        onChange={handleInputChange}
                    />
                </FormItem>
                <FormItem
                    className="form-group-container"
                    label={<FormattedMessage id="employee.details.telephone" />}
                    name="telephone"
                    rules={[
                        () => ({
                            validator(rule, value) {
                                const pattern = new RegExp(/^[+]?[0-9\b]+$/);
                                if (value === "" || pattern.test(value)) return Promise.resolve();
                                else return Promise.reject("Invalid telephone number format");
                            },
                        }),
                    ]}
                >
                    <Input
                        className="edit-company-input-field"
                        value={company.telephone ? company.telephone : ""}
                        onChange={handleInputChange}
                    />
                </FormItem>
                <FormItem
                    className="form-group-container"
                    label="Email"
                    name="contactEmail"
                    rules={[
                        {
                            required: true,
                            message: (
                                <FormattedMessage
                                    id="emailMandatory"
                                    defaultMessage="Email is mandatory"
                                />
                            ),
                        },
                        {
                            type: "email",
                            message: "The username has to be a valid E-mail!",
                        },
                    ]}
                >
                    <Input
                        className="edit-company-input-field"
                        value={company.contactEmail ? company.contactEmail : ""}
                        onChange={handleInputChange}
                    />
                </FormItem>
                <FormItem className="form-group-container" label="Website" name="websiteLink">
                    <Input
                        className="edit-company-input-field"
                        value={company.websiteLink ? company.websiteLink : ""}
                        onChange={handleInputChange}
                    />
                </FormItem>

                <div className="image-buttons-container">
                    <ImageDropzone
                        imagePlaceholder={avatar}
                        crop={crop}
                        onDrop={handleDrop.bind(this)}
                        setCrop={handleCrop.bind(this)}
                        refreshThumb={refreshThumb}
                        clearRefreshThumb={handleRefreshThumb.bind(this, false)}
                        image={user.info}
                    />

                    <div className="edit-company-button">
                        <Button className="plain-button" onClick={props.handleClicks}>
                            <LeftOutlined />
                            <FormattedMessage id="back" />
                        </Button>
                        <Button className="button" htmlType="submit">
                            <FormattedMessage id="submit" />
                        </Button>
                    </div>
                </div>
            </Form>
        </div>
    );
}

export default injectIntl(CompanyEdit);
