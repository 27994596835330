import React, { useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import "./Message.less";
import { DownOutlined } from "@ant-design/icons";
import { Menu, Dropdown, Button } from "antd";
import { editMessage } from "../../services/messagesService";
import { FormattedMessage } from "react-intl";

function formatDateTime(timestamp) {
    let utc = moment.utc(timestamp).toDate();
    let localDate = moment(utc).local();
    if (moment(timestamp).isSame(moment(), "day"))
        return localDate.format("h:mm a");
    else return moment(localDate).format("MMMM Do YYYY, h:mm a");
}

function Message(props) {
    const user = useSelector((state) => state.user);
    const [showTimestamp, setShowTimestamp] = useState(false);
    const [showOptions, setShowOptions] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [newMessage, setNewMessage] = useState(props.message);
    const [editedMessage, setEditedMessage] = useState("");

    const handleTimestampEnter = () => setShowTimestamp(true);
    const handleTimestampLeave = () => setShowTimestamp(false);

    const handleSaveEditedMessage = () => {
        let msg = { ...props };
        msg.message = newMessage;
        editMessage(user, props.conversation, msg).then(() => {
            setEditedMessage(newMessage);
            setShowOptions(false);
            setEditMode(false);
        });
    };

    const handleCancelEditMessage = () => {
        setNewMessage(props.message);
        setShowOptions(false);
        setEditMode(false);
    };

    const menu = (
        <Menu>
            <Menu.Item key="0">
                <span onClick={() => setEditMode(true)}>
                    <FormattedMessage
                        id="editMessage"
                        defaultMessage="Edit message"
                    />
                </span>
            </Menu.Item>
            <Menu.Item key="1">
                <span onClick={() => props.handleSelectMessage({ ...props })}>
                    <FormattedMessage
                        id="deleteMessage"
                        defaultMessage="Delete message"
                    />
                </span>
            </Menu.Item>
        </Menu>
    );

    return (
        <React.Fragment>
            {props.sender === user.guid ? (
                <div className="message-user">
                    {editMode ? (
                        <div className="edit-message-container">
                            <textarea
                                onChange={(e) => setNewMessage(e.target.value)}
                                value={newMessage}
                            />
                            <div className="edit-message-options">
                                <Button
                                    className="button"
                                    onClick={handleSaveEditedMessage}
                                >
                                    <FormattedMessage
                                        id="save"
                                        defaultMessage="Save"
                                    />
                                </Button>
                                <Button
                                    className="plain-button"
                                    onClick={handleCancelEditMessage}
                                >
                                    <FormattedMessage
                                        id="conversations.cancel"
                                        defaultMessage="Cancel"
                                    />
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <div className="time-user">
                            <div
                                className="message-options"
                                onMouseEnter={() => setShowOptions(true)}
                                onMouseLeave={() => setShowOptions(false)}
                            >
                                <p
                                    className="p-user"
                                    onMouseEnter={handleTimestampEnter}
                                    onMouseLeave={handleTimestampLeave}
                                >
                                    {editedMessage.length > 0 ? (
                                        editedMessage
                                    ) : props.deleted ? (
                                        <em>This message has been deleted</em>
                                    ) : (
                                        props.message
                                    )}
                                </p>
                                <Dropdown
                                    disabled={props.deleted}
                                    overlay={menu}
                                    trigger={["click"]}
                                >
                                    <DownOutlined
                                        className={
                                            showOptions && !props.deleted
                                                ? "options-visible"
                                                : ""
                                        }
                                        onClick={(e) => e.preventDefault()}
                                    />
                                </Dropdown>
                            </div>
                            <p
                                className={`time-container ${
                                    showTimestamp ? "visible" : ""
                                }`}
                            >
                                {formatDateTime(props.created)}
                                {props.edited || editedMessage.length > 0
                                    ? " (Edited)"
                                    : ""}
                            </p>
                        </div>
                    )}
                </div>
            ) : (
                <div className="message-interlocutor">
                    <div className="time-interlocutor">
                        {props.isGroup > 2 && props.showSender ? (
                            <p className="p-interlocutor-name">
                                {props.senderName}
                            </p>
                        ) : (
                            ""
                        )}
                        <p
                            className="p-interlocutor"
                            onMouseEnter={handleTimestampEnter}
                            onMouseLeave={handleTimestampLeave}
                        >
                            {props.deleted ? (
                                <em>This message has been deleted</em>
                            ) : (
                                props.message
                            )}
                        </p>

                        <p
                            className={`time-container ${
                                showTimestamp ? "visible" : ""
                            }`}
                        >
                            {formatDateTime(props.created)}
                            {props.edited || editedMessage.length > 0
                                ? " (Edited)"
                                : ""}
                        </p>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
}

export default Message;
