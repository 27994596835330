import { getAuthorizationProperties } from './authorizationService';

export function getJobOffers(user, searchParams, page) {
    var skip = (page - 1) * 12;
    var take = 12;
    searchParams.append('skip', skip);
    searchParams.append('take', take);
    return fetch(`/api/JobOffers?${searchParams}`, {
        method: 'GET',
        ...getAuthorizationProperties(user.token, {
            'Content-Type': 'application/json',
        }),
    }).then(response => response.json());
}

export function getPeronalizedJobOffers(user) {
    return fetch('/api/joboffers/personalized', {
        method: 'GET',
        ...getAuthorizationProperties(user.token, {
            'Content-Type': 'application/json',
        }),
    }).then(response => {
        if (response.ok) {
            return response.json();
        } else {
            return response.text().then(t => { throw t })
        }
    });
}

export function getAllCompanyJobOffers(user) {
    return fetch(`/api/JobOffers?Companies=${user.info}`, {
        method: 'GET',
        ...getAuthorizationProperties(user.token, {
            'Content-Type': 'application/json',
        }),
    }).then(response => response.json());
}

export function getJobOffersWithoutPagination(user, searchParams) {
    return fetch(`/api/JobOffers?${searchParams}`, {
        method: 'GET',
        ...getAuthorizationProperties(user.token, {
            'Content-Type': 'application/json',
        }),
    }).then(response => response.json());
}

export function getJobOffer(user, id) {
    return fetch(`/api/JobOffers/${id}`, {
        method: 'GET',
        ...getAuthorizationProperties(user.token, {
            'Content-Type': 'application/json',
        }),
    }).then(response => response.json());
}

export function postJobOffer(user, state) {
    return fetch('/api/JobOffers', {
        method: 'POST',
        ...getAuthorizationProperties(user.token, {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }),
        body: state,
    }).then(response => response.json());
}

export function putJobOffer(user, state, id) {
    return fetch(`/api/JobOffers/${id}`, {
        method: 'PUT',
        ...getAuthorizationProperties(user.token, {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }),
        body: state,
    });
}

export function deleteJobOffer(user, id) {
    return fetch(`/api/JobOffers/${id}`, {
        method: 'DELETE',
        ...getAuthorizationProperties(user.token),
    });
}

export function getCompanyStatistics(user, searchParams) {
    return fetch(`/api/JobOffers/Statistics?${searchParams}`, {
        method: 'GET',
        ...getAuthorizationProperties(user.token, {
            'Content-Type': 'application/json',
        }),
    }).then(response => response.json());
}
