import { getAuthorizationProperties } from './authorizationService';

export function getConversations(user) {
    return fetch('/api/conversations', {
        method: 'GET',
        ...getAuthorizationProperties(user.token, {
            'Content-Type': 'application/json',
        }),
    }).then((response) => response.json());
}

export function getConversation(user, conversation) {
    return fetch(`/api/conversations/${conversation}`, {
        method: 'GET',
        ...getAuthorizationProperties(user.token, {
            'Content-Type': 'application/json',
        }),
    }).then((response) => response.json());
}

export function postConversations(user, conversation) {
    return fetch('/api/conversations', {
        method: 'POST',
        ...getAuthorizationProperties(user.token, {
            'Content-Type': 'application/json',
        }),
        body: JSON.stringify(conversation),
    }).then((response) => response.json());
}

