import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, InputNumber, Row } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useStripe } from '@stripe/react-stripe-js';
import { buyUnlocks, getCompany } from '../../services/companyService';
import { displayMessageToast } from '../../Helpers/ToastHelper';
import './BuyUnlocksModal.less';

export default function BuyUnlocksModal(props) {
    const [ammount, setAmmount] = useState(1);
    const stripe = useStripe();
    const user = useSelector(state => state.user);

    const handleBuyUnlocks = async () => {
        props.setLoading(true);
        props.close();
        try {
            let intentSecret = await buyUnlocks(user, props.companyId, ammount);
            const stripeResponse = await stripe.confirmCardPayment(intentSecret);
            if (stripeResponse.error) {
                displayMessageToast(stripeResponse.error.message, false);
            } else if (stripeResponse.paymentIntent && stripeResponse.paymentIntent.status === 'succeeded') {
                displayMessageToast("Unlock bought successfully");
            } else {
                displayMessageToast("Error confirming payment!", false);
            }
            let company = await getCompany(user, props.companyId);
            props.setCompany(company);
        } catch (error) {
            displayMessageToast(error.message, false);
        } finally {
            props.setLoading(false);
        }
    }

    const getPrice = () => {
        if (!user || !user.subscription) return 0;
        let factor = user.subscription === "Basic" ? 50 : 25;
        return ammount * factor;
    }

    return (
        <Modal
            title={
                <FormattedMessage
                    id="buyUnlocks"
                    defaultMessage="Buy employee unlocks" />
            }
            onCancel={props.close}
            onOk={handleBuyUnlocks}
            okText="Buy"
            visible={props.show}
            centered
            closable
            width="30%"
            className="buy-unlocks-modal"
        >
            <h2><FormattedMessage id="price" defaultMessage="Price" />: {getPrice()}€</h2>
            <Row align="middle">
                <label><FormattedMessage id="ammount" defaultMessage="Ammount" />:</label>
                <InputNumber min={1} max={99} value={ammount} onChange={setAmmount} />  
            </Row>
        </Modal>
    )
}