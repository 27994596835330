import { defineMessages } from "react-intl";

let messages = defineMessages({
    home: { id: "sider.home", defaultMessage: "Home" },
    profile: { id: "sider.profile", defaultMessage: "Profile" },
    opportunities: {
        id: "sider.opportunities",
        defaultMessage: "Opportunities",
    },
    jobOffers: { id: "sider.jobOffers", defaultMessage: "Job offers" },
    createJobOffer: {
        id: "sider.createJobOffer",
        defaultMessage: "Create job offer",
    },
    myApplications: {
        id: "sider.myApplications",
        defaultMessage: "My applications",
    },
    applicationsDatabase: {
        id: "sider.applicationsDatabase",
        defaultMessage: "Applications database",
    },
    findEmployee: {
        id: "sider.findEmployee",
        defaultMessage: "Find employee",
    },
    conversations: {
        id: "sider.conversations",
        defaultMessage: "Conversations",
    },
    usefulLinks: {
        id: "sider.usefulLinks",
        defaultMessage: "Useful links",
    }
});

export default messages;
