import { take, call, put, select } from 'redux-saga/effects';
import { REFRESH_USER } from '../actions/userActions';
import { SET_USER } from '../actions/authentificationActions';
import { refresh } from '../services/userService';

export function* refreshUserFlow() {
    while (true) {
        yield take(REFRESH_USER)
        let user = yield select(state => state.user);
        const userData = yield call(refresh, user);
        sessionStorage.setItem("user", JSON.stringify(userData));
        yield put({ type: SET_USER, user: userData });
    }
}
