import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Select, Spin } from 'antd';
import { searchUsers } from '../../services/autentificationService';
import { FormattedMessage } from 'react-intl';
import debounce from 'lodash/debounce';
import './UsersSelector.css';

const { Option } = Select;

function getStartValue(props) {
    if (props && props.value) {
        return props.value.map(v => ({ value: v.id, label: v.name }));
    }
    else {
        return [];
    }
}

export default function UsersSelector(props) {
    let lastFetchId = 0;
    const [data, setData] = useState([]);
    const [value, setValue] = useState(getStartValue(props));
    const [fetching, setFetching] = useState(false);
    const user = useSelector((state) => state.user);

    let fetchUsers = searchString => {
        lastFetchId += 1;
        const fetchId = lastFetchId;

        setData([]);
        setFetching(true);

        searchUsers(user, searchString)
            .then(data => {
                if (fetchId !== lastFetchId) {
                    return;
                }
                setData(data);
                setFetching(false);
            });
    }

    fetchUsers = debounce(fetchUsers, 800);

    let handleChange = newValues => {
        if (props.onChange) {
            props.onChange(newValues.map(n => ({ id: n.value, name: n.label })));
        }
        setValue(newValues);
        setData([]);
        setFetching(false);
    };

    return (
        <Select
            className="users-select"
            mode="multiple"
            labelInValue
            value={value}
            placeholder=
            {<FormattedMessage
                id="searchUsers"
                defaultMessage="Search users"
            />}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            filterOption={false}
            onSearch={fetchUsers}
            onChange={handleChange}
        >
            {data.map(d => (
                    <Option
                        key={d.id}>
                        {d.name}
                    </Option>
                ))}
        </Select>
        )
}