import React, { useMemo, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FormattedMessage } from 'react-intl';
import ReactCrop from 'react-image-crop';
import Image from '../Image/Image';
import 'react-image-crop/dist/ReactCrop.css';
import './ImageDropzone.css';

export function getCroppedImg(crop, fileName) {
    let image = document.querySelector('img.ReactCrop__image');
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
    );

    return new Promise((resolve, reject) => {
        canvas.toBlob(
            blob => {
                blob.name = fileName;
                resolve(blob);
            },
            'image',
            1
        );
    });
}

export function ImageDropzone(props) {
    const [files, setFiles] = useState([]);
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
    } = useDropzone({
        maxSize: 5242880,
        multiple: false,
        accept: 'image/*',
        onDrop: acceptedFiles => {
            setFiles(
                acceptedFiles.map(file =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    })
                )
            );
            props.onDrop();
        },
    });

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isDragActive ? activeStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isDragActive, isDragReject, isDragAccept]
    );

    useEffect(
        () => () => {
            files.forEach(file => URL.revokeObjectURL(file.preview));
        },
        [files]
    );

    let handleChangeCrop = newCrop => {
        props.setCrop(newCrop);
    };

    let onImageLoaded = image => {
        let cropDim = 350;

        if (image.width < 350) {
            cropDim = image.width;
        }

        if (image.height < 350) {
            cropDim = image.height;
        }

        props.setCrop({
            width: cropDim,
            height: cropDim,
            aspect: 1 / 1,
            x: 0,
            y: 0,
        });

        return false;
    };

    return (
        <div className="image-dropzone-container">
            <div className="thumbsContainer">
                <Image
                    imageId={props.image}
                    placeholder={props.imagePlaceholder}
                    refresh={props.refreshThumb}
                    clearRefresh={props.clearRefreshThumb}
                />
            </div>
            <div className="dragndrop-container" {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <h5>
                    <FormattedMessage
                        id="file.dropzone.description"
                        defaultMessage="Drag 'n' drop new image or click to add it"
                    />
                </h5>
            </div>
            <div>
                {files.length !== 0 && (
                    <ReactCrop
                        aspect="1/1"
                        src={files[0].preview}
                        crop={props.crop}
                        onChange={handleChangeCrop.bind(this)}
                        onImageLoaded={onImageLoaded.bind(this)}
                    />
                )}
            </div>
        </div>
    );
}

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
};

const activeStyle = {
    borderColor: '#2196f3',
};

const acceptStyle = {
    borderColor: '#00e676',
};

const rejectStyle = {
    borderColor: '#ff1744',
};

export default { ImageDropzone, getCroppedImg };
