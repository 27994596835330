import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { Button } from "antd";
import Experiences from "./Experiences";
import NewExperience from "./NewExperience";
import EditExperience from "./EditExperience";
import ExperienceDetails from "./ExperienceDetails";
import { getExperience, getExperiences } from "../../services/experienceService";
import { getCompanies } from "../../services/companyService";
import { FormattedMessage } from "react-intl";

function Experience({ employeeId }) {
    const [activeComponent, setActiveComponent] = useState("details");
    const [experience, setExperience] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [error, setError] = useState("");
    const user = useSelector(state => state.user);

    let fetchExperiences = useCallback(() => {
        getExperiences(user, employeeId)
            .then(c => {
                if (c.length === 0) setActiveComponent("new");
            })
            .catch(e => setError(e.message));
    }, [user, employeeId]);

    useEffect(() => {
        let fetchCompanies = () => {
            getCompanies(user).then(c => {
                setCompanies(c);
            });
        };

        fetchCompanies();
        fetchExperiences();
    }, [user, fetchExperiences]);

    let handleNewClick = () => {
        setActiveComponent("new");
    };

    let handleEditClick = id => {
        getExperience(user, id)
            .then(e => {
                setExperience(e);
            })
            .then(() => setActiveComponent("edit"));
    };

    let handleExperienceDetails = id => {
        getExperience(user, id)
            .then(e => {
                setExperience(e);
            })
            .then(() => setActiveComponent("experienceDetails"));
    };

    let handleBackAndSubmitClick = () => {
        setActiveComponent("details");
        fetchExperiences();
    };

    return (
        <div>
            {error && <p>{error}</p>}
            {activeComponent === "details" && !error ? (
                <div>
                    <Experiences
                        handleEdit={handleEditClick}
                        handleExperienceDetails={handleExperienceDetails}
                        employeeId={employeeId}
                    />

                    {user.role === "Employee" && user.info === employeeId ? (
                        <Button id="new-experience-btn" className="button" onClick={handleNewClick}>
                            {
                                <FormattedMessage
                                    id="newExperience"
                                    defaultMessage="New experience"
                                />
                            }
                        </Button>
                    ) : (
                        ""
                    )}
                </div>
            ) : (
                ""
            )}
            {activeComponent === "new" ? (
                <div>
                    <NewExperience handleClicks={handleBackAndSubmitClick} companies={companies} />
                </div>
            ) : (
                ""
            )}
            {activeComponent === "edit" ? (
                <div>
                    <EditExperience
                        handleClicks={handleBackAndSubmitClick}
                        experience={experience}
                        companies={companies}
                    />
                </div>
            ) : (
                ""
            )}
            {activeComponent === "experienceDetails" ? (
                <ExperienceDetails
                    handleClicks={handleBackAndSubmitClick}
                    experience={experience}
                />
            ) : (
                ""
            )}
        </div>
    );
}

export default Experience;
