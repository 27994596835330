import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import EmployeeAvatar from "../EmployeeAvatar/EmployeeAvatar";
import { FormattedMessage, injectIntl } from "react-intl";
import LocationMessages from "../Location/LocationMessages";
import LanguageMessages from "../Language/LanguageMessages";
import JobPreferencesMessages from "../JobPreference/JobPrefenecesMessages";
import { withLoader } from "../../components/WithLoader/withLoader";
import { Row, Col } from "antd";
import lockImg from "../Employee/lock.png";
import "./InterestedEmployeeCard.less";

function InterestedEmployeeCard({ setLoading, interestedEmployee, intl }) {
    const allPositions = useSelector(state => state.positions);
    const allLocations = useSelector(state => state.locations);
    const [positions, setPositions] = useState([]);
    const [locations, setLocations] = useState([]);

    useEffect(() => {
        setLoading(true);
        let selectedPositions = interestedEmployee.positions.map(p => {
            return allPositions.find(ap => ap.id === p);
        });
        setPositions(selectedPositions);
        setLoading(false);
    }, [interestedEmployee.positions, allPositions, setLoading]);

    useEffect(() => {
        setLoading(true);
        let selectedLocations = interestedEmployee.locations.map(l => {
            return allLocations.find(al => al.id === l);
        });
        setLoading(false);
        setLocations(selectedLocations);
    }, [interestedEmployee.locations, allLocations, setLoading]);

    return (
        <div className="interested-employee-card-container">
            <Link to={`/Employee/${interestedEmployee.id}`}>
                <div className="employee-avatar-interested-employee">
                    {interestedEmployee.locked ? (
                        <div>
                            <img src={lockImg} alt="lock" />
                        </div>
                    ) : (
                        <EmployeeAvatar id="employee-avatar" employeeId={interestedEmployee.id} />
                    )}
                    <div className="title">
                        <h3>
                            {interestedEmployee.firstName} {interestedEmployee.lastName}
                        </h3>
                    </div>
                </div>
                <div className="interested-employee-card-info-container">
                    <div className="interested-employee-card-info">
                        <Row wrap={false}>
                            <Col span={5} className="bolded-text">
                                <FormattedMessage id="employee.details.country" />:
                            </Col>
                            <Col>
                                {interestedEmployee.country
                                    ? intl.formatMessage(
                                          LocationMessages[
                                              interestedEmployee.country.replace(/\W/g, "")
                                          ]
                                      )
                                    : ""}
                            </Col>
                        </Row>
                        <Row wrap={false}>
                            <Col span={5} className="bolded-text">
                                {positions.length === 1 ? (
                                    <p className="bolded-text">
                                        <FormattedMessage id="position" defaultMessage="Position" />
                                        :
                                    </p>
                                ) : (
                                    <p className="bolded-text">
                                        <FormattedMessage
                                            id="positions"
                                            defaultMessage="Positions"
                                        />
                                        :
                                    </p>
                                )}
                            </Col>
                            <Col>
                                {positions
                                    .map(element =>
                                        intl.formatMessage(
                                            JobPreferencesMessages[element.name.replace(/\W/g, "")]
                                        )
                                    )
                                    .join(", ")}
                            </Col>
                        </Row>
                        <Row wrap={false}>
                            <Col span={5} className="bolded-text">
                                {locations.length === 1 ? (
                                    <p className="bolded-text">
                                        <FormattedMessage id="location" defaultMessage="Location" />
                                        :
                                    </p>
                                ) : (
                                    <p className="bolded-text">
                                        <FormattedMessage
                                            id="locations"
                                            defaultMessage="Locations"
                                        />
                                        :
                                    </p>
                                )}
                            </Col>
                            <Col>
                                {locations
                                    .map(element =>
                                        intl.formatMessage(
                                            LocationMessages[element.name.replace(/\W/g, "")]
                                        )
                                    )
                                    .join(", ")}
                            </Col>
                        </Row>
                        <Row wrap={false}>
                            <Col span={5} className="bolded-text">
                                {interestedEmployee.languages.length === 1 ? (
                                    <p className="bolded-text">
                                        <FormattedMessage id="language" defaultMessage="Language" />
                                    </p>
                                ) : (
                                    <p className="bolded-text">
                                        <FormattedMessage
                                            id="languages"
                                            defaultMessage="Languages"
                                        />
                                    </p>
                                )}
                            </Col>
                            <Col>
                                {interestedEmployee.languages &&
                                    interestedEmployee.languages
                                        .map(lang => intl.formatMessage(LanguageMessages[lang]))
                                        .join(", ")}
                            </Col>
                        </Row>
                    </div>
                </div>
            </Link>
        </div>
    );
}

export default withLoader(injectIntl(InterestedEmployeeCard));
