import { take, call, put, select } from 'redux-saga/effects'
import { getUnreadMessages } from '../services/messagesService';
import { CHECK_UNREAD_MESSAGES, SET_CONVERSATIONS } from '../actions/conversationActions';

export function* conversationsSaga() {
    while (true) {
        yield take(CHECK_UNREAD_MESSAGES);
        const user = yield select(state => state.user);
        const conversations = yield call(getUnreadMessages, user);
        yield put({ type: SET_CONVERSATIONS, conversations });
    }
}

