import { defineMessages } from "react-intl";

export default defineMessages({
    BadenBaden: { id: "city.BadenBaden", defaultMessage: "Baden-Baden" },
    FreiburgimBreisgau: { id: "city.FreiburgimBreisgau", defaultMessage: "Freiburg im Breisgau" },
    Heidelberg: { id: "city.Heidelberg", defaultMessage: "Heidelberg" },
    Heibron: { id: "city.Heibron", defaultMessage: "Heibron" },
    Karlsruhe: { id: "city.Karlsruhe", defaultMessage: "Karlsruhe" },
    Mannheim: { id: "city.Mannheim", defaultMessage: "Mannheim" },
    Pforzheim: { id: "city.Pforzheim", defaultMessage: "Pforzheim" },
    Stuttgart: { id: "city.Stuttgart", defaultMessage: "Stuttgart" },
    Ulm: { id: "city.Ulm", defaultMessage: "Ulm" },
    Amberg: { id: "city.Amberg", defaultMessage: "Amberg" },
    Ansbach: { id: "city.Ansbach", defaultMessage: "Ansbach" },
    Aschaffenburg: { id: "city.Aschaffenburg", defaultMessage: "Aschaffenburg" },
    Augsburg: { id: "city.Augsburg", defaultMessage: "Augsburg" },
    Bamberg: { id: "city.Bamberg", defaultMessage: "Bamberg" },
    Bayreuth: { id: "city.Bayreuth", defaultMessage: "Bayreuth" },
    Coburg: { id: "city.Coburg", defaultMessage: "Coburg" },
    Erlangen: { id: "city.Erlangen", defaultMessage: "Erlangen" },
    Furth: { id: "city.Furth", defaultMessage: "Furth" },
    Hof: { id: "city.Hof", defaultMessage: "Hof" },
    Ingolstadt: { id: "city.Ingolstadt", defaultMessage: "Ingolstadt" },
    Kaufbeuren: { id: "city.Kaufbeuren", defaultMessage: "Kaufbeuren" },
    Kempten: { id: "city.Kempten", defaultMessage: "Kempten" },
    Landshut: { id: "city.Landshut", defaultMessage: "Landshut" },
    Memmingen: { id: "city.Memmingen", defaultMessage: "Memmingen" },
    Munich: { id: "city.Munich", defaultMessage: "Munich" },
    Nuremberg: { id: "city.Nuremberg", defaultMessage: "Nuremberg" },
    Passau: { id: "city.Passau", defaultMessage: "Passau" },
    Regensburg: { id: "city.Regensburg", defaultMessage: "Regensburg" },
    Rosenheim: { id: "city.Rosenheim", defaultMessage: "Rosenheim" },
    Schwabach: { id: "city.Schwabach", defaultMessage: "Schwabach" },
    Schweinfurt: { id: "city.Schweinfurt", defaultMessage: "Schweinfurt" },
    Straubing: { id: "city.Straubing", defaultMessage: "Straubing" },
    Weiden: { id: "city.Weiden", defaultMessage: "Weiden" },
    Wurzburg: { id: "city.Wurzburg", defaultMessage: "Wurzburg" },
    Potsdam: { id: "city.Potsdam", defaultMessage: "Potsdam" },
    Cottbus: { id: "city.Cottbus", defaultMessage: "Cottbus" },
    BrandenburganderHavel: {
        id: "city.Brandenburg an der Havel",
        defaultMessage: "Brandenburg an der Havel",
    },
    FrankfurtOder: { id: "city.Frankfurt Oder", defaultMessage: "Frankfurt Oder" },
    Oranienburg: { id: "city.Oranienburg", defaultMessage: "Oranienburg" },
    Falkensee: { id: "city.Falkensee", defaultMessage: "Falkensee" },
    Eberswalde: { id: "city.Eberswalde", defaultMessage: "Eberswalde" },
    BernaubeiBerlin: { id: "city.Bernau bei Berlin", defaultMessage: "Bernau bei Berlin" },
    KonigsWusterhausen: { id: "city.Konigs Wusterhausen", defaultMessage: "Konigs Wusterhausen" },
    Furstenwalde: { id: "city.Furstenwalde", defaultMessage: "Furstenwalde" },
    Neuruppin: { id: "city.Neuruppin", defaultMessage: "Neuruppin" },
    Schwedt: { id: "city.Schwedt", defaultMessage: "Schwedt" },
    BlankenFeldeMahlow: { id: "city.BlankenFelde Mahlow", defaultMessage: "BlankenFelde Mahlow" },
    Strausberg: { id: "city.Strausberg", defaultMessage: "Strausberg" },
    Hennigsdorf: { id: "city.Hennigsdorf", defaultMessage: "Hennigsdorf" },
    HohenNeuendorf: { id: "city.Hohen Neuendorf", defaultMessage: "Hohen Neuendorf" },
    Teltow: { id: "city.Teltow", defaultMessage: "Teltow" },
    Werder: { id: "city.Werder", defaultMessage: "Werder" },
    Ludwigsfelde: { id: "city.Ludwigsfelde", defaultMessage: "Ludwigsfelde" },
    Eisenhuttenstadt: { id: "city.Eisenhuttenstadt", defaultMessage: "Eisenhuttenstadt" },
    Senftenberg: { id: "city.Senftenberg", defaultMessage: "Senftenberg" },
    Rathenow: { id: "city.Rathenow", defaultMessage: "Rathenow" },
    Wandlitz: { id: "city.Wandlitz", defaultMessage: "Wandlitz" },
    Spremberg: { id: "city.Spremberg", defaultMessage: "Spremberg" },
    Luckenwalde: { id: "city.Luckenwalde", defaultMessage: "Luckenwalde" },
    Kleinmachnow: { id: "city.Kleinmachnow", defaultMessage: "Kleinmachnow" },
    Panketal: { id: "city.Panketal", defaultMessage: "Panketal" },
    Prenzlau: { id: "city.Prenzlau", defaultMessage: "Prenzlau" },
    Zossen: { id: "city.Zossen", defaultMessage: "Zossen" },
    Forst: { id: "city.Forst", defaultMessage: "Forst" },
    NeuenhagenbeiBerlin: {
        id: "city.Neuenhagen bei Berlin",
        defaultMessage: "Neuenhagen bei Berlin",
    },
    Hoppegarten: { id: "city.Hoppegarten", defaultMessage: "Hoppegarten" },
    Nauen: { id: "city.Nauen", defaultMessage: "Nauen" },
    Wittenberge: { id: "city.Wittenberge", defaultMessage: "Wittenberge" },
    Guben: { id: "city.Guben", defaultMessage: "Guben" },
    Finsterwalde: { id: "city.Finsterwalde", defaultMessage: "Finsterwalde" },
    Lubbenau: { id: "city.Lubbenau", defaultMessage: "Lubbenau" },
    Templin: { id: "city.Templin", defaultMessage: "Templin" },
    RudersdorfbeiBerlin: {
        id: "city.RudersdorfbeiBerlin",
        defaultMessage: "RudersdorfbeiBerlin",
    },
    Stahnsdorf: { id: "city.Stahnsdorf", defaultMessage: "Stahnsdorf" },
    PetershagenEggersdorf: {
        id: "city.PetershagenEggersdorf",
        defaultMessage: "Petershagen Eggersdorf",
    },
    BremenCity: { id: "city.Bremen", defaultMessage: "Bremen" },
    Bremerhaven: { id: "city.Bremerhaven", defaultMessage: "Bremerhaven" },
    HamburgCity: { id: "city.HamburgCity", defaultMessage: "Hamburg" },
    Darmstadt: { id: "city.Darmstadt", defaultMessage: "Darmstadt" },
    FrankfurtamMain: { id: "city.FrankfurtamMain", defaultMessage: "Frankfurt am Main" },
    Kassel: { id: "city.Kassel", defaultMessage: "Kassel" },
    OffenbachamMain: { id: "city.OffenbachamMain", defaultMessage: "Offenbach am Main" },
    Wiesbaden: { id: "city.Wiesbaden", defaultMessage: "Wiesbaden" },
    Hanover: { id: "city.Hanover", defaultMessage: "Hanover" },
    Braunschweig: { id: "city.Braunschweig", defaultMessage: "Braunschweig" },
    Oldenburg: { id: "city.Oldenburg", defaultMessage: "Oldenburg" },
    Osnabruck: { id: "city.Osnabruck", defaultMessage: "Osnabruck" },
    Wolfsburg: { id: "city.Wolfsburg", defaultMessage: "Wolfsburg" },
    Gottingen: { id: "city.Gottingen", defaultMessage: "Gottingen" },
    Salzgitter: { id: "city.Salzgitter", defaultMessage: "Salzgitter" },
    Hildesheim: { id: "city.Hildesheim", defaultMessage: "Hildesheim" },
    Delmenhorst: { id: "city.Delmenhorst", defaultMessage: "Delmenhorst" },
    Wihelmshaven: { id: "city.Wihelmshaven", defaultMessage: "Wihelmshaven" },
    Luneburg: { id: "city.Luneburg", defaultMessage: "Luneburg" },
    Celle: { id: "city.Celle", defaultMessage: "Celle" },
    Garbsen: { id: "city.Garbsen", defaultMessage: "Garbsen" },
    Hamelin: { id: "city.Hamelin", defaultMessage: "Hamelin" },
    Lingen: { id: "city.Lingen", defaultMessage: "Lingen" },
    Langengagen: { id: "city.Langengagen", defaultMessage: "Langengagen" },
    Nordhorn: { id: "city.Nordhorn", defaultMessage: "Nordhorn" },
    Wolfenbuttel: { id: "city.Wolfenbuttel", defaultMessage: "Wolfenbuttel" },
    Goslar: { id: "city.Goslar", defaultMessage: "Goslar" },
    Emden: { id: "city.Emden", defaultMessage: "Emden" },
    Rostock: { id: "city.Rostock", defaultMessage: "Rostock" },
    Schwerin: { id: "city.Schwerin", defaultMessage: "Schwerin" },
    Neubrandenburg: { id: "city.Neubrandenburg", defaultMessage: "Neubrandenburg" },
    Stralsund: { id: "city.Stralsund", defaultMessage: "Stralsund" },
    Greifswald: { id: "city.Greifswald", defaultMessage: "Greifswald" },
    Wismar: { id: "city.Wismar", defaultMessage: "Wismar" },
    Gustrow: { id: "city.Gustrow", defaultMessage: "Gustrow" },
    Waren: { id: "city.Waren", defaultMessage: "Waren" },
    Neustrelitz: { id: "city.Neustrelitz", defaultMessage: "Neustrelitz" },
    Parchim: { id: "city.Parchim", defaultMessage: "Parchim" },
    RibnitzDamgarten: { id: "city.RibnitzDamgarten", defaultMessage: "Ribnitz Damgarten" },
    BergenaufRugen: { id: "city.BergenaufRugen", defaultMessage: "Bergen auf Rugen" },
    Anklam: { id: "city.Anklam", defaultMessage: "Anklam" },
    BadDoberan: { id: "city.BadDoberan", defaultMessage: "Bad Doberan" },
    Ludwigslust: { id: "city.Ludwigslust", defaultMessage: "Ludwigslust" },
    Wolgast: { id: "city.Wolgast", defaultMessage: "Wolgast" },
    Hagenow: { id: "city.Hagenow", defaultMessage: "Hagenow" },
    Demmin: { id: "city.Demmin", defaultMessage: "Demmin" },
    Boizenburg: { id: "city.Boizenburg", defaultMessage: "Boizenburg" },
    Grevesmuhlen: { id: "city.Grevesmuhlen", defaultMessage: "Grevesmuhlen" },
    Cologne: { id: "city.Cologne", defaultMessage: "Cologne" },
    Dusseldorf: { id: "city.Dusseldorf", defaultMessage: "Dusseldorf" },
    Dortmund: { id: "city.Dortmund", defaultMessage: "Dortmund" },
    Essen: { id: "city.Essen", defaultMessage: "Essen" },
    Duisburg: { id: "city.Duisburg", defaultMessage: "Duisburg" },
    Bochum: { id: "city.Bochum", defaultMessage: "Bochum" },
    Wuppertal: { id: "city.Wuppertal", defaultMessage: "Wuppertal" },
    Bielefeld: { id: "city.Bielefeld", defaultMessage: "Bielefeld" },
    Bonn: { id: "city.Bonn", defaultMessage: "Bonn" },
    Munster: { id: "city.Munster", defaultMessage: "Munster" },
    Mainz: { id: "city.Mainz", defaultMessage: "Mainz" },
    LudwigshafenamRhein: {
        id: "city.LudwigshafenamRhein",
        defaultMessage: "Ludwigshafen am Rhein",
    },
    Koblenz: { id: "city.Koblenz", defaultMessage: "Koblenz" },
    Trier: { id: "city.Trier", defaultMessage: "Trier" },
    Kaiserslaurern: { id: "city.Kaiserslaurern", defaultMessage: "Kaiserslaurern" },
    Worms: { id: "city.Worms", defaultMessage: "Worms" },
    Neuwied: { id: "city.Neuwied", defaultMessage: "Neuwied" },
    NeustadtanderWeinstrase: {
        id: "city.NeustadtanderWeinstrase",
        defaultMessage: "Neustadt an der Weinstrase",
    },
    Speyer: { id: "city.Speyer", defaultMessage: "Speyer" },
    BadKreuznach: { id: "city.BadKreuznach", defaultMessage: "Bad Kreuznach" },
    BerlinCity: { id: "city.BerlinCity", defaultMessage: "Berlin City" },
    Saarbrucken: { id: "city.Saarbrucken", defaultMessage: "Saarbrucken" },
    Neunkirchen: { id: "city.Neunkirchen", defaultMessage: "Neunkirchen" },
    Homburg: { id: "city.Homburg", defaultMessage: "Homburg" },
    Volklingen: { id: "city.Volklingen", defaultMessage: "Volklingen" },
    SanktIngbert: { id: "city.SanktIngbert", defaultMessage: "Sankt Ingbert" },
    Saarlouis: { id: "city.Saarlouis", defaultMessage: "Saarlouis" },
    Merzig: { id: "city.Merzig", defaultMessage: "Merzig" },
    SanktWendel: { id: "city.SanktWendel", defaultMessage: "Sankt Wendel" },
    Blieskastel: { id: "city.Blieskastel", defaultMessage: "Blieskastel" },
    Dillingen: { id: "city.Dillingen", defaultMessage: "Dillingen" },
    Leipzig: { id: "city.Leipzig", defaultMessage: "Leipzig" },
    Dresden: { id: "city.Dresden", defaultMessage: "Dresden" },
    Chemnitz: { id: "city.Chemnitz", defaultMessage: "Chemnitz" },
    Zwickau: { id: "city.Zwickau", defaultMessage: "Zwickau" },
    Plauen: { id: "city.Plauen", defaultMessage: "Plauen" },
    Gorlitz: { id: "city.Gorlitz", defaultMessage: "Gorlitz" },
    Freiburg: { id: "city.Freiburg", defaultMessage: "Freiburg" },
    Freital: { id: "city.Freital", defaultMessage: "Freital" },
    Bautzen: { id: "city.Bautzen", defaultMessage: "Bautzen" },
    Pirna: { id: "city.Pirna", defaultMessage: "Pirna" },
    Halle: { id: "city.Halle", defaultMessage: "Halle" },
    Magdeburg: { id: "city.Magdeburg", defaultMessage: "Magdeburg" },
    DessauRoslau: { id: "city.DessauRoslau", defaultMessage: "Dessau Roslau" },
    LutherstadtWittenberg: {
        id: "city.LutherstadtWittenberg",
        defaultMessage: "Lutherstadt Wittenberg",
    },
    Weisenfels: { id: "city.Weisenfels", defaultMessage: "Weisenfels" },
    Halberstadt: { id: "city.Halberstadt", defaultMessage: "Halberstadt" },
    Stendal: { id: "city.Stendal", defaultMessage: "Stendal" },
    BitterfeldWolfen: { id: "city.BitterfeldWolfen", defaultMessage: "Bitterfeld Wolfen" },
    Merseburg: { id: "city.Merseburg", defaultMessage: "Merseburg" },
    Bernburg: { id: "city.Bernburg", defaultMessage: "Bernburg" },
    Erfurt: { id: "city.Erfurt", defaultMessage: "Erfurt" },
    Jena: { id: "city.Jena", defaultMessage: "Jena" },
    Gera: { id: "city.Gera", defaultMessage: "Gera" },
    Weimar: { id: "city.Weimar", defaultMessage: "Weimar" },
    Gotha: { id: "city.Gotha", defaultMessage: "Gotha" },
    Nordhausen: { id: "city.Nordhausen", defaultMessage: "Nordhausen" },
    Eisenach: { id: "city.Eisenach", defaultMessage: "Eisenach" },
    Suhl: { id: "city.Suhl", defaultMessage: "Suhl" },
    Altenburg: { id: "city.Altenburg", defaultMessage: "Altenburg" },
    Muhlhausen: { id: "city.Muhlhausen", defaultMessage: "Muhlhausen" },
    Kiel: { id: "city.Kiel", defaultMessage: "Kiel" },
    Lubeck: { id: "city.Lubeck", defaultMessage: "Lubeck" },
    Flensburg: { id: "city.Flensburg", defaultMessage: "Flensburg" },
    Neumunster: { id: "city.Neumunster", defaultMessage: "Neumunster" },
    Norderstedt: { id: "city.Norderstedt", defaultMessage: "Norderstedt" },
    Elmshorn: { id: "city.Elmshorn", defaultMessage: "Elmshorn" },
    Pinneberg: { id: "city.Pinneberg", defaultMessage: "Pinneberg" },
    Wedel: { id: "city.Wedel", defaultMessage: "Wedel" },
    Ahrensburg: { id: "city.Ahrensburg", defaultMessage: "Ahrensburg" },
    Itzehoe: { id: "city.Itzehoe", defaultMessage: "Itzehoe" },
    Other: { id: "city.Other", defaultMessage: "Other" },
});
