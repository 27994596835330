import React, { useState, useCallback } from "react";
import loading_white from "./Assets/loading_white.gif";
import loading_grey from "./Assets/loading_grey.gif";
import "./withLoader.less";

function getDisplayName(WrappedComponent) {
    return WrappedComponent.displayName || WrappedComponent.name || "Component";
}

export const withLoader = (WrappedComponent, color = "grey", width = 250) => {
    const Result = props => {
        const [isLoading, setIsLoading] = useState(true);
        const setLoading = useCallback(l => {
            setIsLoading(l);
        }, []);
        return (
            <div className={`with-loader-container ${isLoading ? "loading" : "done"}`}>
                <WrappedComponent {...props} setLoading={setLoading} />

                <div className={`loading-indicator-container ${color === "white" ? "white" : ""}`}>
                    <LoadingIndicator color={color} width={width} />
                </div>
            </div>
        );
    };

    Result.displayName = `WithLoader(${getDisplayName(WrappedComponent)})`;
    return Result;
};

export const LoadingIndicator = ({ color = "grey", width = 250 }) => {
    if (color === "white") {
        return <img src={loading_white} alt="loading..." style={{ maxWidth: width }} />;
    } else {
        return <img src={loading_grey} alt="loading..." style={{ maxWidth: width }} />;
    }
};

export default { withLoader, LoadingIndicator };
