import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

class RedirectIfLoggedIn extends Component {
  render() {
    if( this.props.token && this.props.loggedIn ){
      return(
        <Redirect to={{ pathname: "/Home" }} />
      );
    } else {
      return (null);
    }
  }
}

function mapStateToProps(state) {
  const { token } = state.user;
  const { loggedIn } = state.login;
  return { token, loggedIn };
}

export default connect(mapStateToProps)(RedirectIfLoggedIn);
