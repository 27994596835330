import { defineMessages } from "react-intl";

export default defineMessages({
    English: { id: "English", defaultMessage: "English" },
    German: { id: "German", defaultMessage: "German" },
    Croatian: { id: "Croatian", defaultMessage: "Croatian" },
    Bulgarian: { id: "Bulgarian", defaultMessage: "Bulgarian" },
    Czech: { id: "Czech", defaultMessage: "Czech" },
    Danish: { id: "Danish", defaultMessage: "Danish" },
    Dutch: { id: "Dutch", defaultMessage: "Dutch" },
    Estonian: { id: "Estonian", defaultMessage: "Estonian" },
    Finnish: { id: "Finnish", defaultMessage: "Finnish" },
    French: { id: "French", defaultMessage: "French" },
    Greek: { id: "Greek", defaultMessage: "Greek" },
    Hungarian: { id: "Hungarian", defaultMessage: "Hungarian" },
    Irish: { id: "Irish", defaultMessage: "Irish" },
    Italian: { id: "Italian", defaultMessage: "Italian" },
    Latvian: { id: "Latvian", defaultMessage: "Latvian" },
    Lithuanian: { id: "Lithuanian", defaultMessage: "Lithuanian" },
    Maltese: { id: "Maltese", defaultMessage: "Maltese" },
    Polish: { id: "Polish", defaultMessage: "Polish" },
    Portuguese: { id: "Portuguese", defaultMessage: "Portuguese" },
    Romanian: { id: "Romanian", defaultMessage: "Romanian" },
    Slovak: { id: "Slovak", defaultMessage: "Slovak" },
    Slovenian: { id: "Slovenian", defaultMessage: "Slovenian" },
    Spanish: { id: "Spanish", defaultMessage: "Spanish" },
    Swedish: { id: "Swedish", defaultMessage: "Swedish" },
});
