export const ADD_JOB_OFFER_ID = 'ADD_JOB_OFFER_ID';
export const REMOVE_JOB_OFFER_ID = 'REMOVE_JOB_OFFER_ID';
export const ADD_UNREAD_JOB_APPLICATION = 'ADD_UNREAD_JOB_APPLICATION';
export const REMOVE_UNREAD_JOB_APPLICATIONS = 'REMOVE_UNREAD_JOB_APPLICATIONS';

export function AddJobOfferId(jobOfferId) {
    return { type: ADD_JOB_OFFER_ID, jobOfferId };
}

export function RemoveJobOfferId(jobOfferId) {
    return { type: REMOVE_JOB_OFFER_ID, jobOfferId };
}

export function AddUnreadJobApplication(jobAppGuid) {
    return { type: ADD_UNREAD_JOB_APPLICATION, jobAppGuid };
}

export function RemoveUnreadJobApplications() {
    return { type: REMOVE_UNREAD_JOB_APPLICATIONS };
}