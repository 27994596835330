import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Row, Col, Popover } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment';
import './EducationCard.less';


function EducationCard(props) {
    const user = useSelector(state => state.user);

    const content = (
        <FormattedMessage
            id="professionalInfo"
            defaultMessage="Professional nurse as defined in Paragraph 71 of the German Social Code (SGB) stating Germany�s Social Law, book #XI, Paragraph 3
        i.e., care specialists, healthcare specialists, qualified nurse, etc. - meaning you earned your profession / specialization / qualification / certification 
        in an apprenticeship or by having studied at a college or university (or equivalent thereof)."
        />
    );

    return (
        <div className="education-container">
            <div className="education-cards-container" key={props.education.id}>
                <Row wrap={false}>
                    <Col span={10}>
                        <p className="bolded-text">
                            <FormattedMessage
                                id="educationalInstitution"
                                defaultMessage="Educational institution"
                            />
                            :
                        </p>
                    </Col>
                    <Col>{props.education.educationalInstitution}</Col>
                </Row>
                <Row wrap={false}>
                    <Col span={10}>
                        <p className="bolded-text">
                            <FormattedMessage
                                id="educationalInstitutionType"
                                defaultMessage="Type"
                            />
                            :
                        </p>
                    </Col>
                    <Col>
                        {props.education.educationalInstitutionType === 0
                            ? <FormattedMessage id="highschool" />
                            : props.education.educationalInstitutionType === 1
                            ? <FormattedMessage id="college" />
                            : props.education.educationalInstitutionType === 2 
                            ? <FormattedMessage id="university" />
                            : <FormattedMessage id="apprenticeship" />
                            }
                    </Col>
                </Row>
                <Row wrap={false}>
                    <Col span={10}>
                        <p className="bolded-text">
                            <FormattedMessage
                                id="filter.proffesional"
                                defaultMessage="Proffesional"
                            />
                            <Popover content={content}>
                                <InfoCircleOutlined id="info-icon" />
                            </Popover>
                        </p>
                    </Col>
                    <Col>
                    {props.education.isProfessional ? <FormattedMessage id="yes" /> : <FormattedMessage id="no" />}
                    </Col>
                </Row>
                <Row wrap={false}>
                    <Col span={10}>
                        <p className="bolded-text">
                            <FormattedMessage id="date" />:
                        </p>
                    </Col>
                    <Col>
                        {moment(props.education.start).format('DD/MM/YYYY ')}-
                        {moment(props.education.end).format(' DD/MM/YYYY')}
                    </Col>
                </Row>

                <div className="line" />
                <div className="edit-button">
                    {/* <Button
                        className="button"
                        onClick={props.handleEducationDetails.bind(
                            this,
                            props.education.id
                        )}
                    >
                        <FormattedMessage
                            id="seeDetails"
                            defaultMessage="See details"
                        /> 
                    </Button> */}
                    {user.role === 'Employee' &&
                    user.info === props.employeeId ? (
                        <Button
                            className="button"
                            onClick={props.handleEdit.bind(
                                this,
                                props.education.guid
                            )}
                        >
                            <FormattedMessage
                                id="education.card.editEducation"
                                defaultMessage="Edit"
                            />
                        </Button>
                    ) : (
                        ''
                    )}
                </div>
            </div>
        </div>
    );
}

export default injectIntl(EducationCard);
