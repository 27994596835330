import React from 'react';
import {
    ADD_UNREAD_MSG,
    CLEAR_CONVERSATION_UNREAD_MSG,
    SET_CONVERSATIONS,
    EDIT_MSG,
    DELETE_MSG,
} from '../actions/conversationActions';
import { ReactComponent as Conversation } from '../containers/CustomSider/Icons/Conversation_icon.svg';
import { message } from 'antd';

var savedState = sessionStorage.getItem('conversationsData')
    ? JSON.parse(sessionStorage.getItem('conversationsData'))
    : null;

const initialState = {};

function displayMessageToast(msg) {
    let text =
        msg.message.length > 200
            ? `${msg.message.substring(0, 150)}...`
            : msg.message;

    let config = {
        content: (
            <span style={{ marginRight: '10px' }}>
                <span
                    style={{ fontFamily: 'Nunito-Bold', marginLeft: '20px' }}
                >{`${msg.senderName}: `}</span>
                {text}
            </span>
        ),
        icon: <Conversation />,
    };

    message.info(config);
}

function conversationsReducer(state = savedState || initialState, action) {
    switch (action.type) {
        case ADD_UNREAD_MSG: {
            let convo = state[action.msg.conversation];
            convo = convo ? convo : [];
            convo = [...convo, action.msg];
            let newState = { ...state };
            newState[action.msg.conversation] = convo;
            sessionStorage.setItem(
                'conversationsData',
                JSON.stringify(newState)
            );
            displayMessageToast(action.msg);
            return newState;
        }
        case CLEAR_CONVERSATION_UNREAD_MSG: {
            let newState = { ...state };
            newState[action.convoId] = null;
            sessionStorage.setItem(
                'conversationsData',
                JSON.stringify(newState)
            );
            return newState;
        }
        case SET_CONVERSATIONS: {
            sessionStorage.setItem(
                'conversationsData',
                JSON.stringify(action.conversations)
            );
            return action.conversations;
        }
        case EDIT_MSG: {
            let convo = state[action.msg.conversation];
            convo = convo ? convo : [];
            convo = [...convo, action.msg];
            let newState = { ...state };
            newState[action.msg.conversation] = convo;
            sessionStorage.setItem(
                'conversationsData',
                JSON.stringify(newState)
            );
            return newState;
        }
        case DELETE_MSG: {
            let convo = state[action.msg.conversation];
            convo = convo ? convo : [];
            convo = [...convo, action.msg];
            let newState = { ...state };
            newState[action.msg.conversation] = convo;
            sessionStorage.setItem(
                'conversationsData',
                JSON.stringify(newState)
            );
            return newState;
        }
        default:
            return state;
    }
}

export default conversationsReducer;
