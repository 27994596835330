import { take, call, put, select } from 'redux-saga/effects';
import { getPositions } from '../services/positionService';
import { getLocations } from '../services/locationService';
import { getAllLanguages } from '../services/LanguagesService';
import { ADD_LOCATIONS } from '../actions/locationActions';
import { ADD_POSITIONS } from '../actions/positionActions';
import { ADD_LANGUAGES } from '../actions/languageActions';
import { FETCH_INITIAL_DATA } from '../actions/initialDataActions';

export function* initialDataFlow() {
    while (true) {
        yield take(FETCH_INITIAL_DATA);
        const user = yield select(state => state.user);

        const positions = yield call(getPositions, user, null);
        const locations = yield call(getLocations, user);
        const languages = yield call(getAllLanguages, user);

        sessionStorage.setItem('positions', JSON.stringify(positions));
        sessionStorage.setItem('locations', JSON.stringify(locations));
        sessionStorage.setItem('languages', JSON.stringify(languages));

        yield put({ type: ADD_LOCATIONS, locations });
        yield put({ type: ADD_POSITIONS, positions });
        yield put({ type: ADD_LANGUAGES, languages });
    }
}
