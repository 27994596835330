import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Row, Col, Button, Tooltip, Divider } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { removePaymentInfo, getConfirmPaymentInfo, confirmPaymentInfo, getCompanyPaymentMethod } from '../../services/companyService';
import { refreshUser } from '../../actions/userActions';
import { modifyPaymentMethod } from '../../actions/companyActions';
import { displayMessageToast } from '../../Helpers/ToastHelper';
import ConditionalWrapper from '../../components/ConditionalWrapper';
import CardSection from '../../components/CardSection/CardSection';
import './ModifyCreditCardModal.less';

export default function ModifyCreditCardModal(props) {
    const [creditCardError, setCreditCardError] = useState('');
    const [updating, setUpdating] = useState(false);
    const [removing, setRemoving] = useState(false);
    const user = useSelector(state => state.user);
    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useDispatch();

    const handleReplaceCreditCard = async () => {
        if (!stripe || !elements) {
            return;
        }

        try {
            setUpdating(true);

            const result = await stripe.createPaymentMethod({
                type: 'card',
                card: elements.getElement(CardElement)
            });

            if (result.error) {
                throw Error(result.error.message);
            } 
            dispatch(modifyPaymentMethod(user, props.companyId, result.paymentMethod.id));
            props.close();
        } catch (ex) {
            setCreditCardError(ex.message);
        } finally {
            setUpdating(false);
        }
    };

    const handleConfirmCreditCard = async () => {
        setUpdating(true);
        try {
            let clientSecret = await getConfirmPaymentInfo(user, props.companyId);
            let pm = await getCompanyPaymentMethod(user, props.companyId)
            const stripeResponse = await stripe.confirmCardSetup(clientSecret, { payment_method: pm })

            if (stripeResponse.error) {
                displayMessageToast(stripeResponse.error.message, false);
            } 
            else {
                await confirmPaymentInfo(user, props.companyId);
                displayMessageToast("Payment method confirmed successfully!");
            }
            dispatch(refreshUser());
            props.close();
        } catch (ex){
            displayMessageToast(ex.message, false);
        } finally {
            setUpdating(false);
        }
    };

    const handleRemoveCreditCard = async () => {
        setRemoving(true);
        await removePaymentInfo(user, props.companyId);
        dispatch(refreshUser());
        setRemoving(false);
        props.close();
    };

    return (
        <Modal
            title={
                <FormattedMessage
                    id="modifyCardModalTitle"
                    defaultMessage="Modify credit card info" />
            }
            footer={[
                <Button key="back" onClick={props.close}>
                  Close
                </Button>
              ]}
            onCancel={props.close}
            visible={props.show}
            centered
            closable
            width={props.needsConfirmation ? "240px" : "60%" }
            className="modify-card-modal"
        >
            <Row justify = {props.needsConfirmation ? "center" : "start" }>
                {props.needsConfirmation ? 
                    <Col>
                        <Button 
                            className="button" 
                            onClick={handleConfirmCreditCard}>
                            <FormattedMessage
                                id="completeCardSetup"
                                defaultMessage="Complete card setup"
                            />
                        </Button>
                    </Col> :
                    <React.Fragment>
                        <Col className="card-container">
                            <CardSection />
                            {creditCardError && <p className="error-text">{creditCardError}</p>}
                        </Col>
                        <Col>
                            <Button 
                                id="replace-card-btn"
                                className="button" 
                                loading={updating}
                                onClick={handleReplaceCreditCard}>
                                <FormattedMessage
                                    id="replaceCreditCard"
                                    defaultMessage="Replace credit card"
                                />
                            </Button>
                        </Col>
                </React.Fragment>}
            </Row>
            <Divider>Or</Divider>
            <Row justify = {props.needsConfirmation ? "center" : "start" }>
                <ConditionalWrapper
                    condition={user.subscriptionStatus !== "no_subscription"}
                    wrapper={c => 
                        <Tooltip
                            title={<FormattedMessage 
                                id="cantRemoveCreditCard" 
                                defaultMessage="Cannot remove credit card info while a subscrption is active!"/>}
                        >
                            {c}
                        </Tooltip>}
                >
                    <Button 
                        className="button" 
                        disabled={user.subscriptionStatus !== "no_subscription"}
                        loading={removing}
                        onClick={handleRemoveCreditCard}>
                        <FormattedMessage
                            id="removeCreditCard"
                            defaultMessage="Remove credit card"
                        />
                    </Button>
                </ConditionalWrapper>
            </Row>
        </Modal>
    )
}