import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from './reducers/root';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './sagas/root';
import signalRMiddleware from './reducers/signalrMiddleware';

const sagaMiddleware = createSagaMiddleware();
const reduxDevTools = window.devToolsExtension ? window.devToolsExtension() : f => f;
//const reduxDevTools = f => f;
const middleware = compose(applyMiddleware(sagaMiddleware, signalRMiddleware), reduxDevTools);

const configureStore = (initialState) => {
  const store = createStore(rootReducer, initialState, middleware);
  sagaMiddleware.run(rootSaga);
  return { ...store}
}

export default configureStore;
