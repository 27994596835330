import { getAuthorizationProperties } from "./authorizationService";

export async function getNews(user, lang) {
    const response = await fetch(`/api/news?language=${lang}`, {
        method: "GET",
        ...getAuthorizationProperties(user.token, {
            "Content-Type": "application/json",
        }),
    });
    return await response.json();
}
