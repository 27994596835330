export const SET_LANGUAGE = 'SET_LANGUAGE';
export const REFRESH_USER = "REFRESH_USER";

export function setLanguage (language) {
    return { type: SET_LANGUAGE, language };
}

export function refreshUser() {
    return { type: REFRESH_USER };
}
