import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Input, Button, DatePicker, Select, Form } from "antd";
import moment from "moment";
import { useSelector } from "react-redux";
import { postJobOffer } from "../../services/jobOfferService";
import TagsSelector from "../../components/TagsSelector/TagsSelector";
import { FormattedMessage, defineMessages, injectIntl } from "react-intl";
import LanguageMessages from "../Language/LanguageMessages";
import { FormatPositions } from "../../Helpers/PositionHelper";
import { FormatLocations } from "../../Helpers/LocationHelper";
import { getJobLocations, getCitiesForRegion } from "../../services/locationService";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./CreateJobOffer.less";
import { FormatCities } from "../../Helpers/CityHelper";

let messages = defineMessages({
    setAmount: {
        id: "setAmount",
        defaultMessage: "Set amount",
    },
    startDate: {
        id: "startDate",
        defaultMessage: "Start date",
    },
    endDate: {
        id: "endDate",
        defaultMessage: "End date",
    },
    setTitle: {
        id: "setTitle",
        defaultMessage: "Set title",
    },
    applicationDeadlineDate: {
        id: "applicationDeadlineDate",
        defaultMessage: "Select date",
    },
});

const CreateJobOffer = props => {
    const [description, setDescription] = useState("");
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [location, setLocation] = useState([]);
    const [selectedPositions, setSelectedPositions] = useState([]);
    const [isProfessional, setIsProfessional] = useState();
    const [jobType, setJobType] = useState();
    const [title, setTitle] = useState();
    const [selectedLanguages, setSelectedLanguages] = useState([]);
    const [driversLicenceNeeded, setDriversLicenceNeeded] = useState();
    const [applicationDeadline, setApplicationDeadline] = useState(null);
    const [jobLocations, setJobLocations] = useState([]);
    const [parametersVisible, setParametersVisible] = useState(false);
    const [cityLocations, setCityLocations] = useState([]);
    const [city, setCity] = useState();
    const [countryside, setCountryside] = useState();
    const [regionSelected, setRegionSelected] = useState(false);
    const [citySelected, setCitySelected] = useState(false);

    const user = useSelector(state => state.user);
    const positions = useSelector(state => state.positions);
    const languages = useSelector(state => state.languages);
    const history = useHistory();

    const [positionOpen, setPositionOpen] = useState(false);
    const [languageOpen, setLanguageOpen] = useState(false);
    const [locationOpen, setLocationOpen] = useState(false);
    const [cityOpen, setCityOpen] = useState(false);

    const { Option } = Select;
    const FormItem = Form.Item;
    const { RangePicker } = DatePicker;

    useEffect(() => {
        let fetchJobLocations = () => {
            getJobLocations(user).then(setJobLocations);
        };
        fetchJobLocations();
    }, [user]);

    let fetchCitesForRegion = useCallback(() => {
        if (location.length !== 0) {
            getCitiesForRegion(user, location).then(setCityLocations);
        }
    }, [location]);

    useEffect(() => {
        fetchCitesForRegion();
    }, [fetchCitesForRegion]);

    let onDescriptionChange = (content, delta, source, editor) => {
        if (editor.getText() != "") {
            setDescription(editor.getHTML());
        } else {
            setDescription("");
        }
    };

    let handleDateChange = values => {
        setStartDate(values[0].format("YYYY-MM-DD"));
        if (values[1] !== null) setEndDate(values[1].format("YYYY-MM-DD"));
    };

    let handleApplicationDeadlineChange = values => {
        if (values === null) {
            setApplicationDeadline(null);
        } else {
            setApplicationDeadline(values.format("YYYY-MM-DD"));
        }
    };

    let disabledDate = current => {
        return current && current < moment().endOf("day");
    };

    let applicationDisabledDate = current => {
        let startDateDate = new Date(startDate);
        return current && current >= startDateDate;
    };

    let onSubmit = values => {
        const sDate = new Date(startDate);
        const eDate = new Date(endDate);
        const applicationDeadlineDate = new Date(applicationDeadline);
        const state = {
            companyId: user.info,
            start: startDate != undefined ? sDate.toISOString() : null,
            end: endDate != undefined ? eDate.toISOString() : null,
            locationId: location,
            shortDescription: description.toString("html"),
            positions: [selectedPositions],
            tags: values.tags,
            isProfessional: isProfessional,
            jobType: jobType === "0" ? 0 : jobType === "1" ? 1 : 2,
            title: title,
            countryside: countryside,
            cityId:
                city === undefined || city === null ? "00000000-0000-0000-0000-000000000000" : city,
            languagesRequired: selectedLanguages,
            driversLicenceNeeded: driversLicenceNeeded,
            applicationDeadline: applicationDeadlineDate,
        };

        postJobOffer(user, JSON.stringify(state)).then(() => {
            history.go({
                pathname: "/CompanyJobOffers",
            });
        });
    };

    let onFinish = values => {
        onSubmit(values);
    };

    return (
        <div className="create-job-offer-container">
            <Form
                className="create-job-offer-form"
                onFinish={onFinish}
                layout="vertical"
                initialValues={{ description: description }}
            >
                <div className="create-job-offer-container-grid">
                    <FormItem
                        name="title"
                        label={<FormattedMessage id="setTitle" defaultMessage="Set title" />}
                        rules={[
                            {
                                required: true,
                                message: (
                                    <FormattedMessage
                                        id="titleMandatory"
                                        defaultMessage="Title is mandatory"
                                    />
                                ),
                            },
                        ]}
                    >
                        <Input
                            placeholder={props.intl.formatMessage(messages.setTitle)}
                            onChange={e => setTitle(e.target.value)}
                        />
                    </FormItem>

                    <FormItem
                        name="positions"
                        label={<FormattedMessage id="selectPositions" />}
                        rules={[
                            {
                                required: true,
                                message: (
                                    <FormattedMessage
                                        id="positionMandatory"
                                        defaultMessage="Position field is mandatory"
                                    />
                                ),
                            },
                        ]}
                    >
                        <Select
                            placeholder={<FormattedMessage id="selectPositions" />}
                            onChange={e => setSelectedPositions(e)}
                            onClick={() => setPositionOpen(!positionOpen)}
                            onBlur={() => setPositionOpen(false)}
                            open={positionOpen}
                            showArrow={true}
                            showSearch
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {positions &&
                                FormatPositions([...positions], props.intl).map(position => (
                                    <Option value={position.id} key={position.id}>
                                        {position.name}
                                    </Option>
                                ))}
                        </Select>
                    </FormItem>

                    <FormItem
                        name="jobType"
                        label={<FormattedMessage id="selectJobType" />}
                        rules={[
                            {
                                required: true,
                                message: (
                                    <FormattedMessage
                                        id="jobTypeMandatory"
                                        defaultMessage="Job type is mandatory"
                                    />
                                ),
                            },
                        ]}
                    >
                        <Select
                            onChange={e => setJobType(e)}
                            placeholder={<FormattedMessage id="selectJobType" />}
                            showArrow={true}
                        >
                            <Option value="0" key="0">
                                <FormattedMessage
                                    id="jobType.temporary"
                                    defaultMessage="Temporary"
                                />
                            </Option>
                            <Option value="1" key="1">
                                <FormattedMessage id="jobType.seasonal" defaultMessage="Seasonal" />
                            </Option>
                            <Option value="2" key="2">
                                <FormattedMessage id="jobType.contract" defaultMessage="Contract" />
                            </Option>
                        </Select>
                    </FormItem>
                    <FormItem
                        name="location"
                        label={<FormattedMessage id="selectLocation" />}
                        rules={[
                            {
                                required: true,
                                message: (
                                    <FormattedMessage
                                        id="locationMandatory"
                                        defaultMessage="Location is mandatory"
                                    />
                                ),
                            },
                        ]}
                    >
                        <Select
                            className="select-container"
                            placeholder={<FormattedMessage id="selectLocation" />}
                            onChange={value => {
                                setLocation(value);
                                setRegionSelected(true);
                            }}
                            onClick={() => setLocationOpen(!locationOpen)}
                            onBlur={() => setLocationOpen(false)}
                            open={locationOpen}
                            showArrow={true}
                            showSearch
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                            }
                        >
                            {jobLocations &&
                                FormatLocations([...jobLocations], props.intl).map(location => (
                                    <Option value={location.id} key={location.id}>
                                        {location.name}
                                    </Option>
                                ))}
                        </Select>
                    </FormItem>
                    <FormItem
                        name="city"
                        label="City"
                        className={`city ${regionSelected ? "visible" : ""}`}
                    >
                        <Select
                            onChange={value => {
                                if (value === null) {
                                    setCitySelected(true);
                                } else {
                                    setCitySelected(false);
                                    setCountryside("");
                                }
                                setCity(value);
                            }}
                            onClick={() => setCityOpen(!cityOpen)}
                            onBlur={() => setCityOpen(false)}
                            open={cityOpen}
                            placeholder={<FormattedMessage id="selectCity" />}
                            showArrow={true}
                            showSearch
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                            }
                        >
                            {cityLocations &&
                                FormatCities(
                                    [
                                        {
                                            id: null,
                                            name: "Other",
                                            parentLocationId:
                                                "00000000-0000-0000-0000-000000000000",
                                            level: 3,
                                        },
                                    ],
                                    props.intl
                                )
                                    .concat(FormatCities([...cityLocations], props.intl))
                                    .map(cityLocation => (
                                        <Option value={cityLocation.id} key={cityLocation.id}>
                                            {cityLocation.name}
                                        </Option>
                                    ))}
                        </Select>
                    </FormItem>
                    <FormItem
                        name="countryside"
                        className={`countryside ${citySelected ? "visible" : ""}`}
                    >
                        <Input onChange={event => setCountryside(event.target.value)} />
                    </FormItem>
                </div>
                <div className="add-more-parameters">
                    <Button
                        className="add-more-parameters-button"
                        onClick={() => setParametersVisible(!parametersVisible)}
                    >
                        <FormattedMessage
                            id="add-more-parameters"
                            defaultMessage="Add more parameters"
                        />
                    </Button>
                </div>
                <div className={`parameters ${parametersVisible ? "visible" : ""}`}>
                    <div className="create-job-offer-container-grid">
                        <FormItem
                            name="languagesRequired"
                            label={<FormattedMessage id="selectRequiredLanguages" />}
                        >
                            <Select
                                mode="multiple"
                                onChange={e => setSelectedLanguages(e)}
                                onClick={() => setLanguageOpen(!languageOpen)}
                                onBlur={() => setLanguageOpen(false)}
                                open={languageOpen}
                                placeholder={<FormattedMessage id="selectRequiredLanguages" />}
                                showArrow={true}
                            >
                                {languages.map(lang => (
                                    <Option value={lang} key={lang}>
                                        {props.intl.formatMessage(LanguageMessages[lang])}
                                    </Option>
                                ))}
                            </Select>
                        </FormItem>
                        <FormItem
                            name="professional"
                            label={
                                <FormattedMessage
                                    id="filter.proffesional"
                                    defaultMessage="Proffesional"
                                />
                            }
                        >
                            <Select
                                onChange={e => {
                                    setIsProfessional(e);
                                }}
                                placeholder={
                                    <FormattedMessage
                                        id="filter.proffesional"
                                        defaultMessage="Proffesional"
                                    />
                                }
                                showArrow={true}
                            >
                                <Option value={true}>
                                    <FormattedMessage id="yes" />
                                </Option>
                                <Option value={false}>
                                    <FormattedMessage id="no" />
                                </Option>
                            </Select>
                        </FormItem>
                        <FormItem name="date" label={<FormattedMessage id="startDate" />}>
                            <RangePicker
                                allowEmpty={[false, true]}
                                id="picker"
                                format="DD/MM/YYYY"
                                onChange={handleDateChange}
                                disabledDate={disabledDate}
                                allowClear={false}
                                placeholder={[
                                    props.intl.formatMessage(messages.startDate),
                                    props.intl.formatMessage(messages.endDate),
                                ]}
                            />
                        </FormItem>
                        <FormItem
                            name="applicationDeadline"
                            label={
                                <FormattedMessage
                                    id="applicationDeadline"
                                    defaultMessage="Application deadline"
                                />
                            }
                            dependencies={["date"]}
                            hasFeedback
                            rules={[
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (startDate != undefined) {
                                            let startDateDate = getFieldValue("date")[0];
                                            startDateDate = startDateDate._d.getTime();
                                            let applicationDeadlineDate = value._d.getTime();
                                            if (
                                                !value ||
                                                applicationDeadlineDate <= startDateDate
                                            ) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(
                                                "Application date must be before start date"
                                            );
                                        } else {
                                            return Promise.resolve();
                                        }
                                    },
                                }),
                            ]}
                        >
                            <DatePicker
                                id="picker"
                                format="DD/MM/YYYY"
                                className="application-deadline-datepicker"
                                allowClear={true}
                                disabledDate={applicationDisabledDate}
                                disabled={startDate === undefined ? true : false}
                                placeholder={props.intl.formatMessage(
                                    messages.applicationDeadlineDate
                                )}
                                onChange={handleApplicationDeadlineChange}
                                value={applicationDeadline}
                            />
                        </FormItem>

                        <FormItem
                            name="driverseLicence"
                            label={<FormattedMessage id="filter.driversLicence" />}
                        >
                            <Select
                                onChange={e => setDriversLicenceNeeded(e)}
                                placeholder={<FormattedMessage id="filter.driversLicence" />}
                                showArrow={true}
                            >
                                <Option value={true} key="true">
                                    <FormattedMessage id="yes" defaultMessage="Yes" />
                                </Option>
                                <Option value={false} key="false">
                                    <FormattedMessage id="no" defaultMessage="No" />
                                </Option>
                            </Select>
                        </FormItem>
                        <FormItem
                            name="tags"
                            label={<FormattedMessage id="enterTags" defaultMessage="Enter tags" />}
                        >
                            <TagsSelector addTags={true} />
                        </FormItem>
                    </div>
                    <FormItem
                        name="description"
                        label={<FormattedMessage id="description" defaultMessage="Description" />}
                    >
                        <ReactQuill value={description} onChange={onDescriptionChange} />
                    </FormItem>
                </div>
                <div className="create-job-offer-submit-container">
                    <Button htmlType="submit" className="button">
                        <FormattedMessage id="submit" />
                    </Button>
                </div>
            </Form>
        </div>
    );
};

export default injectIntl(CreateJobOffer);
