import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getJobOffers, getPeronalizedJobOffers } from "../../services/jobOfferService";
import { withLoader } from "../../components/WithLoader/withLoader";
import JobOfferCard from "../JobOffer/JobOfferCard";
import { getNews } from "../../services/newsService";
import { Radio, notification, Button, Modal } from "antd";
import { FormattedMessage, injectIntl } from "react-intl";
import placeholder from "../Image/placeholder.jpg";
import moment from "moment";
import "../Home/Home.less";

const Home = ({ setLoading, intl }) => {
    const user = useSelector(state => state.user);
    const [jobOffers, setJobOffers] = useState([]);
    const [jobOfferOption, setJobOfferOption] = useState("newest");
    const [news, setNews] = useState([]);
    const [errorMsg, setErrorMsg] = useState("");
    const [isModalVisible, setIsModalVisible] = useState(false);


    var searchParams = new URLSearchParams();

    useEffect(() => {
        if (sessionStorage.getItem("fromRegister") === "true") {
            setTimeout(function () {
                openNotificationWithIcon("info", "topLeft");
                sessionStorage.setItem("fromRegister", "false");
            }, 2000);
        }
        searchParams.append("TimePeriod", "active");
        getJobOffers(user, searchParams, 1).then(j => {
            setJobOffers(j.items);
            setErrorMsg("");
            setLoading(false);
        });
    }, [user, setLoading]);

    useEffect(() => {
        if (sessionStorage.getItem("fromLogin") === "true") {
            if (user.role === "Employer" && user.subscriptionStatus !== "active") {
                showModal();
                sessionStorage.setItem("fromLogin", "false");
            }
        }
    }, [user.role, user.subscriptionStatus, intl]);

    const openNotificationWithIcon = (type, placement) => {
        notification[type]({
            message: "Info",
            description: (
                <FormattedMessage
                    id="editYourProfile"
                    defaultMessage="Edit your profile and find the right job!"
                />
            ),
            duration: 10,
            placement,
        });
    };

    let getNewest = () => {
        setLoading(true);
        searchParams.append("TimePeriod", "active");
        getJobOffers(user, searchParams, 1).then(j => {
            setJobOffers(j.items);
            setErrorMsg("");
            setLoading(false);
        });
    };

    let getForYou = () => {
        setLoading(true);
        getPeronalizedJobOffers(user)
            .then(j => {
                setJobOffers(j.items);
                setErrorMsg("");
                setLoading(false);
            })
            .catch(error => {
                setJobOffers([]);
                setErrorMsg(
                    <FormattedMessage
                        id="notFilledOut"
                        defaultMessage="You have not filled out your job preferences"
                    />
                );
                setLoading(false);
            });
    };

    const showModal = () => {
        setTimeout(() => {
            setIsModalVisible(true);
        }, 1000)
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    useEffect(() => {
        getNews(user, user.language).then(res => setNews(res));
    }, [user, user.language]);

    return (
        <React.Fragment>
            <div className="homepage-container">
                <div className="homepage-jobs-container">
                    <div className="homepage-jobs-header">
                        <h2>
                            {jobOfferOption === "newest" ? (
                                <FormattedMessage
                                    id="newestJobOffers"
                                    defaultMessage="Check out newest job offers!"
                                />
                            ) : (
                                <FormattedMessage
                                    id="jobOffersMatching"
                                    defaultMessage="Job offers matching your preferences"
                                />
                            )}
                        </h2>
                        {user.role === "Employee" && (
                            <Radio.Group
                                className="language-select-btn-group"
                                value={jobOfferOption}
                                onChange={e => setJobOfferOption(e.target.value)}
                                buttonStyle="solid"
                            >
                                <Radio.Button
                                    className="language-select-btn"
                                    value="newest"
                                    onClick={getNewest}
                                >
                                    <FormattedMessage id="newest" defaultMessage="Newest" />
                                </Radio.Button>
                                <Radio.Button
                                    className="language-select-btn"
                                    value="forYou"
                                    onClick={getForYou}
                                >
                                    <FormattedMessage id="forYou" defaultMessage="For you" />
                                </Radio.Button>
                            </Radio.Group>
                        )}
                    </div>
                    <div className="homepage-jobs-offers-container">
                        {errorMsg && <p className="error-text">{errorMsg}</p>}
                        {jobOffers.map(job => (
                            <JobOfferCard key={job.id} {...job} />
                        ))}
                    </div>
                </div>

                <div className="homepage-news-container">
                    <div className="homepage-news-header">
                        <h2>
                            <FormattedMessage
                                id="latestNews"
                                defaultMessage="Latest news from the industry"
                            />
                        </h2>
                    </div>
                    {news.length > 0 ? (
                        news.map(item => (
                            <a
                                href={item.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                key={item.guid}
                                className="news-article-item"
                            >
                                <img
                                    src={item.imageSource !== null ? item.imageSource : placeholder}
                                    alt=""
                                />
                                <div className="news-article-content">
                                    <p className="news-article-title">{item.title}</p>
                                    <p className="news-article-meta">
                                        {item.source}, {moment(item.date).format("DD/MM/YYYY")}
                                    </p>
                                </div>
                            </a>
                        ))
                    ) : (
                        <h3>
                            <FormattedMessage
                                id="noNewsAvailable"
                                defaultMessage="No news available for selected language!"
                            />
                        </h3>
                    )}
                </div>
                <Button style={{ display: "none" }}>Info</Button>
                <Modal
                    visible={isModalVisible}
                    footer={[
                        <Button key="ok" onClick={handleOk}>
                            Ok
                        </Button>
                    ]}>
                    <p><FormattedMessage
                        id="completeProfile"
                        defaultMessage="Finish completing your profile so you can use all functionalities." />
                    </p>
                </Modal>
            </div>
        </React.Fragment>
    );
};

export default injectIntl(withLoader(Home));
