import { ADD_LANGUAGES } from '../actions/languageActions';

var savedState = sessionStorage.getItem('languages')
    ? JSON.parse(sessionStorage.getItem('languages'))
    : null;

const initialState = [];

function lanugagesReducer(state = savedState || initialState, action) {
    switch (action.type) {
        case ADD_LANGUAGES:
            return [...action.languages];
        default:
            return state;
    }
}

export default lanugagesReducer;
