import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Input, Button, DatePicker, Select, Form, Checkbox } from "antd";
import { FormattedMessage, injectIntl, defineMessages } from "react-intl";
import { postExperience } from "../../services/experienceService";
import { LeftOutlined } from "@ant-design/icons";
import moment from "moment";
import { getCountries } from "../../services/locationService";
import { FormatCountries } from "../../Helpers/CountryHelper";
import { FormatPositions } from "../../Helpers/PositionHelper";
import "./Experiences.less";

let messages = defineMessages({
    setAmount: {
        id: "setAmount",
        defaultMessage: "Set amount",
    },
    startDate: {
        id: "startDate",
        defaultMessage: "Start date",
    },
    endDate: {
        id: "endDate",
        defaultMessage: "End date",
    },
    comment: {
        id: "comment",
        defaultMessage: "Komentar",
    },
    positionDescription: {
        id: "positionDescription",
        defaultMessage: "Descripe position",
    },
    enterCompany: {
        id: "enterCompany",
        defaultMessage: "Enter company",
    },
});

function NewExperience(props) {
    const user = useSelector(state => state.user);
    const [comment, setComment] = useState();
    const [positionDescription, setPositionDescription] = useState();
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const positions = useSelector(state => state.positions);
    const [selectedPosition, setSelectedPosition] = useState([]);
    const [positionName, setPositionName] = useState([]);
    const [companyName, setCompanyName] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState([]);
    const [companyCity, setCompanyCity] = useState([]);
    const [countries, setCountries] = useState([]);
    const [currentlyEmployed, setCurrentlyEmployed] = useState(false);

    const { Option } = Select;
    const FormItem = Form.Item;
    const { RangePicker } = DatePicker;

    useEffect(() => {
        getCountries(user).then(res => setCountries(res));
    }, [user]);

    let onCompanyNameChange = event => {
        setCompanyName(event.target.value);
    };

    let handlePositionChange = value => {
        setSelectedPosition(value);
        var position = positions.find(p => p.id === value);
        setPositionName(position.name);
    };

    let handleLocationChange = value => {
        setSelectedLocation(value);
        var country = countries.find(country => country.id === value);
        setCompanyCity(country.name);
    };

    let onCommentChange = event => {
        setComment(event.target.value);
    };

    let onCurrentlyChange = e => {
        setCurrentlyEmployed(e.target.checked);
    };

    let onSingleDateChange = value => {
        setStartDate(value.format("YYYY-MM-DD"));
    };

    let onPositionDescriptionChange = event => {
        setPositionDescription(event.target.value);
    };

    let handleDateChange = values => {
        setStartDate(values[0].format("YYYY-MM-DD"));
        setEndDate(values[1].format("YYYY-MM-DD"));
    };

    let disabledDate = current => {
        return current && current > moment().endOf("day");
    };

    let onSubmit = () => {
        const sDate = new Date(startDate);
        const eDate = currentlyEmployed ? new Date(moment().endOf("day")) : new Date(endDate);
        const state = {
            employeeId: user.info,
            positionName: positionName,
            positionId: selectedPosition,
            positionDescription: positionName === "Other" ? positionDescription : "",
            companyName: companyName,
            currentlyEmployed: currentlyEmployed,
            start: sDate.toISOString(),
            end: eDate.toISOString(),
            comment: comment,
            companyCity: companyCity,
            locationId: selectedLocation,
        };
        postExperience(user, JSON.stringify(state)).then(props.handleClicks);
    };

    let onFinish = values => {
        onSubmit(values);
    };

    return user.role === "Employee" ? (
        <div className="new-experience-container">
            <Form onFinish={onFinish} className="new-experience-form" layout="vertical">
                <FormItem
                    className="form-item"
                    name="position"
                    label={<FormattedMessage id="position" />}
                    rules={[
                        {
                            required: true,
                            message: "Position field is mandatory",
                        },
                    ]}
                >
                    <Select
                        placeholder={
                            <FormattedMessage
                                id="selectPosition"
                                defaultMessage="Select position"
                            />
                        }
                        showArrow={true}
                        onChange={handlePositionChange}
                        showSearch
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {positions &&
                            FormatPositions([...positions], props.intl).map(position => (
                                <Option value={position.id} key={position.id}>
                                    {position.name}
                                </Option>
                            ))}
                    </Select>
                </FormItem>

                {positionName === "Other" ? (
                    <FormItem
                        name="positionDescription"
                        label={<FormattedMessage id="positionDescription" />}
                    >
                        <Input.TextArea
                            className="comment-container"
                            placeholder={props.intl.formatMessage(messages.comment)}
                            rows={3}
                            onChange={onPositionDescriptionChange}
                        />
                    </FormItem>
                ) : (
                    ""
                )}

                <FormItem
                    name="company"
                    label={<FormattedMessage id="companyName" />}
                    rules={[
                        {
                            required: true,
                            message: "Company field is mandatory",
                        },
                    ]}
                >
                    <Input
                        placeholder={props.intl.formatMessage(messages.enterCompany)}
                        onChange={onCompanyNameChange}
                    />
                </FormItem>

                <FormItem
                    name="location"
                    label={<FormattedMessage id="location" />}
                    rules={[
                        {
                            required: true,
                            message: <FormattedMessage id="locationMandatory" />,
                        },
                    ]}
                >
                    <Select
                        placeholder={
                            <FormattedMessage
                                id="selectLocation"
                                defaultMessage="Select location"
                            />
                        }
                        showArrow={true}
                        onChange={handleLocationChange}
                        showSearch
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                        }
                    >
                        {countries &&
                            FormatCountries([...countries], props.intl).map(country => (
                                <Option value={country.id} key={country.id}>
                                    {country.name}
                                </Option>
                            ))}
                    </Select>
                </FormItem>
                <FormItem
                    className="form-group-container-horizontal"
                    name="currentlyEmployed"
                    label={<FormattedMessage id="currentlyEmployed" />}
                >
                    <Checkbox className="checkbox" onChange={onCurrentlyChange} />
                </FormItem>
                <FormItem
                    name="date"
                    label={<FormattedMessage id="date" defaultMessage="Date" />}
                    rules={[
                        {
                            required: true,
                            message: "Date field is mandatory",
                        },
                    ]}
                >
                    {currentlyEmployed ? (
                        <DatePicker
                            format="DD/MM/YYYY"
                            onChange={onSingleDateChange}
                            disabledDate={disabledDate}
                            placeholder={props.intl.formatMessage(messages.startDate)}
                        />
                    ) : (
                        <RangePicker
                            format="DD/MM/YYYY"
                            onChange={handleDateChange}
                            disabledDate={disabledDate}
                            placeholder={[
                                props.intl.formatMessage(messages.startDate),
                                props.intl.formatMessage(messages.endDate),
                            ]}
                        />
                    )}
                </FormItem>

                <FormItem name="comment" label={<FormattedMessage id="comment" />}>
                    <Input.TextArea
                        className="comment-container"
                        placeholder={props.intl.formatMessage(messages.comment)}
                        rows={3}
                        onChange={onCommentChange}
                    />
                </FormItem>

                <FormItem>
                    <div className="submit-container">
                        <Button className="plain-button" onClick={props.handleClicks}>
                            <LeftOutlined />
                            <FormattedMessage id="back" />
                        </Button>
                        <Button htmlType="submit" className="button">
                            <FormattedMessage
                                id="newExperience.submitButton"
                                defaultMessage="Submit"
                            />
                        </Button>
                    </div>
                </FormItem>
            </Form>
        </div>
    ) : (
        <FormattedMessage id="missingInformation" />
    );
}

export default injectIntl(NewExperience);
