import { getAuthorizationProperties } from "./authorizationService";

export function getLocation(user, locationId) {
    return fetch(`/api/Locations/${locationId}`, {
        method: "GET",
        ...getAuthorizationProperties(user.token, {
            "Content-Type": "application/json",
        }),
    }).then(response => response.json());
}

export function getLocations(user) {
    return fetch("/api/Locations", {
        method: "GET",
        ...getAuthorizationProperties(user.token, {
            "Content-Type": "application/json",
        }),
    }).then(response => response.json());
}

export function getLocationsByParentAndLevel(user, level, parent) {
    return fetch(`/api/Locations/filter?parent=${parent}&level=${level}`, {
        method: "GET",
        ...getAuthorizationProperties(user.token, {
            "Content-Type": "application/json",
        }),
    }).then(response => response.json());
}

export async function getCountries(user) {
    const response = await fetch("/api/Locations/Countries", {
        method: "GET",
        ...getAuthorizationProperties(user.token, {
            "Content-Type": "application/json",
        }),
    });
    return await response.json();
}

export function searchCountries(user, value) {
    return fetch(`/api/Locations/search?value=${value}`, {
        method: "GET",
        ...getAuthorizationProperties(user.token, {
            "Content-Type": "application/json",
        }),
    }).then(response => response.json());
}

export function getCitiesForRegion(user, guid) {
    return fetch(`/api/Locations/cities/${guid}`, {
        method: "GET",
        ...getAuthorizationProperties(user.token, {
            "Contet-Type": "application/json",
        }),
    }).then(response => response.json());
}

export async function getJobLocations(user) {
    const response = await fetch("/api/Locations/Job", {
        method: "GET",
        ...getAuthorizationProperties(user.token, {
            "Content-Type": "application/json",
        }),
    });
    return await response.json();
}
