import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";

const PrivateRoute = ({ component: Component, ...rest }) => {
    const { loggedIn, token } = rest;
    return (
        <Route
            {...rest}
            render={(props) =>
                token && loggedIn ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: "/Login",
                            state: { from: props.location },
                        }}
                    />
                )
            }
        />
    );
};

function mapStateToProps(state) {
    const { token } = state.user;
    const { loggedIn } = state.login;
    return { token, loggedIn };
}

export default connect(mapStateToProps)(PrivateRoute);
