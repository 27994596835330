import React, { useEffect, useState } from "react";
import { getInterestedEmployees } from "../../services/employeeService";
import { useSelector } from "react-redux";
import InterestedEmployeeCard from "./InterestedEmployeeCard";
import { Collapse, Button, Select, Pagination, Form } from "antd";
import CogoMasonry from "../../components/CogoMasonry/CogoMasonry";
import { withLoader } from "../../components/WithLoader/withLoader";
import { FormattedMessage, injectIntl } from "react-intl";
import LanguageMessages from "../Language/LanguageMessages";
import { getJobLocations } from "../../services/locationService";
import { FormatPositions } from "../../Helpers/PositionHelper";
import { FormatLocations } from "../../Helpers/LocationHelper";
import { displayMessageToast } from "../../Helpers/ToastHelper";
import "./EmployeeSearch.less";

function EmployeeSearch(props) {
    const user = useSelector(state => state.user);
    const [interestedEmployees, setInterestedEmployees] = useState([]);
    const positions = useSelector(state => state.positions);
    const [form] = Form.useForm();
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState();
    const [selectedPositions, setSelectedPositions] = useState([]);
    const languages = useSelector(state => state.languages);
    const [jobLocations, setJobLocations] = useState([]);
    const [collapseOpen, setCollapseOpen] = useState([]);

    const [locationOpen, setLocationOpen] = useState(false);
    const [positionOpen, setPositionOpen] = useState(false);
    const [languageOpen, setLanguageOpen] = useState(false);

    let searchParams = new URLSearchParams();

    const { Panel } = Collapse;
    const { Option } = Select;

    let fetchInterestedEmployees = (page, values) => {
        handleFilter(values);
        props.setLoading(true);
        getInterestedEmployees(user, page, searchParams).then(res => {
            if (res.total === 0) {
                displayMessageToast("noResults", false, props.intl);
            }
            setInterestedEmployees(res.items);
            setTotal(res.total);
            props.setLoading(false);
        });
    };

    useEffect(() => {
        fetchInterestedEmployees(page);
    }, [user, page]);

    useEffect(() => {
        let fetchJobLocations = () => {
            getJobLocations(user).then(setJobLocations);
        };
        fetchJobLocations();
    }, [user]);

    let onPositionChange = value => {
        setSelectedPositions(value);
    };

    let handleFilter = values => {
        searchParams = new URLSearchParams();

        if (values && values.locations) {
            values.locations.forEach(sl => {
                searchParams.append("Locations", sl);
            });
        }

        if (values && values.languages) {
            values.languages.forEach(sl => {
                searchParams.append("Languages", sl);
            });
        }

        if (selectedPositions) {
            selectedPositions.forEach(sp => {
                searchParams.append("Positions", sp);
            });
        }
    };

    const handleCollapse = () => {
        if (user.subscriptionStatus === "no_subscription") {
            displayMessageToast("ChooseSubscription", false, props.intl);
        } else {
            collapseOpen.length === 0 ? setCollapseOpen(["0"]) : setCollapseOpen([]);
        }
    };

    let filter = values => {
        fetchInterestedEmployees(page, values);
    };

    let onPageChange = page => {
        setPage(page);
        fetchInterestedEmployees(page);
    };

    let onReset = () => {
        form.resetFields();
        setSelectedPositions([]);
    };

    let onFinish = values => {
        filter(values);
        setCollapseOpen([]);
    };

    return (
        <div className="employee-search-container">
            <Collapse
                className="employee-search-collapse"
                activeKey={collapseOpen}
                onChange={handleCollapse}
            >
                <Panel header="Filter">
                    <Form
                        form={form}
                        onFinish={onFinish}
                        initialValues={{
                            locations: [],
                        }}
                    >
                        <Form.Item name="positions">
                            <div className="filter-section-container">
                                <p className="bolded-text-filter">
                                    <FormattedMessage id="positions" />:
                                </p>
                                <Select
                                    mode="multiple"
                                    placeholder={<FormattedMessage id="selectPositions" />}
                                    showArrow={true}
                                    onChange={onPositionChange}
                                    onClick={() => setPositionOpen(!positionOpen)}
                                    onBlur={() => setPositionOpen(false)}
                                    open={positionOpen}
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {positions &&
                                        FormatPositions([...positions], props.intl).map(
                                            position => (
                                                <Option value={position.id} key={position.id}>
                                                    {position.name}
                                                </Option>
                                            )
                                        )}
                                </Select>
                            </div>
                        </Form.Item>

                        <div className="filter-section-container">
                            <p className="bolded-text-filter">
                                <FormattedMessage id="locations" />:
                            </p>
                            <Form.Item name="locations">
                                <Select
                                    className="select-container"
                                    mode="multiple"
                                    placeholder={<FormattedMessage id="selectLocation" />}
                                    onClick={() => setLocationOpen(!locationOpen)}
                                    onBlur={() => setLocationOpen(false)}
                                    open={locationOpen}
                                    showArrow={true}
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) === 0
                                    }
                                >
                                    {jobLocations &&
                                        FormatLocations([...jobLocations], props.intl).map(
                                            location => (
                                                <Option value={location.id} key={location.id}>
                                                    {location.name}
                                                </Option>
                                            )
                                        )}
                                </Select>
                            </Form.Item>
                        </div>

                        <div className="filter-section-container">
                            <p className="bolded-text-filter">
                                <FormattedMessage id="languages" />
                            </p>
                            <Form.Item name="languages">
                                <Select
                                    mode="multiple"
                                    placeholder={<FormattedMessage id="selectLanguages" />}
                                    showArrow={true}
                                    onClick={() => setLanguageOpen(!languageOpen)}
                                    onBlur={() => setLanguageOpen(false)}
                                    open={languageOpen}
                                >
                                    {languages.map(lang => (
                                        <Option value={lang} key={lang}>
                                            {props.intl.formatMessage(LanguageMessages[lang])}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>

                        <div className="buttons-container">
                            <Button className="button" type="primary" htmlType="submit">
                                <FormattedMessage id="filter.btnFilter" />
                            </Button>
                            <Button className="button" htmlType="button" onClick={onReset}>
                                <FormattedMessage
                                    id="filter.btnClearFilter"
                                    defaultMessage="Clear Filter"
                                />
                            </Button>
                        </div>
                    </Form>
                </Panel>
            </Collapse>
            <div className="interested-employees-container">
                <CogoMasonry className="employeeSearch-grid">
                    {interestedEmployees.map(ie => (
                        <InterestedEmployeeCard interestedEmployee={ie} key={ie.id} />
                    ))}
                </CogoMasonry>
            </div>
            {total > 12 && (
                <div className="pagination-container">
                    <Pagination
                        current={page}
                        onChange={onPageChange}
                        total={total}
                        pageSize={12}
                    />
                </div>
            )}
        </div>
    );
}

export default withLoader(injectIntl(EmployeeSearch));
