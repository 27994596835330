import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Select } from "antd";
import FormButtons from "../FormButtons";
import { setLanguage } from "../../../actions/userActions";
import { FormattedMessage, injectIntl } from "react-intl";
import countries from "../countries.json";
import { LockOutlined } from "@ant-design/icons";

const FormItem = Form.Item;
const Option = Select.Option;

const buttons = [
    {
        text: <FormattedMessage id="back" />,
        type: "back",
    },
    {
        text: <FormattedMessage id="next" defaultMessage="Next" />,
        type: "submit",
    },
];

const validatePassword = (pass, rule, value, callback) => {
    if (value && value !== pass) {
        return Promise.reject(
            <FormattedMessage id="passDontMatch" defaultMessage="The passwords dont match!" />
        );
    } else {
        return Promise.resolve();
    }
};

const startValues = { language: "en", country: "Germany" };

function Employee(props) {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    let error = useSelector(state => state.login.error);

    let onLanguageChange = value => {
        dispatch(setLanguage(value));
    };

    return (
        <Form
            onFinish={props.onSubmit}
            layout="vertical"
            form={form}
            initialValues={{ ...startValues, ...props.user }}
        >
            <div className="register-employer-form">
                <FormItem
                    label="First name"
                    name="firstName"
                    rules={[{ required: true, message: "Please input first name!" }]}
                >
                    <Input />
                </FormItem>
                <FormItem
                    label="Last name"
                    name="lastName"
                    rules={[{ required: true, message: "Please input first name!" }]}
                >
                    <Input />
                </FormItem>
                <FormItem label="Language" name="language">
                    <Select onChange={onLanguageChange}>
                        <Option value="en" key="en">
                            English
                        </Option>
                        <Option value="de" key="de">
                            Deutsch
                        </Option>
                        <Option value="hr" key="hr">
                            Hrvatski
                        </Option>
                    </Select>
                </FormItem>
                <FormItem
                    label="Country"
                    name="country"
                    rules={[{ required: true, message: "Please select country!" }]}
                >
                    <Select>
                        {countries.map(c => (
                            <Option value={c.name} key={c.code}>
                                {c.name}
                            </Option>
                        ))}
                    </Select>
                </FormItem>
                <FormItem
                    label="City"
                    name="city"
                    rules={[{ required: true, message: "Please input city!" }]}
                >
                    <Input />
                </FormItem>
                <FormItem
                    name="username"
                    label="Username(contact email)"
                    rules={[
                        {
                            type: "email",
                            message: (
                                <FormattedMessage
                                    id="validEmail"
                                    defaultMessage="The input is not valid E-mail!"
                                />
                            ),
                        },
                        {
                            required: true,
                            message: (
                                <FormattedMessage
                                    id="inputEmail"
                                    defaultMessage="Please input your email!"
                                />
                            ),
                        },
                    ]}
                >
                    <Input />
                </FormItem>

                <FormItem
                    name="password"
                    label="Password"
                    rules={[
                        {
                            required: true,
                            message: (
                                <FormattedMessage
                                    id="inputPassword"
                                    defaultMessage="Please input your password!"
                                />
                            ),
                        },
                    ]}
                >
                    <Input.Password prefix={<LockOutlined />} type="password" />
                </FormItem>

                <FormItem
                    name="confirmPassword"
                    label="Confirm password"
                    rules={[
                        {
                            required: true,
                            message: <FormattedMessage id="plsConfirmPassword" />,
                        },
                        {
                            validator: validatePassword.bind(this, form.getFieldValue("password")),
                        },
                    ]}
                >
                    <Input.Password prefix={<LockOutlined />} type="password" />
                </FormItem>

                <p id="server-error" className="ant-form-explain">
                    {error ? error.toString() : null}
                </p>
                <div className="register-buttons">
                    <React.Fragment>
                        <FormButtons buttons={buttons} />
                    </React.Fragment>
                </div>
            </div>
        </Form>
    );
}

export default injectIntl(Employee);
