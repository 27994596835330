import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import { withRouter } from 'react-router';
import EmployeeComponents from './EmployeeComponents.js';
import { injectIntl } from 'react-intl';
import CompanyComponents from './CompanyComponents.js';
import { RightOutlined } from '@ant-design/icons';
import UnreadMsgCounter from '../../components/UnreadMsgCounter/UnreadMsgCounter';
import UnreadJobAppCounter from '../../components/UnreadJobAppCounter/UnreadJobAppCounter';
import Icons from './Icons/Icons';
import './CustomSider.less';

class CustomSider extends Component {
    constructor(props) {
        super(props);

        let components =
            props.user.role === 'Admin' || props.user.role === 'Employee'
                ? EmployeeComponents
                : CompanyComponents;

        this.state = {
            components,
            // activeComp: null,
        };

    }

    render() {
        return (
            <Menu
                selectedKeys={[
                    // this.state.activeComp ? this.state.activeComp : 'Home',
                    sessionStorage.getItem('activeComp') ? sessionStorage.getItem('activeComp') : 'Home'
                ]}
                mode="inline"
                className="cogo-sider-menu"
            >
                {this.state.components.map(component => (
                    <Menu.Item
                        key={component.icon}
                        onClick={() =>
                            sessionStorage.setItem('activeComp', component.icon)
                        }
                    >
                        <Link to={component.link} className="menu-link">
                            {component.link === '/Conversations' ? (
                                <UnreadMsgCounter className="icon">
                                    {Icons[component.icon].render()}
                                </UnreadMsgCounter>
                            ) : component.link === '/JobApplications' && this.props.user.role === 'Employer' ? (
                                    <UnreadJobAppCounter className="icon">
                                        {Icons[component.icon].render()}
                                    </UnreadJobAppCounter>
                            ) : (
                                <div className="icon">
                                    {Icons[component.icon].render()}
                                </div>
                            )}
                            <span className="text">
                                {this.props.intl.formatMessage(component.name)}
                            </span>
                            <RightOutlined className="chevron" />
                        </Link>
                    </Menu.Item>
                ))}
            </Menu>
        );
    }
}

function mapStateToProps(state) {
    const user = state.user;
    return { user };
}

export default withRouter(injectIntl(connect(mapStateToProps)(CustomSider)));
