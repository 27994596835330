import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Row, Col } from "antd";
import CompanyAvatar from "../CompanyAvatar/CompanyAvatar";
import { FormattedMessage, injectIntl } from "react-intl";
import { Link } from "react-router-dom";
import LocationMessages from "../Location/LocationMessages";
import JobPreferencesMessages from "../JobPreference/JobPrefenecesMessages";
import "./JobOfferCard.less";

const JobOfferCard = props => {
    const allLocations = useSelector(state => state.locations);
    const allPositions = useSelector(state => state.positions);
    const [location, setLocation] = useState(null);
    const [positions, setPositions] = useState([]);

    useEffect(() => {
        if (props.positions && allPositions.length > 0) {
            let selectedPositions = props.positions.map(p => {
                return allPositions.find(ap => ap.id === p);
            });
            setPositions(selectedPositions);
        }
    }, [props.positions, allPositions]);

    useEffect(() => {
        setLocation(allLocations.find(l => l.id === props.locationId));
    }, [props.locationId, allLocations]);

    return (
        <div className="jobOffer-card">
            <Link to={`/JobOffer/${props.id}`}>
                <div className="company-avatar-jobOffer">
                    <CompanyAvatar companyId={props.companyId} />
                </div>
                <div className="job-offer-card-info-container">
                    <div className="job-offer-card-info">
                        <div className="title">
                            <h3>{props.title}</h3>
                        </div>
                        <Row wrap={false}>
                            <Col span={7} className="bolded-text">
                                <FormattedMessage id="companyName" defaultMessage="Company" />:
                            </Col>
                            <Col>
                                <p>{props.company.name}</p>
                            </Col>
                        </Row>
                        {positions && positions.length > 0 && (
                            <Row wrap={false}>
                                <Col span={7} className="bolded-text">
                                    <FormattedMessage id="position" />:
                                </Col>
                                <Col>
                                    {positions
                                        .map(element =>
                                            props.intl.formatMessage(
                                                JobPreferencesMessages[
                                                    element.name.replace(/\W/g, "")
                                                ]
                                            )
                                        )
                                        .join(", ")}
                                </Col>
                            </Row>
                        )}
                        <Row wrap={false}>
                            <Col span={7} className="bolded-text">
                                <FormattedMessage id="filter.jobType" />:
                            </Col>
                            <Col>
                                {props.jobType === 0 ? (
                                    <p>
                                        {" "}
                                        <FormattedMessage id="jobType.temporary" />
                                    </p>
                                ) : props.jobType === 1 ? (
                                    <p>
                                        <FormattedMessage id="jobType.seasonal" />
                                    </p>
                                ) : props.jobType === 2 ? (
                                    <p>
                                        <FormattedMessage id="jobType.contract" />
                                    </p>
                                ) : (
                                    ""
                                )}
                            </Col>
                        </Row>
                        {location && (
                            <Row wrap={false}>
                                <Col span={7} className="bolded-text">
                                    <FormattedMessage id="location" defaultMessage="Location" />:
                                </Col>
                                <Col>
                                    <p>
                                        {props.intl.formatMessage(
                                            LocationMessages[location.name.replace(/\W/g, "")]
                                        )}
                                    </p>
                                </Col>
                            </Row>
                        )}
                    </div>
                </div>
            </Link>
        </div>
    );
};

export default injectIntl(JobOfferCard);
