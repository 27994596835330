import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import JobOfferCard from "../JobOffer/JobOfferCard";
import TagsSelector from "../../components/TagsSelector/TagsSelector";
import CompanySelector from "../../components/CompanySelector/CompanySelector";
import { getJobOffers } from "../../services/jobOfferService";
import { withLoader } from "../../components/WithLoader/withLoader";
import CogoMasonry from "../../components/CogoMasonry/CogoMasonry";
import JobPrefenecesMessages from "../JobPreference/JobPrefenecesMessages";
import LanguageMessages from "../Language/LanguageMessages";
import { Collapse, Button, Select, Pagination, Form } from "antd";
import { getJobLocations } from "../../services/locationService";
import { addFilterData } from "../../actions/filterAction";
import LocationMessages from "../Location/LocationMessages";
import "./JobOfferList.less";

function JobOfferList(props) {
    const [jobOffers, setJobOffers] = useState([]);
    const user = useSelector(state => state.user);
    const positions = useSelector(state => state.positions);
    const filterData = useSelector(state => state.filter);
    const languages = useSelector(state => state.languages);
    const [reset, setReset] = useState(false);
    const dispatch = useDispatch();
    const [total, setTotal] = useState();
    const [form] = Form.useForm();
    const [positionOpen, setPositionOpen] = useState(false);
    const [locationOpen, setLocationOpen] = useState(false);
    const [languageOpen, setLanguageOpen] = useState(false);
    const [jobLocations, setJobLocations] = useState([]);
    const wrapper = useRef();
    const [collapseOpen, setCollapseOpen] = useState([]);

    var searchParams = new URLSearchParams();

    const { Panel } = Collapse;
    const { Option } = Select;

    useEffect(() => {
        let fetchJobLocations = () => {
            getJobLocations(user).then(setJobLocations);
        };
        fetchJobLocations();
    }, [user]);

    let fetchJobOffers = (page, timePeriod) => {
        handleFilter();
        if (page == null) page = filterData.length !== 0 ? filterData.page : 1;
        if (timePeriod !== undefined) searchParams.append("TimePeriod", timePeriod);
        if (props.company) searchParams.append("Companies", props.company);

        setJobOffers([]);
        props.setLoading(true);

        getJobOffers(user, searchParams, page).then(j => {
            setJobOffers(j.items);
            setTotal(j.total);
            props.setLoading(false);
        });
    };

    useEffect(() => {
        if (filterData.length === 0)
            dispatch(
                addFilterData({
                    filter: {
                        companies: [],
                        positions: [],
                        tags: [],
                        location: "",
                        driversLicenceNeeded: undefined,
                        isProfessional: undefined,
                        jobType: undefined,
                        languagesRequired: [],
                    },
                    page: 1,
                })
            );
        fetchJobOffers();
    }, [reset, user]);

    let handleCompanyChange = value => {
        dispatch(
            addFilterData({
                filter: { ...filterData.filter, companies: value },
                page: filterData.page,
            })
        );
    };

    let handleLocationChange = value => {
        if (value === undefined) {
            dispatch(
                addFilterData({
                    filter: {
                        ...filterData.filter,
                        location: "00000000-0000-0000-0000-000000000000",
                    },
                    page: filterData.page,
                })
            );
        } else {
            dispatch(
                addFilterData({
                    filter: { ...filterData.filter, location: value },
                    page: filterData.page,
                })
            );
        }
    };

    let handlePositionsChange = value => {
        dispatch(
            addFilterData({
                filter: { ...filterData.filter, positions: value },
                page: filterData.page,
            })
        );
    };

    let handleTagsChange = value => {
        dispatch(
            addFilterData({
                filter: { ...filterData.filter, tags: value },
                page: filterData.page,
            })
        );
    };

    let handleIsProfessionalChange = value => {
        dispatch(
            addFilterData({
                filter: {
                    ...filterData.filter,
                    isProfessional: value,
                },
                page: filterData.page,
            })
        );
    };

    let handleJobTypeChange = value => {
        dispatch(
            addFilterData({
                filter: { ...filterData.filter, jobType: value },
                page: filterData.page,
            })
        );
    };

    let handleLanguagesRequiredChange = value => {
        dispatch(
            addFilterData({
                filter: { ...filterData.filter, languagesRequired: value },
                page: filterData.page,
            })
        );
    };

    let handleDriversLicenceChange = value => {
        dispatch(
            addFilterData({
                filter: { ...filterData.filter, driversLicenceNeeded: value },
                page: filterData.page,
            })
        );
    };

    let onPageChange = page => {
        dispatch(
            addFilterData({
                filter: { ...filterData.filter },
                page: page,
            })
        );
        fetchJobOffers(page);
    };

    let handleFilter = () => {
        if (filterData.filter.companies) {
            filterData.filter.companies.forEach(selectedCom => {
                searchParams.append("Companies", selectedCom.id);
            });
        }

        if (filterData.filter.positions) {
            filterData.filter.positions.forEach(selectedPosition => {
                searchParams.append("Positions", selectedPosition);
            });
        }
        if (filterData.filter.tags) {
            filterData.filter.tags.forEach(selectedTag => {
                searchParams.append("Tags", selectedTag.id);
            });
        }
        if (filterData.filter.location) {
            filterData.filter.location.forEach(selectedLoc => {
                searchParams.append("Locations", selectedLoc);
            });
        }
        if (filterData.filter.driversLicenceNeeded !== undefined) {
            searchParams.append("DriversLicenceNeeded", filterData.filter.driversLicenceNeeded);
        }
        if (filterData.filter.languagesRequired) {
            filterData.filter.languagesRequired.forEach(selectedLanguage => {
                searchParams.append("LanguagesRequired", selectedLanguage);
            });
        }
        if (filterData.filter.isProfessional !== undefined) {
            searchParams.append("IsProfessional", filterData.filter.isProfessional);
        }
        if (filterData.filter.jobType !== undefined) {
            searchParams.append("JobType", filterData.filter.jobType);
        }
    };

    let filter = () => {
        fetchJobOffers();
    };

    let onFinish = values => {
        filter();
        setCollapseOpen([]);
    };

    let onReset = () => {
        form.resetFields();
        dispatch(
            addFilterData({
                filter: {
                    companies: [],
                    positions: [],
                    tags: [],
                    location: "",
                    driversLicenceNeeded: undefined,
                    isProfessional: undefined,
                    jobType: undefined,
                    languagesRequired: [],
                },
                page: 1,
            })
        );
        setReset(!reset);
    };

    const handleCollapse = () => {
        collapseOpen.length === 0 ? setCollapseOpen(["0"]) : setCollapseOpen([]);
    };

    const handleClick = e => {
        if (wrapper.current && wrapper.current.contains(e.target)) return;
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);

    return (
        <div className="job-list-container">
            <div ref={wrapper}>
                <Collapse className="collapse" activeKey={collapseOpen} onChange={handleCollapse}>
                    <Panel header="Filter">
                        <Form form={form} onFinish={onFinish} className="job-offer-list-form">
                            {props.company ? (
                                ""
                            ) : (
                                <Form.Item name="Companies">
                                    <div className="filter-section-container">
                                        <div className="bolded-text-filter">
                                            <FormattedMessage
                                                id="filter.companies"
                                                defaultMessage="Companies"
                                            />
                                            :
                                        </div>
                                        <CompanySelector onChange={handleCompanyChange} />
                                    </div>
                                </Form.Item>
                            )}

                            <Form.Item name="Positions">
                                <div className="filter-section-container">
                                    <div className="bolded-text-filter">
                                        <FormattedMessage
                                            id="filter.position"
                                            defaultMessage="Position"
                                        />
                                        :
                                    </div>
                                    <Select
                                        mode="multiple"
                                        placeholder={
                                            <FormattedMessage
                                                id="selectPositions"
                                                defaultMessage="Select positions"
                                            />
                                        }
                                        onClick={() => setPositionOpen(!positionOpen)}
                                        onBlur={() => setPositionOpen(false)}
                                        open={positionOpen}
                                        onChange={handlePositionsChange}
                                        showArrow={true}
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {positions.map(position => (
                                            <Option value={position.id} key={position.id}>
                                                {props.intl.formatMessage(
                                                    JobPrefenecesMessages[
                                                        position.name.replace(/\W/g, "")
                                                    ]
                                                )}
                                            </Option>
                                        ))}
                                    </Select>
                                </div>
                            </Form.Item>
                            <Form.Item name="JobType">
                                <div className="filter-section-container">
                                    <div className="bolded-text-filter">
                                        <FormattedMessage
                                            id="filter.jobType"
                                            defaultMessage="Job type"
                                        />
                                        :
                                    </div>
                                    <Select
                                        onChange={handleJobTypeChange}
                                        placeholder={
                                            <FormattedMessage
                                                id="selectJobType"
                                                defaultMessage="Select job type"
                                            />
                                        }
                                        showArrow={true}
                                    >
                                        <Option value="0" key="0">
                                            <FormattedMessage id="jobType.temporary" />
                                        </Option>
                                        <Option value="1" key="1">
                                            <FormattedMessage id="jobType.seasonal" />
                                        </Option>
                                        <Option value="2" key="2">
                                            <FormattedMessage id="jobType.contract" />
                                        </Option>
                                    </Select>
                                </div>
                            </Form.Item>
                            <Form.Item name="Location">
                                <div className="filter-section-container">
                                    <div className="bolded-text-filter">
                                        <FormattedMessage
                                            id="filter.location"
                                            defaultMessage="Location"
                                        />
                                        :
                                    </div>
                                    <Select
                                        className="select-container"
                                        mode="multiple"
                                        placeholder={<FormattedMessage id="selectLocation" />}
                                        onChange={handleLocationChange}
                                        onClick={() => setLocationOpen(!locationOpen)}
                                        onBlur={() => setLocationOpen(false)}
                                        open={locationOpen}
                                        showArrow={true}
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) === 0
                                        }
                                    >
                                        {jobLocations.map(jobLocation => (
                                            <Option value={jobLocation.id} key={jobLocation.id}>
                                                {props.intl.formatMessage(
                                                    LocationMessages[
                                                        jobLocation.name
                                                            .replace(/\W/g, "")
                                                            .replace("-", "")
                                                    ]
                                                )}
                                            </Option>
                                        ))}
                                    </Select>
                                </div>
                            </Form.Item>
                            <Form.Item name="LanguagesRequired">
                                <div className="filter-section-container">
                                    <div className="bolded-text-filter">
                                        <FormattedMessage
                                            id="filter.languagesRequired"
                                            defaultMessage="Languages required"
                                        />
                                        :
                                    </div>
                                    <Select
                                        mode="multiple"
                                        onClick={() => setLanguageOpen(!languageOpen)}
                                        onBlur={() => setLanguageOpen(false)}
                                        open={languageOpen}
                                        onChange={handleLanguagesRequiredChange}
                                        placeholder={
                                            <FormattedMessage
                                                id="selectRequiredLanguages"
                                                defaultMessage="Select required languages"
                                            />
                                        }
                                        showArrow={true}
                                    >
                                        {languages.map(lang => (
                                            <Option value={lang} key={lang}>
                                                {props.intl.formatMessage(LanguageMessages[lang])}
                                            </Option>
                                        ))}
                                    </Select>
                                </div>
                            </Form.Item>
                            <Form.Item name="Professional">
                                <div className="filter-section-container">
                                    <div className="bolded-text-filter">
                                        <FormattedMessage
                                            id="filter.professional"
                                            defaultMessage="Professional"
                                        />
                                        :
                                    </div>
                                    <Select
                                        onChange={handleIsProfessionalChange}
                                        placeholder={<FormattedMessage id="filter.professional" />}
                                        showArrow={true}
                                    >
                                        <Option value={true} key="true">
                                            <FormattedMessage id="yes" />
                                        </Option>
                                        <Option value={false} key="false">
                                            <FormattedMessage id="no" />
                                        </Option>
                                    </Select>
                                </div>
                            </Form.Item>
                            <Form.Item name="DriversLicence">
                                <div className="filter-section-container">
                                    <div className="bolded-text-filter">
                                        <FormattedMessage
                                            id="filter.driversLicence"
                                            defaultMessage="Drivers Licence"
                                        />
                                        :
                                    </div>
                                    <Select
                                        onChange={handleDriversLicenceChange}
                                        placeholder={
                                            <FormattedMessage id="filter.driversLicence" />
                                        }
                                        showArrow={true}
                                    >
                                        <Option value={true} key="true">
                                            <FormattedMessage id="yes" />
                                        </Option>
                                        <Option value={false} key="false">
                                            <FormattedMessage id="no" />
                                        </Option>
                                    </Select>
                                </div>
                            </Form.Item>
                            <Form.Item name="Tags">
                                <div className="filter-section-container">
                                    <div className="bolded-text-filter">
                                        <FormattedMessage id="filter.tags" defaultMessage="Tags" />:
                                    </div>
                                    <TagsSelector onChange={handleTagsChange} addTags={false} />
                                </div>
                            </Form.Item>

                            <div className="buttons-container">
                                <Button
                                    style={{ marginTop: "8px" }}
                                    className="button"
                                    type="primary"
                                    htmlType="submit"
                                >
                                    <FormattedMessage
                                        id="filter.btnFilter"
                                        defaultMessage="Filter"
                                    />
                                </Button>
                                <Button
                                    style={{ marginTop: "8px" }}
                                    className="button"
                                    htmlType="button"
                                    onClick={onReset}
                                >
                                    <FormattedMessage
                                        id="filter.btnClearFilter"
                                        defaultMessage="Clear Filter"
                                    />
                                </Button>
                            </div>
                        </Form>
                    </Panel>
                </Collapse>
            </div>
            <div className="select-dropdown">
                <Select
                    defaultValue="all"
                    className="ant-select-selector"
                    onSelect={value => fetchJobOffers(filterData.page, value)}
                >
                    <Option value="all">All</Option>
                    <Option value="active">Active</Option>
                    <Option value="past">Past</Option>
                </Select>
            </div>
            <div className="jobOffer-container">
                <CogoMasonry className="jobOffer-grid">
                    {jobOffers.map(job => (
                        <JobOfferCard key={job.id} {...job} />
                    ))}
                </CogoMasonry>
            </div>
            <div className="pagination-container">
                <Pagination
                    current={filterData.length !== 0 ? filterData.page : 1}
                    onChange={onPageChange}
                    total={total}
                    pageSize={12}
                />
            </div>
        </div>
    );
}

export default withLoader(injectIntl(JobOfferList));
