import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getJobOfferJobApplications } from '../../services/jobApplicationService';
import ApplicantCard from './ApplicantCard';
import { Pagination } from 'antd';
import CogoMasonry from '../../components/CogoMasonry/CogoMasonry';
import './JobOfferApplicants.css';

function JobOfferApplicants(props) {
    const [applicants, setApplicants] = useState([]);
    const user = useSelector((state) => state.user);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState();

    useEffect(() => {
        let fetchApplicants = () => {
            getJobOfferJobApplications(user, props.id, page).then((a) => {
                setApplicants(a.items);
                setTotal(a.total);
            });
        };

        fetchApplicants();
    }, [user, props.id, page]);

    let onPageChange = (page) => {
        setPage(page);
    };

    return (
        <React.Fragment>
            <div className='job-offer-applicants-container'>
                <CogoMasonry className='job-offer-applicants-grid'>
                    {applicants.map((applicant) => (
                        <ApplicantCard
                            key={applicant.created}
                            employeeGuid={applicant.employeeId}
                            created={applicant.created}
                            jobOffer={applicant.jobOffer}
                            user={user}
                        />
                    ))}
                </CogoMasonry>
            </div>
            <div className='pagination-container'>
                <Pagination
                    current={page}
                    total={total}
                    hideOnSinglePage={false}
                    onChange={onPageChange}
                />
            </div>
        </React.Fragment>
    );
}

export default JobOfferApplicants;
