import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Tabs } from 'antd';
import JobOfferDetails from './JobOfferDetails';
import JobOfferEdit from './JobOfferEdit';
import JobOfferApplicants from './JobOfferApplicants';
import { getJobOffer } from '../../services/jobOfferService';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';

function JobOffer(props) {
    const { TabPane } = Tabs;
    const user = useSelector(state => state.user);
    const [jobOffer, setJobOffer] = useState({});
    let jobOfferId = props.location.pathname.split('/')[2];

    useEffect(() => {
        let fetchJobOffer = () => {
            getJobOffer(user, jobOfferId).then(jo => {
                setJobOffer(jo);
            });
        };
        fetchJobOffer();
    }, [user, jobOfferId]);

    return (
        <Tabs>
            <TabPane
                tab={<FormattedMessage id="details" defaultMessage="Details" />}
                key="details"
            >
                <JobOfferDetails {...jobOffer} />
            </TabPane>
            {user.info === jobOffer.companyId && (
                <TabPane tab={<FormattedMessage id="edit" />} key="edit">
                    <JobOfferEdit {...jobOffer} />
                </TabPane>
            )}
            {user.info === jobOffer.companyId && (
                <TabPane
                    tab={
                        <FormattedMessage
                            id="applied"
                            defaultMessage="Applied for job"
                        />
                    }
                    key="applied"
                >
                    <JobOfferApplicants {...jobOffer} />
                </TabPane>
            )}
        </Tabs>
    );
}

export default JobOffer;
