import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import {
    getEmployee,
    getEmployeePreferences,
} from "../../services/employeeService";
import EmployeeEdit from "./EmployeeEdit";
import EmployeeDetails from "./EmployeeDetails";
import "./Employee.css";

function Employee(props) {
    const [employee, setEmployee] = useState([]);
    const [preferences, setPreferences] = useState({});
    const user = useSelector((state) => state.user);
    const [component, setComponent] = useState("details");

    let fetchEmployee = useCallback(() => {
        getEmployee(user, props.employeeId).then((e) => {
            setEmployee(e);
        });
        getEmployeePreferences(user, props.employeeId).then(setPreferences);
    }, [user, props.employeeId]);

    useEffect(() => {
        fetchEmployee();
    }, [fetchEmployee]);

    let handleEditClick = () => setComponent("edit");

    let handleBackAndSubmitClick = () => setComponent("details");

    return (
        <div className="employee-container">
            {component === "details" ? (
                <EmployeeDetails
                    employee={employee}
                    handleClicks={handleEditClick}
                    user={user}
                    employeeId={props.employeeId}
                    preferences={preferences}
                    refreshEmployee={setEmployee}
                />
            ) : (
                ""
            )}
            {component === "edit" && user.role === "Employee" ? (
                <EmployeeEdit
                    employee={employee}
                    handleClicks={handleBackAndSubmitClick}
                    employeeId={props.employeeId}
                    refresh={fetchEmployee}
                    preferences={preferences}
                />
            ) : (
                ""
            )}
        </div>
    );
}

export default Employee;
