import { fork } from "redux-saga/effects";
import { loginFlow, logoutFlow, registerFlow, onLoginFlow } from "./loginSaga";
import { initialDataFlow } from "./initialDataSaga";
import { conversationsSaga } from "./conversationsSaga";
import { refreshUserFlow } from "./userSaga";
import { subscribeFlow, unsubscribeFlow, modifySubscriptionFlow, modifyPaymentMethodFlow } from "./companySaga";

// The root saga is what we actually send to Redux's middleware. In here we fork
// each saga so that they are all "active" and listening.
// Sagas are fired once at the start of an app and can be thought of as processes running
// in the background, watching actions dispatched to the store.
export default function* root() {
    yield fork(onLoginFlow);
    yield fork(loginFlow);
    yield fork(logoutFlow);
    yield fork(registerFlow);
    yield fork(initialDataFlow);
    yield fork(conversationsSaga);
    yield fork(refreshUserFlow);
    yield fork(subscribeFlow);
    yield fork(unsubscribeFlow);
    yield fork(modifySubscriptionFlow);
    yield fork(modifyPaymentMethodFlow);
}
