import React, { useState, useEffect } from "react";
import { Badge } from "antd";
import { useSelector } from "react-redux";
import "./UnreadMsgCounter.less";

function UnreadMsgCounter(props) {
    const [counter, setCounter] = useState(0);
    const conversations = useSelector((state) => state.conversations);

    useEffect(() => {
        let newCount = 0;
        Object.values(conversations).forEach((messages) => {
            if (messages)
                newCount += messages.filter((x) => !x.deleted && !x.edited)
                    .length;
        });
        setCounter(newCount);
    }, [conversations]);

    return (
        <Badge className={props.className} count={counter}>
            {props.children}
        </Badge>
    );
}

export default UnreadMsgCounter;
