import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import EducationCard from './EducationCard';
import { getEducations } from '../../services/educationService';
import { withLoader } from '../../components/WithLoader/withLoader';
import CogoMasonry from '../../components/CogoMasonry/CogoMasonry';
import './Educations.less';

function Educations({setLoading, employeeId, handleEdit,handleEducationDetails}) {
    const [educations, setEducations] = useState([]);
    const [error, setError] = useState("");
    const user = useSelector(state => state.user);

    useEffect(() => {
        setLoading(true);
        let fetchEducations = () => {
            getEducations(user, employeeId)
            .then(e => {
                setEducations(e);
                setLoading(false);
            })
            .catch(e => setError(e.message))
        };
        fetchEducations();
    }, [user, setLoading, employeeId]);

    return (
        <div className="educations-container">
            {error ? <p>{error}</p> : <CogoMasonry>
                {educations.map(education => (
                    <EducationCard
                        education={education}
                        key={education.id}
                        handleEdit={handleEdit}
                        handleEducationDetails={handleEducationDetails}
                        employeeId={employeeId}
                    />
                ))}
            </CogoMasonry>}
        </div>
    );
}

export default withLoader(Educations);
