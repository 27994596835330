import { getAuthorizationProperties } from './authorizationService';

export function getEmployee(user, employeeGuid) {
    return fetch(`/api/Employees/${employeeGuid}`, {
        method: 'GET',
        ...getAuthorizationProperties(user.token, {
            'Content-Type': 'application/json',
        }),
    }).then((response) => response.json());
}

export function updateEmployeeInfo(user, obj) {
    return fetch(`/api/Employees/${user.info}`, {
        method: 'PUT',
        ...getAuthorizationProperties(user.token, {
            'Content-Type': 'application/json',
        }),
        body: JSON.stringify(obj),
    });
}

export function getInterestedEmployees(user, page, searchParams) {
    var skip = (page - 1) * 12;
    var take = 12;

    searchParams.append('skip', skip);
    searchParams.append('take', take);

    return fetch(`/api/Employees?${searchParams}`, {
        method: 'GET',
        ...getAuthorizationProperties(user.token, {
            'Content-Type': 'application/json',
        }),
    }).then((response) => response.json());
}

export function getEmployeePreferences(user, employeeGuid) {
    return fetch(`/api/Employees/preferences/${employeeGuid}`, {
        method: 'GET',
        ...getAuthorizationProperties(user.token, {
            'Content-Type': 'application/json',
        }),
    }).then((response) => response.json());
}

export function putEmployeePreferences(user, state) {
    return fetch('/api/Employees/preferences', {
        method: 'PUT',
        ...getAuthorizationProperties(user.token, {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }),
        body: state,
    });
}

export function unlockEmployee(user, employeeGuid) {
    return fetch(`/api/Employees/unlock/${employeeGuid}`, {
        method: 'PATCH',
        ...getAuthorizationProperties(user.token, {
            'Content-Type': 'application/json',
        }),
    }).then((response) => {
        if (!response.ok) {
            return response.text().then((text) => {
                throw Error(text);
            });
        } else {
            return response.text();
        }
    });
}