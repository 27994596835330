import messages from "./SiderMessages";

export default [
    {
        name: messages.home,
        link: "/Home",
        icon: "Home",
    },
    {
        name: messages.profile,
        link: "/Employee",
        icon: "UserProfile",
    },
    {
        name: messages.opportunities,
        link: "/Opportunities",
        icon: "Opportunities",
    },
    {
        name: messages.myApplications,
        link: "/JobApplications",
        icon: "JobApplications",
    },
    {
        name: messages.conversations,
        link: "/Conversations",
        icon: "Conversations",
    },
    {
        name: messages.usefulLinks,
        link: '/UsefulLinks',
        icon: 'UsefulLinks',
    }
];
