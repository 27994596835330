import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { loginRequest } from "../../actions/authentificationActions";
import LoginForm from "./LoginForm";
import RedirectIfLoggedIn from "../../components/RedirectIfLoggedIn";
import LoginHeader from "../../components/LoginHeader/LoginHeader";
import "./Login.less";

class Login extends Component {
    constructor(){
        super()
        sessionStorage.setItem('activeComp', 'Home');
    }
    
    render() {
        return (
            <div className="container-center">
                <RedirectIfLoggedIn />
                <div id="login-form-container">
                    <LoginHeader />
                    <LoginForm
                        onSubmit={this.props.actions.loginRequest}
                        serverError={this.props.error}
                    />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { error } = state.login;
    return { error };
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators({ loginRequest }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
