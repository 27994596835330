import { defineMessages } from 'react-intl';

export default defineMessages({
    Nurse: { id: 'position.nurse', defaultMessage: 'Nurse' },
    Respiratorytherapist: {
        id: 'position.Respiratorytherapist',
        defaultMessage: 'Respiratory therapist',
    },
    Specialisthealthandnursingstaff: {
        id: 'position.Specialisthealthandnursingstaff',
        defaultMessage: 'Specialist health and nursing staff',
    },
    Healingeducationnurse: {
        id: 'position.Healingeducationnurse',
        defaultMessage: 'Healing education nurse',
    },
    Registerednurse: {
        id: 'position.Registerednurse',
        defaultMessage: 'Registered nurse',
    },
    Medicalspecialist: {
        id: 'position.Medicalspecialist',
        defaultMessage: 'Medical specialist',
    },
    Occupationaltherapist: {
        id: 'position.Occupationaltherapist',
        defaultMessage: 'Occupationaltherapist',
    },
    Malenurse: { id: 'position.Malenurse', defaultMessage: 'Male nurse' },
    Healthcareworker: {
        id: 'position.Healthcareworker',
        defaultMessage: 'Health care worker',
    },
    Socialcareemployee: {
        id: 'position.Socialcareemployee',
        defaultMessage: 'Social-care employee',
    },
    Interninoccupationaltherapy: {
        id: 'position.Interninoccupationaltherapy',
        defaultMessage: 'Intern in occupational therapy',
    },
    Seniornursewithdiploma: {
        id: 'position.Seniornursewithdiploma',
        defaultMessage: 'Senior nurse with diploma',
    },
    Qualitymanager: {
        id: 'position.Qualitymanager',
        defaultMessage: 'Quality manager',
    },
    Seniornurse: { id: 'position.Seniornurse', defaultMessage: 'Senior nurse' },
    Dentaltechnician: {
        id: 'position.Dentaltechnician',
        defaultMessage: 'Dental technician',
    },
    Speechtherapist: {
        id: 'position.Speechtherapist',
        defaultMessage: 'Speech therapist',
    },
    Midwife: { id: 'position.Midwife', defaultMessage: 'Midwife' },
    Physicaltherapist: {
        id: 'position.Physicaltherapist',
        defaultMessage: 'Physical therapist',
    },
    Obstetrician: {
        id: 'position.Obstetrician',
        defaultMessage: 'Obstetrician',
    },
    Other: {
        id: 'position.Other',
        defaultMessage: 'Other',
    },
});
