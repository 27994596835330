import { getAuthorizationProperties } from './authorizationService';

export function getPositions(user, guids) {
    if (!guids) {
        return fetch('/api/Positions', {
            method: 'GET',
            ...getAuthorizationProperties(user.token, {
                'Content-Type': 'application/json'
            })
        }).then(response => response.json());
    } else {
        var searchParams = new URLSearchParams();
        guids.forEach(guid => {
            searchParams.append('guid', guid);
        });
        searchParams.toString();
        return fetch(`/api/Positions?${searchParams}`, {
            method: 'GET',
            ...getAuthorizationProperties(user.token, {
                'Content-Type': 'application/json'
            })
        }).then(response => response.json());
    }
}
