import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { deleteJobOffer } from '../../services/jobOfferService';
import { postJobApplication } from '../../services/jobApplicationService';
import { Button, Tag, Modal, Row, Col, Popover } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { AddJobOfferId } from '../../actions/jobApplicationAction';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment';
import LanguageMessages from '../Language/LanguageMessages';
import CityMessages from '../../Helpers/CityMessages';
import LocationMessages from '../Location/LocationMessages';
import JobPreferencesMessages from '../JobPreference/JobPrefenecesMessages';
import './JobOfferDetails.less';
import { displayMessageToast } from '../../Helpers/ToastHelper';

const JobOfferDetails = props => {
    const [visibility, setVisibility] = useState(false);
    const employeeId = useSelector(state => state.user.info);
    const user = useSelector(state => state.user);
    const allLocations = useSelector(state => state.locations);
    const allPositions = useSelector(state => state.positions);
    const [positions, setPositions] = useState(null);
    const [location, setLocation] = useState(null);
    const [city, setCity] = useState(null);
    const [jobsApplied] = useState(useSelector(state => state.jobApplication));
    const [applicationDeadlineDate, setApplicationDeadlineDate] = useState(
        null
    );
    const [todayDate] = useState(new Date());
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();

    const content = (
        <FormattedMessage
            id="professionalInfo"
            defaultMessage="Professional nurse as defined in Paragraph 71 of the German Social Code (SGB) stating Germany’s Social Law, book #XI, Paragraph 3
        i.e., care specialists, healthcare specialists, qualified nurse, etc. - meaning you earned your profession / specialization / qualification / certification 
        in an apprenticeship or by having studied at a college or university (or equivalent thereof)."
        />
    );

    useEffect(() => {
        if (props.positions) {
            let selectedPositions = props.positions.map(p => {
                return allPositions.find(ap => ap.id === p);
            });
            setPositions(selectedPositions);
        }
    }, [props.positions, allPositions]);

    useEffect(() => {
        if (props.applicationDeadline) {
            setApplicationDeadlineDate(new Date(props.applicationDeadline));
        }
    }, [props.applicationDeadline]);

    useEffect(() => {
        if (props.locationId) {
            setLocation(allLocations.find(l => l.id === props.locationId));
        }
    }, [props.locationId, allLocations]);

    useEffect(() => {
        if (props.cityId) {
            setCity(allLocations.find(l => l.id === props.cityId));
        }
    }, [props.cityId, allLocations]);

    let setModalVisibility = isVisible => setVisibility(isVisible);

    let handleDeleteJobOffer = () => {
        deleteJobOffer(user, props.id).then(() => {
            history.push({
                pathname: '/CompanyJobOffers',
            });
        });
    };

    let handleBackButton = () => history.goBack();

    let handleApplyForJobApplication = () => {
        const state = {
            employeeId: employeeId,
            jobOffer: props,
            description: '',
        };

        postJobApplication(user, JSON.stringify(state))
            .then(() => setVisibility(false))
            .then(() => dispatch(AddJobOfferId(props.id)))
            .then(() => displayMessageToast("messageApplied", true, props.intl))
            .then(() => {
                history.push({
                    pathname: '/JobApplications',
                });
            });
    };

    return (
        <div className="jobOfferDetails-container">
            <h2 className="job-offer-info">{props.title}</h2>
            <div className="line" id="job-offer-details-line" />

            <div className="job-offer-info">
                <Row>
                    <Col span={7} className="bolded-text">
                        <FormattedMessage
                            id="company"
                            defaultMessage="Company"
                        />
                        :
                    </Col>
                    <Col>
                        <Link
                            to={`/Company/${
                                props.company ? props.company.id : ''
                            }`}
                        >
                            <p>{props.company ? props.company.name : ''}</p>
                        </Link>
                    </Col>
                </Row>

                {positions && (
                    <Row>
                        <Col span={7} className="bolded-text">
                            {positions.length === 1 ? (
                                <p>
                                    <FormattedMessage id="position" />:
                                </p>
                            ) : (
                                <p>
                                    <FormattedMessage id="positions" />:
                                </p>
                            )}
                        </Col>
                        <Col>
                            {positions
                                .map(element =>
                                    props.intl.formatMessage(
                                        JobPreferencesMessages[
                                            element.name.replace(/\W/g, '')
                                        ]
                                    )
                                )
                                .join(', ')}
                        </Col>
                    </Row>
                )}

                <Row>
                    <Col span={7} className="bolded-text">
                        <FormattedMessage id="filter.jobType" />:
                    </Col>
                    <Col>
                        {props.jobType === 0 ? (
                            <p>
                                {' '}
                                <FormattedMessage id="jobType.temporary" />
                            </p>
                        ) : props.jobType === 1 ? (
                            <p>
                                <FormattedMessage id="jobType.seasonal" />
                            </p>
                        ) : props.jobType === 2 ? (
                            <p>
                                <FormattedMessage id="jobType.contract" />
                            </p>
                        ) : (
                            ''
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col span={7} className="bolded-text">
                        <FormattedMessage id="location" />:
                    </Col>
                    <Col>
                        <p>
                            {location &&
                                props.intl.formatMessage(
                                    LocationMessages[
                                        location.name.replace(/\W/g, '')
                                    ]
                                )}
                        </p>
                    </Col>
            </Row>
            {props.cityId !== "00000000-0000-0000-0000-000000000000" && props.cityId !== null ?
                    <Row>
                        <Col span={7} className="bolded-text">
                        <FormattedMessage id="place"
                            defaultMessage="Place" />
                                    :
                        </Col>
                        <Col>
                            {city && props.intl.formatMessage(
                                CityMessages[
                                city.name.replace(/\W/g, '')
                                ]
                            )}        
                        </Col>
                    </Row>
                : props.countryside !== null && props.countryside !== "" ? 
                    <Row>
                        <Col span={7} className="bolded-text">
                            <FormattedMessage id="place"
                                />
                                    :
                        </Col>
                        <Col>
                            {props.countryside}
                        </Col>
                    </Row> : ''}
                <React.Fragment>
                    {props.languagesRequired &&
                    props.languagesRequired.length !== 0 ? (
                        <Row>
                            <Col span={7} className="bolded-text">
                                <FormattedMessage id="filter.languagesRequired" />
                                :
                            </Col>
                            <Col>
                                {props.languagesRequired &&
                                    props.languagesRequired
                                        .map(lang =>
                                            props.intl.formatMessage(
                                                LanguageMessages[lang]
                                            )
                                        )
                                        .join(', ')}
                            </Col>
                        </Row>
                    ) : (
                        ''
                    )}
                </React.Fragment>
                <React.Fragment>
                    {props.start !== null &&
                    props.start !== '1970-01-01T00:00:00' ? (
                        <Row>
                            <Col span={7} className="bolded-text">
                                <FormattedMessage id="startDate" />:
                            </Col>
                            <Col>
                                {props.start &&
                                    moment(props.start).format('DD/MM/YYYY')}
                            </Col>
                        </Row>
                    ) : (
                        ''
                    )}
                </React.Fragment>
                <React.Fragment>
                    {props.end !== null &&
                    props.end !== '1970-01-01T00:00:00' ? (
                        <Row>
                            <Col span={7} className="bolded-text">
                                <FormattedMessage id="endDate" />:
                            </Col>
                            <Col>
                                {props.end &&
                                    moment(props.end).format('DD/MM/YYYY')}
                            </Col>
                        </Row>
                    ) : (
                        ''
                    )}
                </React.Fragment>
                <React.Fragment>
                    {props.applicationDeadline === '1970-01-01T00:00:00' ? (
                        ''
                    ) : (
                        <Row>
                            <Col span={7} className="bolded-text">
                                <FormattedMessage id="applicationDeadline" />:
                            </Col>
                            <Col>
                                {props.applicationDeadline &&
                                    moment(props.applicationDeadline).format(
                                        'DD/MM/YYYY'
                                    )}
                            </Col>
                        </Row>
                    )}
                </React.Fragment>
                <React.Fragment>
                    <Row>
                        {props.driversLicenceNeeded === false ? (
                            <React.Fragment>
                                <Col></Col>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <Col span={7} className="bolded-text">
                                    <FormattedMessage id="filter.driversLicence" />
                                    :
                                </Col>
                                <Col>
                                    <FormattedMessage id="yes" />
                                </Col>
                            </React.Fragment>
                        )}
                    </Row>
                </React.Fragment>
                <Row>
                    <Col span={7} className="bolded-text">
                        <FormattedMessage id="filter.professional" />:
                        {props.isProfessional ? (
                            <Popover content={content}>
                                <InfoCircleOutlined id="info-icon" />
                            </Popover>
                        ) : (
                            ''
                        )}
                    </Col>
                    <Col>
                        {props.isProfessional ? (
                            <p>
                                <FormattedMessage id="yes" />
                            </p>
                        ) : (
                            <p>
                                <FormattedMessage id="no" />
                            </p>
                        )}
                    </Col>
                </Row>
                {/*<React.Fragment>
                    {props.attachment !==
                    '00000000-0000-0000-0000-000000000000' ? (
                        <React.Fragment>
                            <Col span={7} className="bolded-text">
                                <FormattedMessage id="attachment" />
                                Attachment:
                            </Col>
                            <Col>
                                <a href={`/api/File/${props.attachment}`}>
                                    {props.attachmentInfo
                                        ? props.attachmentInfo.name
                                        : props.attachment}
                                </a>
                            </Col>
                        </React.Fragment>
                    ) : (
                        <React.Fragment />
                    )}
                </React.Fragment> */}
                <React.Fragment>
                    {props.shortDescription !== '' ? (
                        <Row>
                            <Col span={7} className="bolded-text">
                                <FormattedMessage
                                    id="description"
                                    defaultMessage="Description"
                                />
                                :
                            </Col>
                            <Col span={10}>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: props.shortDescription,
                                    }}
                                />
                            </Col>
                        </Row>
                    ) : (
                        ''
                    )}
                </React.Fragment>
            </div>
            <p>
                {props.tags &&
                    props.tags.map(element => (
                        <Tag className="tag" key={element.id}>
                            {element.name}
                        </Tag>
                    ))}
            </p>
            <div className="line" />
            <div className="job-offer-details-button-container">
                {user.info === props.companyId ? (
                    <Button className="button" onClick={handleDeleteJobOffer}>
                        <FormattedMessage id="delete" defaultMessage="Delete" />
                    </Button>
                ) : (
                    ''
                )}

                {user.role === 'Employee' &&
                    !jobsApplied.some(guid => guid === props.id) && (
                        <React.Fragment>
                            <div className="job-offer-details-button">
                                <Button
                                    className="button"
                                    disabled={
                                        todayDate > applicationDeadlineDate &&
                                        props.applicationDeadline !==
                                            '1970-01-01T00:00:00'
                                    }
                                    onClick={() => setModalVisibility(true)}
                                    onMouseOver={() =>
                                        setTooltipVisible(
                                            todayDate >
                                                applicationDeadlineDate &&
                                                props.applicationDeadline !==
                                                    '1970-01-01T00:00:00'
                                        )
                                    }
                                    onMouseLeave={() =>
                                        setTooltipVisible(false)
                                    }
                                >
                                    <FormattedMessage
                                        id="apply"
                                        defaultMessage="Apply"
                                    />
                                </Button>
                                <div
                                    className={`job-offer-details-tooltip ${
                                        tooltipVisible ? 'visible' : ''
                                    }`}
                                >
                                    <FormattedMessage
                                        id="cantApply"
                                        defaultMessage="Application deadline has passed"
                                    />
                                </div>
                            </div>

                            <Modal
                                title={
                                    <FormattedMessage
                                        id="doYouWant"
                                        defaultMessage="Are you sure you want to send your application?"
                                    />
                                }
                                closable
                                onCancel={() => setModalVisibility(false)}
                                centered
                                visible={visibility}
                                footer={
                                    <div className="new-conversation-modal-footer">
                                        <Button
                                            className="cancel-button-modal"
                                            onClick={() =>
                                                setModalVisibility(false)
                                            }
                                        >
                                            <FormattedMessage id="conversations.cancel" />
                                        </Button>
                                        <Button
                                            className="button"
                                            onClick={
                                                handleApplyForJobApplication
                                            }
                                        >
                                            <FormattedMessage id="apply" />
                                        </Button>
                                    </div>
                                }
                                width="500px"
                            ></Modal>
                        </React.Fragment>
                    )}
                <Button className="button" onClick={handleBackButton}>
                    <FormattedMessage id="back" />
                </Button>
            </div>
        </div>
    );
};

export default injectIntl(JobOfferDetails);
