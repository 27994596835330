export const ADD_UNREAD_MSG = "ADD_UNREAD_MSG";
export const CLEAR_CONVERSATION_UNREAD_MSG = "CLEAR_CONVERSATION_UNREAD_MSG";
export const SET_CONVERSATIONS = "SET_CONVERSATIONS";
export const CHECK_UNREAD_MESSAGES = "CHECK_UNREAD_MESSAGES";
export const EDIT_MSG = "EDIT_MSG";
export const DELETE_MSG = "DELETE_MSG";

export function addUnreadMsg(msg) {
    return { type: ADD_UNREAD_MSG, msg };
}

export function clearConversationUnreadMsg(convoId) {
    return { type: CLEAR_CONVERSATION_UNREAD_MSG, convoId };
}

export function setConversations(conversations) {
    return { type: SET_CONVERSATIONS, conversations };
}

export function checkUnreadMessages() {
    return { type: CHECK_UNREAD_MESSAGES };
}

export function editMsg(msg) {
    return { type: EDIT_MSG, msg };
}

export function deleteMsg(msg) {
    return { type: DELETE_MSG, msg };
}
