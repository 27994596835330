import React from 'react';
import { useSelector } from 'react-redux';
import { Tabs } from 'antd';
import './CompanyJobOffers.css';
import CreateJobOffer from '../CreateJobOffer/CreateJobOffer';
import { FormattedMessage } from 'react-intl';
import JobOfferList from '../JobOfferList/JobOfferList';

function CompanyJobOffers() {
    const user = useSelector(state => state.user);

    const { TabPane } = Tabs;

    return (
        <div>
            <Tabs>
                <TabPane
                    tab={
                        <FormattedMessage
                            id="ourJobOffers"
                            defaultMessage="Our job offers"
                        />
                    }
                    key="our-job-offers"
                >
                    <JobOfferList company={user.info} />
                </TabPane>
                <TabPane
                    tab={
                        <FormattedMessage
                            id="createJobOffer"
                            defaultMessage="Create job offer"
                        />
                    }
                    key="create-job-offer"
                >
                    <CreateJobOffer />
                </TabPane>
            </Tabs>
        </div>
    );
}

export default CompanyJobOffers;
