import { Input, Button } from "antd";
import React, { Fragment } from "react";
import { useState } from "react";
import LoginHeader from "../../components/LoginHeader/LoginHeader";
import RedirectIfLoggedIn from "../../components/RedirectIfLoggedIn";
import { forgotPassword } from "../../services/autentificationService";
import "./ForgotPassword.less";

const ForgotPassword = () => {
    const [email, setEmail] = useState("");
    const [success, setSuccess] = useState(false);

    const handleSendForgotPassword = async () => {
        const res = await forgotPassword(email);
        if (res.ok) setSuccess(true);
    };

    return (
        <div className="container-center">
            <RedirectIfLoggedIn />
            <div id="login-form-container">
                <LoginHeader />
                <div id="forgot-password-form">
                    {!success ? (
                        <Fragment>
                            <h4>Forgot password?</h4>
                            <p>
                                Type in your email address and we'll send you an
                                email with instructions how to reset your
                                password.
                            </p>
                            <Input
                                type="Email"
                                placeholder="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="login-button"
                                size="large"
                                block
                                disabled={
                                    !email.includes("@") || !email.includes(".")
                                }
                                onClick={handleSendForgotPassword}
                            >
                                Send password reset email
                            </Button>
                        </Fragment>
                    ) : (
                        <h4>
                            We have sent you an email with instructions how to
                            reset your email.
                        </h4>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;
