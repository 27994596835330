import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import JobOfferList from '../JobOfferList/JobOfferList';
import { AddJobOfferId } from '../../actions/jobApplicationAction';
import { getEmployeeJobApplications } from '../../services/jobApplicationService';
import './Opportunities.css';

function Opportunities() {
    const jobsApplied = useSelector((state) => state.jobApplication);
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);

    useEffect(() => {
        let fetchApplications = () => {
            if (jobsApplied.length === 0) {
                getEmployeeJobApplications(user, user.info).then((ja) => {
                    ja.items.map((j) => dispatch(AddJobOfferId(j.jobOffer.id)));
                });
            }
        };

        fetchApplications();
    }, [jobsApplied, dispatch, user]);

    return (
        <div className='opportunities-container'>
            <JobOfferList />
        </div>
    );
}

export default Opportunities;
