//const apiUrl = process.env.REACT_APP_API;

import { getAuthorizationProperties } from "./authorizationService";

export function login(username, password) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ username, password }),
    };

    return fetch(`/users/authenticate`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // login successful if there's a jwt token in the response
            if (user.token) {
                // store user details and jwt token in session storage to keep user logged in between page refreshes
                sessionStorage.setItem("user", JSON.stringify(user));
                sessionStorage.setItem("loggedIn", JSON.stringify(true));
            }

            return user;
        });
}

export function logout() {
    // remove user from session storage to log user out
    sessionStorage.removeItem("user");
    sessionStorage.setItem("loggedIn", JSON.stringify(false));
}

export function registerUser(user) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(user),
    };

    return fetch(`/users/registerUser`, requestOptions).then(handleResponse);
}

export function registerCompany(company) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(company),
    };

    return fetch(`/users/registerCompany`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data || response.ok;
    });
}

export function searchUsers(user, value) {
    return fetch(`/users/search?value=${value}`, {
        method: "GET",
        ...getAuthorizationProperties(user.token, {
            "Content-Type": "application/json",
        }),
    }).then(response => response.json());
}

export function getConversationUsers(user, appUsers) {
    var searchParams = new URLSearchParams();
    appUsers.forEach(user => {
        searchParams.append("userGuids", user);
    });

    return fetch(`/users/conversationUsers?${searchParams}`, {
        method: "GET",
        ...getAuthorizationProperties(user.token, {
            "Content-Type": "application/json",
        }),
    }).then(response => response.json());
}

export function deleteAccount(user, userDto) {
    return fetch(`/users/${user.guid}`, {
        method: "DELETE",
        ...getAuthorizationProperties(user.token, {
            Accept: "application/json",
            "Content-Type": "application/json",
        }),
        body: JSON.stringify(userDto),
    });
}

export function updatePassword(user, userDto) {
    return fetch(`/users/${user.guid}`, {
        method: "PUT",
        ...getAuthorizationProperties(user.token, {
            Accept: "application/json",
            "Content-Type": "application/json",
        }),
        body: JSON.stringify(userDto),
    });
}

export function forgotPassword(username) {
    return fetch("/users/forgotPassword", {
        method: "POST",
        headers: { "Content-Type": "text/plain" },
        body: username,
    });
}

export function checkPasswordToken(token) {
    return fetch(`/users/resetPassword?token=${token}`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
    });
}

export function resetPassword(userDto) {
    return fetch("/users/resetPassword", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(userDto),
    });
}

export default {
    login,
    logout,
    registerUser,
    registerCompany,
    getConversationUsers,
    deleteAccount,
    updatePassword,
    forgotPassword,
    checkPasswordToken,
    resetPassword,
};

export function changeUserLanguage(user, language) {
    return fetch(`/users/${user.guid}/changeLanguage?language=${language}`, {
        method: "PUT",
        ...getAuthorizationProperties(user.token, {
            "Content-Type": "application/json",
        }),
    });
}
