import { getAuthorizationProperties } from './authorizationService';

export function getLanguages(user, guids) {
    if (!guids) {
        return fetch('/api/RequiredLanguages', {
            method: 'GET',
            ...getAuthorizationProperties(user.token, {
                'Content-Type': 'application/json'
            })
        }).then(response => response.json());
    } else {
        var searchParams = new URLSearchParams();
        guids.forEach(guid => {
            searchParams.append('guid', guid);
        });
        searchParams.toString();
        return fetch(`/api/RequiredLanguages?${searchParams}`, {
            method: 'GET',
            ...getAuthorizationProperties(user.token, {
                'Content-Type': 'application/json'
            })
        }).then(response => response.json());
    }
}

export function getAllLanguages(user) {
        return fetch('/api/RequiredLanguages/allLanguages', {
        method: 'GET',
        ...getAuthorizationProperties(user.token, {
            'Content-Type': 'application/json'
        })
        }).then(response => response.json()); 
}