import React, { useState, useEffect, useCallback } from "react";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { getCompany } from "../../services/companyService";
import { Tabs } from "antd";
import CompanyEdit from "./CompanyEdit";
import CompanyDetails from "./CompanyDetails";
import CompanyPayments from "./CompanyPayments";
const { TabPane } = Tabs;

function Company(props) {
    const [company, setCompany] = useState([]);
    const [component, setComponent] = useState("details");
    const [activeTab, setActiveTab] = useState("details");
    const [tabSwitchCount, setTabSwitchCount] = useState(0);
    const user = useSelector(state => state.user);
    let companyId = user.info;
    let redirect = false;

    if (props.location.pathname) {
        var parts = props.location.pathname.split("/");
        if (parts.length === 3 && parts[2].length === 36) {
            companyId = parts[2];
        } else if (user.role === "Employee") {
            redirect = true;
        }
    }

    let fetchCompany = useCallback(() => {
        getCompany(user, companyId).then(c => {
            setCompany(c);
        });
    }, [user, companyId]);

    useEffect(() => {
        fetchCompany();
    }, [fetchCompany]);

    let handleEditClick = () => {
        setComponent("edit");
    };

    let handleBackAndSubmitClick = () => {
        setComponent("details");
    };

    let letHandleTabChange = key => {
        setActiveTab(key);
        if (key === "companyPayments") setTabSwitchCount(tabSwitchCount + 1);
    };

    return (
        <div className="company-container">
            {redirect && <Redirect to={{ pathname: "/Home" }} />}
            {component === "details" ? (
                <Tabs activeKey={activeTab} onTabClick={letHandleTabChange}>
                    <TabPane
                        tab={<FormattedMessage id="details" defaultMessage="Details" />}
                        key="details"
                    >
                        <CompanyDetails
                            company={company}
                            setCompany={setCompany}
                            handleClicks={handleEditClick}
                            goPayments={setActiveTab.bind(null, "companyPayments")}
                            companyId={companyId}
                        />
                    </TabPane>
                    {companyId === user.info && user.role === "Employer" ? (
                        <TabPane
                            tab={
                                <FormattedMessage id="companyPayments" defaultMessage="Payments" />
                            }
                            key="companyPayments"
                        >
                            <CompanyPayments
                                refreshCount={tabSwitchCount}
                                companyId={companyId}
                                goBack={setActiveTab.bind(null, "details")}
                            />
                        </TabPane>
                    ) : (
                        ""
                    )}
                </Tabs>
            ) : (
                <CompanyEdit
                    company={company}
                    handleClicks={handleBackAndSubmitClick}
                    refresh={fetchCompany}
                />
            )}
        </div>
    );
}

export default Company;
