import { ADD_POSITIONS } from '../actions/positionActions';

var savedState = sessionStorage.getItem('positions') ? JSON.parse(sessionStorage.getItem('positions')) : null;

const initialState = [];

function postionsReducer(state = savedState || initialState, action) {
    switch (action.type) {
        case ADD_POSITIONS:
            return  [...action.positions]
        default:
            return state;
    }
}

export default postionsReducer;
