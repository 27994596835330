import React from "react";
import { CardElement } from "@stripe/react-stripe-js";
import { FormattedMessage } from "react-intl";
import "./CardSection.css";

const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            color: "#32325d",
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
                color: "#aab7c4",
            },
        },
        invalid: {
            color: "#fa755a",
            iconColor: "#fa755a",
        },
    },
};

function CardSection() {
    return (
        <div className="card-container">
            <label>
                <FormattedMessage id="creditCardInfo" defaultMessage="Credit card info" />:
            </label>
            <CardElement id="card-detalis" options={CARD_ELEMENT_OPTIONS} />
        </div>
    );
}

export default CardSection;
