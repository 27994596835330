import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Button, Row, Col } from "antd";
import { injectIntl, FormattedMessage } from "react-intl";
import { withLoader } from "../../components/WithLoader/withLoader";
import EmployeeAvatar from "../EmployeeAvatar/EmployeeAvatar";
import LanguageMessages from "../Language/LanguageMessages";
import JobPreferencesMessages from "../JobPreference/JobPrefenecesMessages";
import LocationMessages from "../Location/LocationMessages";
import { getEmployee, unlockEmployee } from "../../services/employeeService";
import { displayMessageToast } from "../../Helpers/ToastHelper";
import { useHistory } from "react-router-dom";
import { postConversations } from "../../services/conversationsService";
import lockImg from "./lock.png";
import "./EmployeeDetails.less";

const EmployeeDetails = props => {
    const user = useSelector(state => state.user);
    let history = useHistory();

    useEffect(() => {
        props.setLoading(false);
    });

    const formatLanguages = langs => {
        if (langs) {
            const formattedLangs = [];
            langs.forEach(lang => {
                const formattedLang = props.intl.formatMessage(LanguageMessages[lang]);
                formattedLangs.push(formattedLang);
            });
            return formattedLangs.sort().join(", ");
        }
        return "";
    };

    const handleUnlock = async () => {
        try {
            props.setLoading(true);
            let result = await unlockEmployee(user, props.employee.id);
            displayMessageToast(result);
            let employee = await getEmployee(user, props.employee.id);
            props.refreshEmployee(employee);
        } catch (error) {
            displayMessageToast(error.message, false);
        } finally {
            props.setLoading(false);
        }
    };

    const handleOpenConversation = () => {
        let state = {
            name: "",
            appUsers: [{ id: props.employeeId }],
        };

        postConversations(user, state).then(data => {
            history.push(`/Conversations/Messages/${data.id}`);
        });
    };

    return (
        console.log(props),
        (
            <div className="employee-data-container">
                <div className="employee-data">
                    <div className="employee-avatar-centered">
                        <h2>
                            {props.employee.firstName} {props.employee.lastName}
                        </h2>
                        {props.employee.locked ? (
                            <img src={lockImg} alt="lock" />
                        ) : (
                            <EmployeeAvatar id="employee-avatar" employeeId={props.employeeId} />
                        )}
                    </div>
                    <div className="employee-info-container">
                        <div className="employee-info">
                            <Row>
                                <Col span={7} className="bolded-text">
                                    <FormattedMessage
                                        id="employee.details.country"
                                        defaultMessage="Country"
                                    />
                                    :
                                </Col>
                                <Col span={14}>
                                    {props.employee.country &&
                                        props.intl.formatMessage(
                                            LocationMessages[
                                                props.employee.country
                                                    .replace(/\W/g, "")
                                                    .replace("-", "")
                                            ]
                                        )}
                                </Col>
                            </Row>
                            <Row>
                                <Col span={7} className="bolded-text">
                                    <FormattedMessage id="gender" />:
                                </Col>
                                <Col span={14}>
                                    {props.employee.gender === 0 ? (
                                        <FormattedMessage id="gender-male" />
                                    ) : props.employee.gender === 1 ? (
                                        <FormattedMessage id="gender-female" />
                                    ) : (
                                        <FormattedMessage id="gender-other" />
                                    )}
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
                <div className="line" />
                <div className="employee-preferences">
                    <Row>
                        <Col span={10} className="bolded-text">
                            <FormattedMessage
                                id="employee.details.languages"
                                defaultMessage="Languages:"
                            />
                        </Col>
                        <Col span={14}>{formatLanguages(props.employee.languages)}</Col>
                    </Row>
                    <Row>
                        <Col span={10} className="bolded-text">
                            {props.preferences.positions && (
                                <FormattedMessage
                                    id="employee.details.positionsInterestedIn"
                                    defaultMessage="Positions interested In:"
                                />
                            )}
                        </Col>
                        <Col span={14}>
                            {props.preferences.positions &&
                                props.preferences.positions
                                    .map(element =>
                                        props.intl.formatMessage(
                                            JobPreferencesMessages[element.name.replace(/\W/g, "")]
                                        )
                                    )
                                    .join(", ")}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={10} className="bolded-text">
                            {props.preferences.locations && (
                                <FormattedMessage
                                    id="employee.details.locationsInterestedIn"
                                    defaultMessage="Locations interested In:"
                                />
                            )}
                        </Col>
                        <Col span={14}>
                            {props.preferences.locations &&
                                props.preferences.locations
                                    .map(element =>
                                        props.intl.formatMessage(
                                            LocationMessages[element.name.replace(/\W/g, "")]
                                        )
                                    )
                                    .join(", ")}{" "}
                        </Col>
                    </Row>
                </div>
                {props.user.role === "Employee" && props.user.info === props.employeeId && (
                    <React.Fragment>
                        {(!props.employee.languages || props.employee.languages.length === 0) && (
                            <p className="error-text prefferences-warning">
                                <FormattedMessage
                                    id="employee.details.fillJobPreferences"
                                    defaultMessage="Fill in your job preferences to make it easier for companies to find you!"
                                />
                            </p>
                        )}

                        <div className="line" />
                        <div className="edit-profile-container">
                            <Button className="button" onClick={props.handleClicks}>
                                <FormattedMessage
                                    id="employee.details.editProfile"
                                    defaultMessage="Edit profile"
                                />
                            </Button>
                        </div>
                    </React.Fragment>
                )}
                <React.Fragment>
                    <div className="line" />
                    <div className="edit-profile-container">
                        {props.user.role === "Employer" && props.employee.locked ? (
                            <Button className="button" onClick={handleUnlock}>
                                <FormattedMessage
                                    id="employee.details.unlock"
                                    defaultMessage="Unlock employee"
                                />
                            </Button>
                        ) : (
                            <Button className="button" onClick={handleOpenConversation}>
                                <FormattedMessage id="conversations.new" />
                            </Button>
                        )}
                    </div>
                </React.Fragment>
            </div>
        )
    );
};

export default injectIntl(withLoader(EmployeeDetails));
