import React, { Component } from 'react';
import { Switch } from 'react-router-dom';
import PrivateRoute from '../../components/PrivateRoute';
import EmployeeProfile from '../EmployeeProfile/EmployeeProfile';
import Opportunities from '../Opportunities/Opportunities';
import CompanyJobOffers from '../CompanyJobOffers/CompanyJobOffers';
import JobApplications from '../JobApplications/JobApplications';
import Company from '../Company/Company';
import CreateJobOffer from '../CreateJobOffer/CreateJobOffer';
import JobOffer from '../JobOffer/JobOffer';
import Home from '../Home/Home';
import EmployeeSearch from '../EmployeeSearch/EmployeeSearch';
import Conversations from '../Conversations/Conversations';
import UsefulLinks from '../UsefulLinks/UsefulLinks';

class CustomComponent extends Component {
    render() {
        return (
            <Switch>
                <PrivateRoute exact={true} path="/Home" component={Home} />
                <PrivateRoute
                    exact={true}
                    path="/CreateJobOffer"
                    component={CreateJobOffer}
                />
                <PrivateRoute path="/JobOffer" component={JobOffer} />
                <PrivateRoute
                    exact={true}
                    path="/Opportunities"
                    component={Opportunities}
                />
                <PrivateRoute
                    exact={true}
                    path="/CompanyJobOffers"
                    component={CompanyJobOffers}
                />
                <PrivateRoute
                    exact={true}
                    path="/FindEmployee"
                    component={EmployeeSearch}
                />
                <PrivateRoute
                    exact={true}
                    path="/JobApplications"
                    component={JobApplications}
                />
                <PrivateRoute
                    exact={true}
                    path="/UsefulLinks"
                    component={UsefulLinks}
                />
                <PrivateRoute path="/Company" component={Company} />
                <PrivateRoute path="/Conversations" component={Conversations} />
                <PrivateRoute path="/Employee" component={EmployeeProfile} />
            </Switch>
        );
    }
}

export default CustomComponent;
