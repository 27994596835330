import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getConversations, postConversations } from "../../services/conversationsService";
import { FormattedMessage, defineMessages, injectIntl } from "react-intl";
import ConversationAvatar from "../../components/ConversationAvatar/ConversationAvatar";
import { Button, Modal, Input, Badge } from "antd";
import UsersSelector from "../../components/UsersSelector/UsersSelector";
import { useHistory } from "react-router-dom";
import { ReactComponent as NewConversation } from "./New_conversation_icon.svg";
import { ImageDropzone, getCroppedImg } from "../Dropzone/ImageDropzone";
import { putImage } from "../../services/imageService";
import { getConversationUsers } from "../../services/autentificationService";
import { RefreshImage } from "../../actions/imageAction";
import { withLoader } from "../../components/WithLoader/withLoader";
import avatar from "./default-group.svg";
import "./Conversations.less";

let messages = defineMessages({
    setConvName: {
        id: "conversations.setConvName",
        defaultMessage: "Set conversation name",
    },
});

const onlineStatusRefreshInterval = 15000;

function ConversationsList({ sidebar, selected, intl, setLoading }) {
    const [conversationsList, setConversationsList] = useState([]);
    const conversations = useSelector(state => state.conversations);
    const user = useSelector(state => state.user);
    const [visible, setVisible] = useState(false);
    const [conversationName, setConversationName] = useState("");
    const [users, setUsers] = useState([]);
    const [userOnlineStatus, setUserOnlineStatus] = useState({});
    let history = useHistory();
    const [imageDropped, setImageDropped] = useState(false);
    const [crop, setCrop] = useState(false);
    const [refreshThumb, setRefreshThumb] = useState(false);
    const [statusInterval, setStatusInterval] = useState(null);
    const dispatch = useDispatch();

    let countUnreadMessages = id => {
        if (conversations[id]) return conversations[id].filter(x => !x.deleted && !x.edited).length;
        else return 0;
    };

    let fetchConversations = useCallback(async () => {
        let data = await getConversations(user);
        data = data.sort((a, b) => countUnreadMessages(b.id) - countUnreadMessages(a.id));
        setConversationsList(data);
        setLoading(false);
    }, [user, setLoading]);

    useEffect(() => {
        fetchConversations();
    }, [fetchConversations]);

    useEffect(() => {
        if (conversationsList.length > 0) {
            const fetchUsersOnlineStatus = () => {
                let userGuids = conversationsList.flatMap(c => c.appUsers).map(u => u.id);

                userGuids = [...new Set(userGuids)];

                getConversationUsers(user, userGuids).then(data => {
                    let onlineStatus = {};
                    data.forEach(u => {
                        onlineStatus[u.id] = u.isOnline;
                    });
                    setUserOnlineStatus(onlineStatus);
                });
            };

            if (!statusInterval) fetchUsersOnlineStatus();

            clearInterval(statusInterval);
            let interval = setInterval(fetchUsersOnlineStatus, onlineStatusRefreshInterval);
            setStatusInterval(interval);

            return () => clearInterval(interval);
        }
    }, [conversationsList, user]);

    let showModal = () => setVisible(true);

    let handleOk = e => {
        setVisible(false);
        let state = {
            name: conversationName,
            appUsers: users.map(u => ({ id: u.id })),
        };

        postConversations(user, state).then(data => {
            if (imageDropped && users.length >= 2) {
                getCroppedImg(crop, user.info).then(imageData => {
                    putImage(user, imageData, data.id).then(() => {
                        dispatch(RefreshImage(data.id));
                    });
                });
            }
            history.push(`/Conversations/Messages/${data.id}`);
        });
    };

    let handleCancel = e => setVisible(false);

    let onNameChange = event => setConversationName(event.target.value);

    let onUsersChange = value => setUsers(value);

    let handleDrop = () => setImageDropped(true);

    let handleCrop = newCrop => setCrop(newCrop);

    let handleRefreshThumb = () => setRefreshThumb(refreshThumb);

    return (
        <div className="conversations-container">
            <div className="conversations-title-container">
                <h2>
                    <FormattedMessage id="conversations" defaultMessage="Conversations" />
                </h2>
                {/*    <Button className="conv-button" type="primary" onClick={showModal}>
                    <FormattedMessage
                        id="conversations.start"
                        defaultMessage="Start conversation"
                    />
                </Button>  */}

            </div>
            <div className={`conversations-list ${sidebar ? "sidebar-version" : ""}`}>
                {conversationsList.length !== 0 ? conversationsList.map(c => (
                    <Badge key={c.id} count={countUnreadMessages(c.id)}>
                        <ConversationAvatar
                            onlineStatus={userOnlineStatus}
                            selected={c.id === selected}
                            key={c.id}
                            {...c}
                        />
                    </Badge>)) : <p>There are no active conversations</p>
                }
            </div>

            <div>
                <Modal
                    title={
                        <div className="new-conversation-modal-container">
                            <NewConversation className="new-conversation-icon" />
                            <div className="modal-title-paragraph">
                                <FormattedMessage
                                    id="conversations.new"
                                    defaultMessage="New conversation"
                                />
                            </div>
                        </div>
                    }
                    footer={
                        <div className="new-conversation-modal-footer">
                            <Button className="cancel-button-modal" onClick={handleCancel}>
                                <FormattedMessage
                                    id="conversations.cancel"
                                    defaultMessage="Cancel"
                                />
                            </Button>
                            <Button className="button" onClick={handleOk}>
                                <FormattedMessage
                                    id="conversations.create"
                                    defaultMessage="Create"
                                />
                            </Button>
                        </div>
                    }
                    visible={visible}
                    centered
                    closable={false}
                    className="new-conversation-modal"
                >
                    <Input
                        className="modal-input"
                        placeholder={intl.formatMessage(messages.setConvName)}
                        onChange={onNameChange}
                    />
                    <UsersSelector onChange={onUsersChange} />

                    <div
                        className={`image-dropzone-conversations ${users.length >= 2 ? "dropzone-visible" : ""
                            }`}
                    >
                        <ImageDropzone
                            imagePlaceholder={avatar}
                            crop={crop}
                            onDrop={handleDrop.bind(this)}
                            setCrop={handleCrop.bind(this)}
                            refreshThumb={refreshThumb}
                            clearRefreshThumb={handleRefreshThumb.bind(this, false)}
                        />
                    </div>
                </Modal>
            </div>
        </div>
    );
}

export default withLoader(injectIntl(ConversationsList));
