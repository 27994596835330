import { defineMessages } from "react-intl";

export default defineMessages({
    Germany: { id: "location.Germany", defaultMessage: "Germany" },
    Croatia: { id: "location.Croatia", defaultMessage: "Croatia" },
    Bavaria: { id: "location.Bavaria", defaultMessage: "Bavaria" },
    BadenWurttemberg: {
        id: "location.BadenWürttemberg",
        defaultMessage: "Baden-Württemberg",
    },
    Berlin: { id: "location.Berlin", defaultMessage: "Berlin" },
    Brandenburg: { id: "location.Brandenburg", defaultMessage: "Brandenburg" },
    Bremen: { id: "location.Bremen", defaultMessage: "Bremen" },
    Hamburg: { id: "location.Hamburg", defaultMessage: "Hamburg" },
    Hesse: { id: "location.Hesse", defaultMessage: "Hesse" },
    MecklenburgVorpommern: {
        id: "location.MecklenburgVorpommern",
        defaultMessage: "Mecklenburg-Vorpommern",
    },
    LowerSaxony: { id: "location.LowerSaxony", defaultMessage: "Lower Saxony" },
    NorthRhineWestphalia: {
        id: "location.NorthRhineWestphalia",
        defaultMessage: "North Rhine-Westphalia",
    },
    RhinelandPalatinate: {
        id: "location.RhinelandPalatinate",
        defaultMessage: "Rhineland-Palatinate",
    },
    Saarland: { id: "location.Saarland", defaultMessage: "Saarland" },
    Saxony: { id: "location.Saxony", defaultMessage: "Saxony" },
    SaxonyAnhalt: {
        id: "location.SaxonyAnhalt",
        defaultMessage: "Saxony-Anhalt",
    },
    SchleswigHolstein: {
        id: "location.SchleswigHolstein",
        defaultMessage: "Schleswig-Holstein",
    },
    Thuringia: { id: "location.Thuringia.", defaultMessage: "Thuringia" },
    BjelovarBilogora: {
        id: "location.BjelovarBilogora",
        defaultMessage: "Bjelovar-Bilogora",
    },
    BrodPosavina: {
        id: "location.BrodPosavina",
        defaultMessage: "Brod-Posavina",
    },
    DubrovnikNeretva: {
        id: "location.DubrovnikNeretva",
        defaultMessage: "Dubrovnik-Neretva",
    },
    Istria: { id: "location.Istria", defaultMessage: "Istria" },
    Karlovac: { id: "location.Karlovac", defaultMessage: "Karlovac" },
    KoprivnicaKrizevci: {
        id: "location.KoprivnicaKriževci",
        defaultMessage: "Koprivnica-Križevci",
    },
    KrapinaZagorje: {
        id: "location.KrapinaZagorje",
        defaultMessage: "Krapina-Zagorje",
    },
    LikaSenj: { id: "location.LikaSenj", defaultMessage: "Lika-Senj" },
    Medimurje: { id: "location.Međimurje", defaultMessage: "Međimurje" },
    OsijekBaranja: {
        id: "location.OsijekBaranja",
        defaultMessage: "Osijek-Baranja",
    },
    PozegaSlavonia: {
        id: "location.PožegaSlavonia",
        defaultMessage: "Požega-Slavonia",
    },
    PrimorjeGorskiKotar: {
        id: "location.PrimorjeGorskiKotar",
        defaultMessage: "Primorje-Gorski Kotar",
    },
    SibenikKnin: { id: "location.ŠibenikKnin", defaultMessage: "Šibenik-Knin" },
    SisakMoslavina: {
        id: "location.SisakMoslavina",
        defaultMessage: "Sisak-Moslavina",
    },
    SplitDalmatia: {
        id: "location.SplitDalmatia",
        defaultMessage: "Split-Dalmatia",
    },
    Varazdin: { id: "location.Varaždin", defaultMessage: "Varaždin" },
    ViroviticaPodravina: {
        id: "location.ViroviticaPodravina",
        defaultMessage: "Virovitica-Podravina",
    },
    VukovarSrijem: {
        id: "location.VukovarSrijem",
        defaultMessage: "Vukovar-Srijem",
    },
    Zadar: { id: "location.Zadar", defaultMessage: "Zadar" },
    ZagrebCounty: {
        id: "location.ZagrebCounty",
        defaultMessage: "Zagreb County",
    },
    CityofZagreb: {
        id: "location.CityofZagreb",
        defaultMessage: "City of Zagreb",
    },
    Austria: { id: "location.Austria", defaultMessage: "Austria" },
    Burgenland: { id: "location.Burgenland", defaultMessage: "Burgenland" },
    LowerAustria: {
        id: "location.LowerAustria",
        defaultMessage: "Lower Austria",
    },
    UpperAustria: {
        id: "location.UpperAustria",
        defaultMessage: "Upper Austria",
    },
    Salzburg: { id: "location.Salzburg", defaultMessage: "Salzburg" },
    Styria: { id: "location.Styria", defaultMessage: "Styria" },
    Tyrol: { id: "location.Tyrol", defaultMessage: "Tyrol" },
    Vorarlberg: { id: "location.Vorarlberg", defaultMessage: "Vorarlberg" },
    Vienna: { id: "location.Vienna", defaultMessage: "Vienna" },
    Switzerland: { id: "location.Switzerland", defaultMessage: "Switzerland" },
    Zurich: { id: "location.Zurich", defaultMessage: "Zurich" },
    Bern: { id: "location.Bern", defaultMessage: "Bern" },
    Luzern: { id: "location.Luzern", defaultMessage: "Luzern" },
    Uri: { id: "location.Uri", defaultMessage: "Uri" },
    Schwyz: { id: "location.Schwyz", defaultMessage: "Schwyz" },
    Obwalden: { id: "location.Obwalden", defaultMessage: "Obwalden" },
    Nidwalden: { id: "location.Nidwalden", defaultMessage: "Nidwalden" },
    Glarus: { id: "location.Glarus", defaultMessage: "Glarus" },
    Zug: { id: "location.Zug", defaultMessage: "Zug" },
    Fribourg: { id: "location.Fribourg", defaultMessage: "Fribourg" },
    Solothurn: { id: "location.Solothurn", defaultMessage: "Solothurn" },
    BaselStadt: { id: "location.BaselStadt", defaultMessage: "Basel-Stadt" },
    Schaffhausen: {
        id: "location.Schaffhausen",
        defaultMessage: "Schaffhausen",
    },
    AppenzellAusserrhoden: {
        id: "location.AppenzellAusserrhoden",
        defaultMessage: "Appenzell Ausserrhoden",
    },
    AppenzellInnerrhoden: {
        id: "location.AppenzellInnerrhoden",
        defaultMessage: "Appenzell Innerrhoden",
    },
    StGallen: { id: "location.StGallen", defaultMessage: "St. Gallen" },
    GraubundenGrisons: {
        id: "location.GraubündenGrisons",
        defaultMessage: "Graubünden Grisons",
    },
    Aargau: { id: "location.Aargau", defaultMessage: "Aargau" },
    Thurgau: { id: "location.Thurgau", defaultMessage: "Thurgau" },
    Ticino: { id: "location.Ticino", defaultMessage: "Ticino" },
    Vaud: { id: "location.Vaud", defaultMessage: "Vaud" },
    Valais: { id: "location.Valais", defaultMessage: "Valais" },
    Neuchatel: { id: "location.Neuchatel", defaultMessage: "Neuchatel" },
    Geneva: { id: "location.Geneva", defaultMessage: "Geneva" },
    Jura: { id: "location.Jura", defaultMessage: "Jura" },
    Carinthia: { id: "location.Carinthia", defaultMessage: "Carinthia" },

    Afghanistan: { id: "location.Afghanistan", defaultMessage: "Afghanistan" },
    Albania: { id: "location.Albania", defaultMessage: "Albania" },
    Algeria: { id: "location.Algeria", defaultMessage: "Algeria" },
    Andorra: { id: "location.Andorra", defaultMessage: "Andorra" },
    Angola: { id: "location.Angola", defaultMessage: "Angola" },
    AntiguaandBarbuda: {
        id: "location.AntiguaandBarbuda",
        defaultMessage: "Antigua and Barbuda",
    },
    Argentina: { id: "location.Argentina", defaultMessage: "Argentina" },
    Armenia: { id: "location.Armenia", defaultMessage: "Armenia" },
    Australia: { id: "location.Australia", defaultMessage: "Australia" },
    Azerbaijan: { id: "location.Azerbaijan", defaultMessage: "Azerbaijan" },
    Bahamas: { id: "location.Bahamas", defaultMessage: "Bahamas" },
    Bahrain: { id: "location.Bahrain", defaultMessage: "Bahrain" },
    Bangladesh: { id: "location.Bangladesh", defaultMessage: "Bangladesh" },
    Barbados: { id: "location.Barbados", defaultMessage: "Barbados" },
    Belarus: { id: "location.Belarus", defaultMessage: "Belarus" },
    Belgium: { id: "location.Belgium", defaultMessage: "Belgium" },
    Belize: { id: "location.Belize", defaultMessage: "Belize" },
    Benin: { id: "location.Benin", defaultMessage: "Benin" },
    Bhutan: { id: "location.Bhutan", defaultMessage: "Bhutan" },
    Bolivia: { id: "location.Bolivia", defaultMessage: "Bolivia" },
    BosniaandHerzegovina: {
        id: "location.BosniaandHerzegovina",
        defaultMessage: "Bosnia and Herzegovina",
    },
    Botswana: { id: "location.Botswana", defaultMessage: "Botswana" },
    Brazil: { id: "location.Brazil", defaultMessage: "Brazil" },
    Brunei: { id: "location.Brunei", defaultMessage: "Brunei" },
    Bulgaria: { id: "location.Bulgaria", defaultMessage: "Bulgaria" },
    BurkinaFaso: { id: "location.BurkinaFaso", defaultMessage: "Burkina Faso" },
    Burundi: { id: "location.Burundi", defaultMessage: "Burundi" },
    CaboVerde: { id: "location.CaboVerde", defaultMessage: "Cabo Verde" },
    Cambodia: { id: "location.Cambodia", defaultMessage: "Cambodia" },
    Cameroon: { id: "location.Cameroon", defaultMessage: "Cameroon" },
    Canada: { id: "location.Canada", defaultMessage: "Canada" },
    CentralAfricanRepublic: {
        id: "location.CentralAfricanRepublic",
        defaultMessage: "Central African Republic",
    },
    Chad: { id: "location.Chad", defaultMessage: "Chad" },
    Chile: { id: "location.Chile", defaultMessage: "Chile" },
    China: { id: "location.China", defaultMessage: "China" },
    Colombia: { id: "location.Colombia", defaultMessage: "Colombia" },
    Comoros: { id: "location.Comoros", defaultMessage: "Comoros" },
    Congo: { id: "location.Congo", defaultMessage: "Congo" },
    CostaRica: { id: "location.CostaRica", defaultMessage: "Costa Rica" },
    Cuba: { id: "location.Cuba", defaultMessage: "Cuba" },
    Cyprus: { id: "location.Cyprus", defaultMessage: "Cyprus" },
    CzechRepublic: {
        id: "location.CzechRepublic",
        defaultMessage: "Czech Republic",
    },
    DemocraticRepublicoftheCongo: {
        id: "location.DemocraticRepublicoftheCongo",
        defaultMessage: "Democratic Republic of the Congo",
    },
    Denmark: { id: "location.Denmark", defaultMessage: "Denmark" },
    Djibouti: { id: "location.Djibouti", defaultMessage: "Djibouti" },
    Dominica: { id: "location.Dominica", defaultMessage: "Dominica" },
    DominicanRepublic: {
        id: "location.DominicanRepublic",
        defaultMessage: "Dominican Republic",
    },
    Ecuador: { id: "location.Ecuador", defaultMessage: "Ecuador" },
    Egypt: { id: "location.Egypt", defaultMessage: "Egypt" },
    ElSalvador: { id: "location.ElSalvador", defaultMessage: "El Salvador" },
    EquatorialGuinea: {
        id: "location.EquatorialGuinea",
        defaultMessage: "Equatorial Guinea",
    },
    Eritrea: { id: "location.Eritrea", defaultMessage: "Eritrea" },
    Estonia: { id: "location.Estonia", defaultMessage: "Estonia" },
    Eswatini: { id: "location.Eswatini", defaultMessage: "Eswatini" },
    Ethiopia: { id: "location.Ethiopia", defaultMessage: "Ethiopia" },
    Fiji: { id: "location.Fiji", defaultMessage: "Fiji" },
    Finland: { id: "location.Finland", defaultMessage: "Finland" },
    France: { id: "location.France", defaultMessage: "France" },
    Gabon: { id: "location.Gabon", defaultMessage: "Gabon" },
    Gambia: { id: "location.Gambia", defaultMessage: "Gambia" },
    Georgia: { id: "location.Georgia", defaultMessage: "Georgia" },
    Ghana: { id: "location.Ghana", defaultMessage: "Ghana" },
    Greece: { id: "location.Greece", defaultMessage: "Greece" },
    Grenada: { id: "location.Grenada", defaultMessage: "Grenada" },
    Guatemala: { id: "location.Guatemala", defaultMessage: "Guatemala" },
    Guinea: { id: "location.Guinea", defaultMessage: "Guinea" },
    GuineaBissau: {
        id: "location.GuineaBissau",
        defaultMessage: "Guinea-Bissau",
    },
    Guyana: { id: "location.Guyana", defaultMessage: "Guyana" },
    Haiti: { id: "location.Haiti", defaultMessage: "Haiti" },
    Honduras: { id: "location.Honduras", defaultMessage: "Honduras" },
    Hungary: { id: "location.Hungary", defaultMessage: "Hungary" },
    Iceland: { id: "location.Iceland", defaultMessage: "Iceland" },
    India: { id: "location.India", defaultMessage: "India" },
    Indonesia: { id: "location.Indonesia", defaultMessage: "Indonesia" },
    Iran: { id: "location.Iran", defaultMessage: "Iran" },
    Iraq: { id: "location.Iraq", defaultMessage: "Iraq" },
    Ireland: { id: "location.Ireland", defaultMessage: "Ireland" },
    Israel: { id: "location.Israel", defaultMessage: "Israel" },
    Italy: { id: "location.Italy", defaultMessage: "Italy" },
    IvoryCoast: { id: "location.IvoryCoast", defaultMessage: "Ivory Coast" },
    Jamaica: { id: "location.Jamaica", defaultMessage: "Jamaica" },
    Japan: { id: "location.Japan", defaultMessage: "Japan" },
    Jordan: { id: "location.Jordan", defaultMessage: "Jordan" },
    Kazakhstan: { id: "location.Kazakhstan", defaultMessage: "Kazakhstan" },
    Kenya: { id: "location.Kenya", defaultMessage: "Kenya" },
    Kiribati: { id: "location.Kiribati", defaultMessage: "Kiribati" },
    Kosovo: { id: "location.Kosovo", defaultMessage: "Kosovo" },
    Kuwait: { id: "location.Kuwait", defaultMessage: "Kuwait" },
    Kyrgyzstan: { id: "location.Kyrgyzstan", defaultMessage: "Kyrgyzstan" },
    Laos: { id: "location.Laos", defaultMessage: "Laos" },
    Latvia: { id: "location.Latvia", defaultMessage: "Latvia" },
    Lebanon: { id: "location.Lebanon", defaultMessage: "Lebanon" },
    Lesotho: { id: "location.Lesotho", defaultMessage: "Lesotho" },
    Liberia: { id: "location.Liberia", defaultMessage: "Liberia" },
    Libya: { id: "location.Libya", defaultMessage: "Libya" },
    Liechtenstein: {
        id: "location.Liechtenstein",
        defaultMessage: "Liechtenstein",
    },
    Lithuania: { id: "location.Lithuania", defaultMessage: "Lithuania" },
    Luxembourg: { id: "location.Luxembourg", defaultMessage: "Luxembourg" },
    Madagascar: { id: "location.Madagascar", defaultMessage: "Madagascar" },
    Malawi: { id: "location.Malawi", defaultMessage: "Malawi" },
    Malaysia: { id: "location.Malaysia", defaultMessage: "Malaysia" },
    Maldives: { id: "location.Maldives", defaultMessage: "Maldives" },
    Mali: { id: "location.Mali", defaultMessage: "Mali" },
    Malta: { id: "location.Malta", defaultMessage: "Malta" },
    MarshallIslands: {
        id: "location.MarshallIslands",
        defaultMessage: "Marshall Islands",
    },
    Mauritania: { id: "location.Mauritania", defaultMessage: "Mauritania" },
    Mauritius: { id: "location.Mauritius", defaultMessage: "Mauritius" },
    Mexico: { id: "location.Mexico", defaultMessage: "Mexico" },
    Micronesia: { id: "location.Micronesia", defaultMessage: "Micronesia" },
    Moldova: { id: "location.Moldova", defaultMessage: "Moldova" },
    Monaco: { id: "location.Monaco", defaultMessage: "Monaco" },
    Mongolia: { id: "location.Mongolia", defaultMessage: "Mongolia" },
    Montenegro: { id: "location.Montenegro", defaultMessage: "Montenegro" },
    Morocco: { id: "location.Morocco", defaultMessage: "Morocco" },
    Mozambique: { id: "location.Mozambique", defaultMessage: "Mozambique" },
    Myanmar: { id: "location.Myanmar", defaultMessage: "Myanmar" },
    Namibia: { id: "location.Namibia", defaultMessage: "Namibia" },
    Nauru: { id: "location.Nauru", defaultMessage: "Nauru" },
    Nepal: { id: "location.Nepal", defaultMessage: "Nepal" },
    Netherlands: { id: "location.Netherlands", defaultMessage: "Netherlands" },
    NewZealand: { id: "location.NewZealand", defaultMessage: "New Zealand" },
    Nicaragua: { id: "location.Nicaragua", defaultMessage: "Nicaragua" },
    Niger: { id: "location.Niger", defaultMessage: "Niger" },
    Nigeria: { id: "location.Nigeria", defaultMessage: "Nigeria" },
    NorthKorea: { id: "location.NorthKorea", defaultMessage: "North Korea" },
    NorthMacedonia: {
        id: "location.NorthMacedonia",
        defaultMessage: "North Macedonia",
    },
    Norway: { id: "location.Norway", defaultMessage: "Norway" },
    Oman: { id: "location.Oman", defaultMessage: "Oman" },
    Pakistan: { id: "location.Pakistan", defaultMessage: "Pakistan" },
    Palau: { id: "location.Palau", defaultMessage: "Palau" },
    Palestine: { id: "location.Palestine", defaultMessage: "Palestine" },
    Panama: { id: "location.Panama", defaultMessage: "Panama" },
    PapuaNewGuinea: {
        id: "location.PapuaNewGuinea",
        defaultMessage: "Papua New Guinea",
    },
    Paraguay: { id: "location.Paraguay", defaultMessage: "Paraguay" },
    Peru: { id: "location.Peru", defaultMessage: "Peru" },
    Philippines: { id: "location.Philippines", defaultMessage: "Philippines" },
    Poland: { id: "location.Poland", defaultMessage: "Poland" },
    Portugal: { id: "location.Portugal", defaultMessage: "Portugal" },
    Qatar: { id: "location.Qatar", defaultMessage: "Qatar" },
    Romania: { id: "location.Romania", defaultMessage: "Romania" },
    Russia: { id: "location.Russia", defaultMessage: "Russia" },
    Rwanda: { id: "location.Rwanda", defaultMessage: "Rwanda" },
    SaintKittsandNevis: {
        id: "location.SaintKittsandNevis",
        defaultMessage: "Saint Kitts and Nevis",
    },
    SaintLucia: { id: "location.SaintLucia", defaultMessage: "Saint Lucia" },
    SaintVincentandtheGrenadines: {
        id: "location.SaintVincentandtheGrenadines",
        defaultMessage: "Saint Vincent and the Grenadines",
    },
    Samoa: { id: "location.Samoa", defaultMessage: "Samoa" },
    SanMarino: { id: "location.SanMarino", defaultMessage: "San Marino" },
    SaoTomeandPrincipe: {
        id: "location.SaoTomeandPrincipe",
        defaultMessage: "Sao Tome and Principe",
    },
    SaudiArabia: { id: "location.SaudiArabia", defaultMessage: "Saudi Arabia" },
    Senegal: { id: "location.Senegal", defaultMessage: "Senegal" },
    Serbia: { id: "location.Serbia", defaultMessage: "Serbia" },
    Seychelles: { id: "location.Seychelles", defaultMessage: "Seychelles" },
    SierraLeone: { id: "location.SierraLeone", defaultMessage: "Sierra Leone" },
    Singapore: { id: "location.Singapore", defaultMessage: "Singapore" },
    Slovakia: { id: "location.Slovakia", defaultMessage: "Slovakia" },
    Slovenia: { id: "location.Slovenia", defaultMessage: "Slovenia" },
    SolomonIslands: {
        id: "location.SolomonIslands",
        defaultMessage: "Solomon Islands",
    },
    Somalia: { id: "location.Somalia", defaultMessage: "Somalia" },
    SouthAfrica: { id: "location.SouthAfrica", defaultMessage: "South Africa" },
    SouthKorea: { id: "location.SouthKorea", defaultMessage: "South Korea" },
    SouthSudan: { id: "location.SouthSudan", defaultMessage: "South Sudan" },
    Spain: { id: "location.Spain", defaultMessage: "Spain" },
    SriLanka: { id: "location.SriLanka", defaultMessage: "Sri Lanka" },
    Sudan: { id: "location.Sudan", defaultMessage: "Sudan" },
    Suriname: { id: "location.Suriname", defaultMessage: "Suriname" },
    Sweden: { id: "location.Sweden", defaultMessage: "Sweden" },
    Syria: { id: "location.Syria", defaultMessage: "Syria" },
    Taiwan: { id: "location.Taiwan", defaultMessage: "Taiwan" },
    Tajikistan: { id: "location.Tajikistan", defaultMessage: "Tajikistan" },
    Tanzania: { id: "location.Tanzania", defaultMessage: "Tanzania" },
    Thailand: { id: "location.Thailand", defaultMessage: "Thailand" },
    TimorLeste: { id: "location.TimorLeste", defaultMessage: "Timor-Leste" },
    Togo: { id: "location.Togo", defaultMessage: "Togo" },
    Tonga: { id: "location.Tonga", defaultMessage: "Tonga" },
    TrinidadandTobago: {
        id: "location.TrinidadandTobago",
        defaultMessage: "Trinidad and Tobago",
    },
    Tunisia: { id: "location.Tunisia", defaultMessage: "Tunisia" },
    Turkey: { id: "location.Turkey", defaultMessage: "Turkey" },
    Turkmenistan: {
        id: "location.Turkmenistan",
        defaultMessage: "Turkmenistan",
    },
    Tuvalu: { id: "location.Tuvalu", defaultMessage: "Tuvalu" },
    Uganda: { id: "location.Uganda", defaultMessage: "Uganda" },
    Ukraine: { id: "location.Ukraine", defaultMessage: "Ukraine" },
    UnitedArabEmirates: {
        id: "location.UnitedArabEmirates",
        defaultMessage: "United Arab Emirates",
    },
    UnitedKingdom: {
        id: "location.UnitedKingdom",
        defaultMessage: "United Kingdom",
    },
    UnitedStatesofAmerica: {
        id: "location.UnitedStatesofAmerica",
        defaultMessage: "United States of America",
    },
    Uruguay: { id: "location.Uruguay", defaultMessage: "Uruguay" },
    Uzbekistan: { id: "location.Uzbekistan", defaultMessage: "Uzbekistan" },
    Vanuatu: { id: "location.Vanuatu", defaultMessage: "Vanuatu" },
    VaticanCity: { id: "location.VaticanCity", defaultMessage: "Vatican City" },
    Venezuela: { id: "location.Venezuela", defaultMessage: "Venezuela" },
    Vietnam: { id: "location.Vietnam", defaultMessage: "Vietnam" },
    WesternSahara: {
        id: "location.WesternSahara",
        defaultMessage: "Western Sahara",
    },
    Yemen: { id: "location.Yemen", defaultMessage: "Yemen" },
    Zambia: { id: "location.Zambia", defaultMessage: "Zambia" },
    Zimbabwe: { id: "location.Zimbabwe", defaultMessage: "Zimbabwe" },
});
