import React from 'react';
import { Divider } from 'antd';
import { ReactComponent as Logo } from './Cogo_logo_login.svg';
import { ReactComponent as Icon } from './Cogo_icon_login.svg';
import './LoginHeader.less';

function LoginHeader(props) {
    let texts = props.texts ? props.texts : ['Exclusive access', 'to top talent'];

    return (
        <div className="header-container">
            <div className="logo-container">
                {props.useIcon ? <Icon id="logo-icon" /> : <Logo id="logo-image" /> }
                <div className="logo-text-container">
                    {texts.length === 1 ? <h2>{texts[0]}</h2> : texts.map((text, i) => <p key={i}>{text}</p>)}
                </div>
            </div>
            {!props.hideDivider && <div className="divider-container">
                <Divider />
            </div>}
        </div>
    );
}

export default LoginHeader