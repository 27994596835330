import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { Form, Input, Select, DatePicker, Button } from "antd";
import moment from "moment";
import TagsSelector from "../../components/TagsSelector/TagsSelector";
import { putJobOffer } from "../../services/jobOfferService";
import { getJobLocations, getCitiesForRegion } from "../../services/locationService";
import { FormattedMessage, defineMessages, injectIntl } from "react-intl";
import LanguageMessages from "../Language/LanguageMessages";
import { FormatPositions } from "../../Helpers/PositionHelper";
import { FormatLocations } from "../../Helpers/LocationHelper";
import { FormatCities } from "../../Helpers/CityHelper";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useHistory } from "react-router-dom";
import "./JobOfferEdit.less";

let messages = defineMessages({
    setAmount: {
        id: "setAmount",
        defaultMessage: "Set amount",
    },
    startDate: {
        id: "startDate",
        defaultMessage: "Start date",
    },
    endDate: {
        id: "endDate",
        defaultMessage: "End date",
    },
    applicationDeadlineDate: {
        id: "applicationDeadlineDate",
        defaultMessage: "Select date",
    },
});

function JobOfferEdit(props) {
    const [title, setTitle] = useState(props.title);
    const [description, setDescription] = useState(props.shortDescription);
    const [positions, setPositions] = useState(props.positions);
    const [isProfessional, setIsProfessional] = useState(props.isProfessional);
    const [jobType, setJobType] = useState(props.jobType);
    const [location, setLocation] = useState(props.locationId);
    const [city, setCity] = useState(props.cityId);
    const [countryside, setCountryside] = useState(props.countryside);
    const [startDate, setStartDate] = useState(props.start);
    const [endDate, setEndDate] = useState(props.end);
    const [applicationDeadline, setApplicationDeadline] = useState(props.applicationDeadline);
    const [driversLicenceNeeded, setDriversLicenceNeeded] = useState(props.driversLicenceNeeded);
    const languages = useSelector(state => state.languages);
    const history = useHistory();
    const user = useSelector(state => state.user);
    const allPositions = useSelector(state => state.positions);
    const [jobLocations, setJobLocations] = useState([]);
    const [cityLocations, setCityLocations] = useState([]);
    const [citySelected, setCitySelected] = useState(
        props.cityId === "00000000-0000-0000-0000-000000000000" ? true : false
    );

    const [positionOpen, setPositionOpen] = useState(false);
    const [languageOpen, setLanguageOpen] = useState(false);
    const [locationOpen, setLocationOpen] = useState(false);
    const [cityOpen, setCityOpen] = useState(false);

    const FormItem = Form.Item;
    const { Option } = Select;
    const { RangePicker } = DatePicker;

    useEffect(() => {
        let fetchJobLocations = () => {
            getJobLocations(user).then(setJobLocations);
        };
        fetchJobLocations();
    }, [user]);

    let fetchCitesForRegion = useCallback(() => {
        getCitiesForRegion(user, location).then(setCityLocations);
    }, [location]);

    useEffect(() => {
        fetchCitesForRegion();
    }, [fetchCitesForRegion]);

    let onDescriptionChange = value => {
        setDescription(value);
    };

    let handleApplicationDeadlineChange = values => {
        if (values === null) {
            setApplicationDeadline(null);
        } else {
            setApplicationDeadline(values.format("YYYY-MM-DD"));
        }
    };

    let applicationDisabledDate = current => {
        let startDateDate = new Date(startDate);
        return current && current >= startDateDate && startDate != "1970-01-01T00:00:00";
    };

    let handleDateChange = values => {
        setStartDate(values[0].format("YYYY-MM-DD"));
        if (values[1] !== null) setEndDate(values[1].format("YYYY-MM-DD"));
    };

    let disabledDate = current => {
        return current && current < moment().endOf("day");
    };

    let onSubmit = async values => {
        let sDate = null;
        let eDate = null;

        if (startDate !== null && startDate !== "1970-01-01T00:00:00") {
            sDate = new Date(startDate);
        }
        if (endDate !== null && endDate !== "1970-01-01T00:00:00") {
            eDate = new Date(endDate);
        }

        const state = {
            id: props.id,
            companyId: user.info,
            start: startDate != undefined ? sDate.toISOString() : null,
            end: endDate != undefined ? eDate.toISOString() : null,
            locationId: location,
            cityId:
                city === undefined || city === null ? "00000000-0000-0000-0000-000000000000" : city,
            countryside: countryside,
            shortDescription: description.toString("html"),
            positions: positions,
            tags: values.tags,
            isProffesional: isProfessional,
            jobType: jobType === "0" ? 0 : jobType === "1" ? 1 : 2,
            title: title,
            languagesRequired: values.languagesRequired,
            driversLicenceNeeded: driversLicenceNeeded,
            applicationDeadline: applicationDeadline,
        };

        putJobOffer(user, JSON.stringify(state), props.id).then(() => {
            history.push({
                pathname: "/CompanyJobOffers",
            });
        });
    };

    let onFinish = values => {
        onSubmit(values);
    };

    return (
        <div className="jobOfferDetails-container">
            <Form
                className="edit-job-offer-form"
                onFinish={onFinish}
                layout="vertical"
                initialValues={{
                    title: props.title,
                    positions: positions,
                    professional: isProfessional,
                    jobType:
                        jobType === 0 ? (
                            <p>
                                {" "}
                                <FormattedMessage id="jobType.temporary" />
                            </p>
                        ) : jobType === 1 ? (
                            <p>
                                <FormattedMessage id="jobType.seasonal" />
                            </p>
                        ) : jobType === 2 ? (
                            <p>
                                <FormattedMessage id="jobType.contract" />
                            </p>
                        ) : (
                            ""
                        ),
                    location: location,
                    city: city === "00000000-0000-0000-0000-000000000000" ? undefined : city,
                    countryside: countryside,
                    date:
                        startDate &&
                        endDate &&
                        endDate != "1970-01-01T00:00:00" &&
                        startDate != "1970-01-01T00:00:00"
                            ? [moment(startDate), moment(endDate)]
                            : startDate && startDate != "1970-01-01T00:00:00"
                            ? [moment(startDate), null]
                            : "",
                    tags: props.tags,
                    languagesRequired: props.languagesRequired,
                    driversLicenceNeeded: driversLicenceNeeded,
                    description: props.shortDescription,
                    applicationDeadline:
                        props.applicationDeadline != "1970-01-01T00:00:00"
                            ? moment(applicationDeadline)
                            : "",
                }}
            >
                <div className="job-offer-edit-grid">
                    <FormItem
                        name="title"
                        label={<FormattedMessage id="title" defaultMessage="Title" />}
                        rules={[
                            {
                                required: true,
                                message: <FormattedMessage id="titleMandatory" />,
                            },
                        ]}
                    >
                        <Input onChange={e => setTitle(e.target.value)} />
                    </FormItem>
                    <FormItem
                        name="positions"
                        label={<FormattedMessage id="selectPositions" />}
                        rules={[
                            {
                                required: true,
                                message: (
                                    <FormattedMessage
                                        id="positionMandatory"
                                        defaultMessage="Position field is mandatory"
                                    />
                                ),
                            },
                        ]}
                    >
                        <Select
                            placeholder={<FormattedMessage id="selectPositions" />}
                            onChange={e => setPositions([].concat(e))}
                            onClick={() => setPositionOpen(!positionOpen)}
                            onBlur={() => setPositionOpen(false)}
                            open={positionOpen}
                            showArrow={true}
                            showSearch
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {allPositions &&
                                FormatPositions([...allPositions], props.intl).map(position => (
                                    <Option value={position.id} key={position.id}>
                                        {position.name}
                                    </Option>
                                ))}
                        </Select>
                    </FormItem>

                    <FormItem
                        name="jobType"
                        label={<FormattedMessage id="selectJobType" />}
                        rules={[
                            {
                                required: true,
                                message: (
                                    <FormattedMessage
                                        id="jobTypeMandatory"
                                        defaultMessage="Job type is mandatory"
                                    />
                                ),
                            },
                        ]}
                    >
                        <Select
                            onChange={e => setJobType(e)}
                            placeholder={<FormattedMessage id="selectJobType" />}
                            showArrow={true}
                        >
                            <Option value="0" key="0">
                                <FormattedMessage
                                    id="jobType.temporary"
                                    defaultMessage="Temporary"
                                />
                            </Option>
                            <Option value="1" key="1">
                                <FormattedMessage id="jobType.seasonal" defaultMessage="Seasonal" />
                            </Option>
                            <Option value="2" key="2">
                                <FormattedMessage id="jobType.contract" defaultMessage="Contract" />
                            </Option>
                        </Select>
                    </FormItem>
                    <FormItem
                        name="location"
                        label={<FormattedMessage id="selectLocation" />}
                        rules={[
                            {
                                required: true,
                                message: (
                                    <FormattedMessage
                                        id="locationMandatory"
                                        defaultMessage="Location is mandatory"
                                    />
                                ),
                            },
                        ]}
                    >
                        <Select
                            className="select-container"
                            placeholder={<FormattedMessage id="selectLocation" />}
                            onChange={value => {
                                setLocation(value);
                                setCountryside("");
                                setCity("00000000-0000-0000-0000-000000000000");
                            }}
                            onClick={() => setLocationOpen(!locationOpen)}
                            onBlur={() => setLocationOpen(false)}
                            open={locationOpen}
                            showArrow={true}
                            showSearch
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                            }
                        >
                            {jobLocations &&
                                FormatLocations([...jobLocations], props.intl).map(location => (
                                    <Option value={location.id} key={location.id}>
                                        {location.name}
                                    </Option>
                                ))}
                        </Select>
                    </FormItem>
                    <FormItem
                        name="city"
                        label={<FormattedMessage id="city" defaultMessage="City" />}
                    >
                        <Select
                            className="select-container"
                            onClick={() => setCityOpen(!cityOpen)}
                            onBlur={() => setCityOpen(false)}
                            onChange={value => {
                                if (value === null) {
                                    setCitySelected(true);
                                    setCountryside(
                                        countryside === "" ? props.countryside : countryside
                                    );
                                    setCity("00000000-0000-0000-0000-000000000000");
                                } else {
                                    setCitySelected(false);
                                    setCountryside("");
                                }
                                setCity(value);
                            }}
                            open={cityOpen}
                            showArrow={true}
                            placeholder={
                                <FormattedMessage id="selectCity" defaultMessage="Select city" />
                            }
                            showSearch
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                            }
                        >
                            {cityLocations &&
                                FormatCities(
                                    [
                                        {
                                            id: null,
                                            name: "Other",
                                            parentLocationId:
                                                "00000000-0000-0000-0000-000000000000",
                                            level: 3,
                                        },
                                    ],
                                    props.intl
                                )
                                    .concat(FormatCities([...cityLocations], props.intl))
                                    .map(cityLocation => (
                                        <Option value={cityLocation.id} key={cityLocation.id}>
                                            {cityLocation.name}
                                        </Option>
                                    ))}
                        </Select>
                    </FormItem>
                    <FormItem
                        name="countryside"
                        className={`countryside ${citySelected ? "visible" : ""}`}
                    >
                        <Input onChange={event => setCountryside(event.target.value)} />
                    </FormItem>
                    <FormItem
                        label={props.intl.formatMessage({
                            id: "selectLanguages",
                        })}
                        name="languagesRequired"
                    >
                        <Select
                            className="select-container"
                            mode="multiple"
                            placeholder={
                                <FormattedMessage
                                    id="selectLanguages"
                                    defaultMessage="Select languages"
                                />
                            }
                            onClick={() => setLanguageOpen(!languageOpen)}
                            onBlur={() => setLanguageOpen(false)}
                            open={languageOpen}
                            showArrow={true}
                        >
                            {languages.sort().map(lang => (
                                <Option value={lang} key={lang}>
                                    {props.intl.formatMessage(LanguageMessages[lang])}
                                </Option>
                            ))}
                        </Select>
                    </FormItem>
                    <FormItem
                        name="professional"
                        label={
                            <FormattedMessage
                                id="filter.proffesional"
                                defaultMessage="Proffesional"
                            />
                        }
                        rules={[
                            {
                                required: true,
                                message: (
                                    <FormattedMessage
                                        id="professionalMandatory"
                                        defaultMessage="Professional field is mandatory"
                                    />
                                ),
                            },
                        ]}
                    >
                        <Select
                            onChange={e => setIsProfessional(e)}
                            placeholder={
                                <FormattedMessage
                                    id="filter.proffesional"
                                    defaultMessage="Proffesional"
                                />
                            }
                            showArrow={true}
                        >
                            <Option value={true} key="true">
                                <FormattedMessage id="yes" />
                            </Option>
                            <Option value={false} key="false">
                                <FormattedMessage id="no" />
                            </Option>
                        </Select>
                    </FormItem>
                    <FormItem name="date" label={<FormattedMessage id="startDate" />}>
                        <RangePicker
                            allowEmpty={[false, true]}
                            id="picker"
                            onChange={handleDateChange}
                            disabledDate={disabledDate}
                            allowClear={false}
                            placeholder={[
                                props.intl.formatMessage(messages.startDate),
                                props.intl.formatMessage(messages.endDate),
                            ]}
                        />
                    </FormItem>
                    <FormItem
                        name="applicationDeadline"
                        label={
                            <FormattedMessage
                                id="applicationDeadline"
                                defaultMessage="Application deadline"
                            />
                        }
                        dependencies={["date"]}
                        hasFeedback
                        rules={[
                            ({ getFieldValue }) => ({
                                validator(rule, value) {
                                    if (startDate != null && startDate != "1970-01-01T00:00:00") {
                                        let startDateDate = getFieldValue("date")[0];
                                        startDateDate = startDateDate._d.getTime();
                                        let applicationDeadlineDate = value._d.getTime();
                                        if (!value || applicationDeadlineDate <= startDateDate) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(
                                            "Application date must be before start date"
                                        );
                                    } else {
                                        return Promise.resolve();
                                    }
                                },
                            }),
                        ]}
                    >
                        <DatePicker
                            id="picker"
                            className="application-deadline-datepicker"
                            format="DD/MM/YYYY"
                            allowClear={true}
                            placeholder={props.intl.formatMessage(messages.applicationDeadlineDate)}
                            disabledDate={applicationDisabledDate}
                            disabled={startDate === undefined ? true : false}
                            onChange={handleApplicationDeadlineChange}
                        />
                    </FormItem>

                    <FormItem
                        name="driversLicenceNeeded"
                        label={<FormattedMessage id="filter.driversLicence" />}
                    >
                        <Select
                            onChange={e => setDriversLicenceNeeded(e)}
                            placeholder={<FormattedMessage id="filter.driversLicence" />}
                            showArrow={true}
                        >
                            <Option value={true} key="true">
                                <FormattedMessage id="yes" defaultMessage="Yes" />
                            </Option>
                            <Option value={false} key="false">
                                <FormattedMessage id="no" defaultMessage="No" />
                            </Option>
                        </Select>
                    </FormItem>
                    <FormItem
                        name="tags"
                        label={<FormattedMessage id="enterTags" defaultMessage="Enter tags" />}
                    >
                        <TagsSelector addTags={true} />
                    </FormItem>
                </div>
                <FormItem
                    name="description"
                    label={<FormattedMessage id="description" defaultMessage="Description" />}
                >
                    <ReactQuill value={description} onChange={onDescriptionChange} />
                </FormItem>
                <div className="job-offer-edit-submit-container">
                    <Button htmlType="submit" className="button">
                        <FormattedMessage id="save" />
                    </Button>
                </div>
            </Form>
        </div>
    );
}

export default injectIntl(JobOfferEdit);
