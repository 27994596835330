import React from "react";
import { Button, Row, Col } from "antd";
import { FormattedMessage, injectIntl } from "react-intl";
import JobPrefenecesMessages from "../JobPreference/JobPrefenecesMessages";
import LocationMessages from "../Location/LocationMessages";
import moment from "moment";
import "./ExperienceCard.less";

function ExperienceDetails(props) {
    return (
        <div className="experiences-container">
            <div className="experience-cards-container" key={props.experience.id}>
                <Row wrap={false}>
                    <Col span={7}>
                        {" "}
                        <p className="bolded-text">
                            <FormattedMessage id="position" />:
                        </p>
                    </Col>
                    <Col>
                        <p>
                            {props.intl.formatMessage(
                                JobPrefenecesMessages[
                                    props.experience.positionName.replace(/\W/g, "")
                                ]
                            )}
                        </p>
                    </Col>
                </Row>

                {props.experience.positionName === "Other" &&
                props.experience.positionDescription !== "" ? (
                    <Row wrap={false}>
                        <Col span={7}>
                            <p className="bolded-text">
                                <FormattedMessage id="positionDescription" />:
                            </p>
                        </Col>
                        <Col>
                            <p>{props.experience.positionDescription}</p>
                        </Col>
                    </Row>
                ) : (
                    ""
                )}
                <Row wrap={false}>
                    <Col span={7}>
                        <p className="bolded-text">
                            <FormattedMessage id="companyName" defaultMessage="Company" />:
                        </p>
                    </Col>
                    <Col>
                        <p>{props.experience.companyName}</p>
                    </Col>
                </Row>
                <Row wrap={false}>
                    <Col span={7}>
                        <p className="bolded-text">
                            <FormattedMessage id="location" />:
                        </p>
                    </Col>
                    <Col>
                        <p>
                            {props.intl.formatMessage(
                                LocationMessages[props.experience.companyCity.replace(/\W/g, "")]
                            )}
                        </p>
                    </Col>
                </Row>
                <Row wrap={false}>
                    <Col span={7}>
                        <p className="bolded-text">
                            <FormattedMessage id="date" />:
                        </p>
                    </Col>
                    <Col>
                        <p>
                            {moment(props.experience.start).format("DD/MM/YYYY ")}
                            {props.experience.currentlyEmployed
                                ? "->"
                                : `- ${moment(props.experience.end).format(" DD/MM/YYYY")}`}
                        </p>
                    </Col>
                </Row>
                {props.experience.comment !== "" ? (
                    <Row wrap={false}>
                        <Col span={7}>
                            <p className="bolded-text">
                                <FormattedMessage id="comment" />:
                            </p>
                        </Col>
                        <Col>
                            <p>{props.experience.comment}</p>
                        </Col>
                    </Row>
                ) : (
                    ""
                )}
                <div className="line" />
                <div className="edit-button">
                    <Button className="button" onClick={props.handleClicks}>
                        <FormattedMessage id="back" />
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default injectIntl(ExperienceDetails);
