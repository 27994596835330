import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Input, Button, DatePicker, Select, Form, Modal } from "antd";
import { FormattedMessage, injectIntl } from "react-intl";
import moment from "moment";
import { updateExperience, deleteExperience } from "../../services/experienceService";
import { LeftOutlined } from "@ant-design/icons";
import { ReactComponent as Trash } from "../JobApplications/Trash_icon.svg";
import { getCountries } from "../../services/locationService";
import { FormatCountries } from "../../Helpers/CountryHelper";
import { FormatPositions } from "../../Helpers/PositionHelper";
import "./Experiences.less";

function EditExperience(props) {
    const allPositions = useSelector(state => state.positions);
    const user = useSelector(state => state.user);
    const [experience, setExperience] = useState(props.experience);
    const [visibility, setVisibility] = useState(false);
    const [countries, setCountries] = useState([]);

    const FormItem = Form.Item;
    const { Option } = Select;
    const { RangePicker } = DatePicker;
    const dateFormat = "YYYY/MM/DD";

    useEffect(() => {
        getCountries(user).then(res => setCountries(res));
    }, [user]);

    let setModalVisibility = isVisible => setVisibility(isVisible);

    let onPositionChange = value => {
        var position = allPositions.find(p => p.id === value);
        setExperience({
            ...experience,
            positionName: position.name,
            positionId: value,
        });
    };

    let onPositionDescriptionChange = event => {
        setExperience({
            ...experience,
            positionDescription: event.target.value,
        });
    };

    let onCompanyChange = event => {
        setExperience({
            ...experience,
            companyName: event.target.value,
        });
    };

    let onLocationChange = value => {
        var country = countries.find(country => country.id === value);
        setExperience({
            ...experience,
            locationId: value,
            companyCity: country.name,
        });
    };

    //let onCurrentlyChange = e => {
    //    setExperience({
    //        ...experience,
    //        currentlyEmployed: e.target.checked,
    //    });
    //};

    let onDateChange = values => {
        if (values !== null) {
            setExperience({
                ...experience,
                start: values[0].format("YYYY-MM-DD"),
                end: values[1].format("YYYY-MM-DD"),
            });
        }
    };

    //let onSingleDateChange = value => {
    //    if (value !== null) {
    //        setExperience({
    //            ...experience,
    //            start: value.format("YYYY-MM-DD"),
    //            end: moment().endOf("day"),
    //        });
    //    }
    //};

    let getDate = param => {
        let date = param.split("T");
        return date[0];
    };

    let disabledDate = current => {
        return current && current > moment().endOf("day");
    };

    let onCommentChange = event => {
        setExperience({ ...experience, comment: event.target.value });
    };

    let handleSubmit = () => {
        updateExperience(user, experience.id, experience).then(props.handleClicks);
    };

    let handleDelete = () => deleteExperience(user, experience.id).then(props.handleClicks);

    let onFinish = values => handleSubmit(values);

    return (
        <div className="edit-experience-container">
            <Form
                className="edit-experience-form"
                onFinish={onFinish}
                layout="vertical"
                initialValues={{
                    position: props.experience.positionId,
                    positionDescription:
                        props.experience.positionName === "Other"
                            ? props.experience.positionDescription
                            : "",
                    company: experience.companyName,
                    location: props.experience.locationId,
                    currentlyEmployed: props.experience.currentlyEmployed,
                    comment: experience.comment,
                    singleDate: moment(
                        experience.start ? getDate(experience.start) : "",
                        "YYYY-MM-DD"
                    ),
                    date: [
                        moment(experience.start ? getDate(experience.start) : "", "YYYY-MM-DD"),
                        moment(experience.end ? getDate(experience.end) : "", "YYYY-MM-DD"),
                    ],
                }}
            >
                <FormItem
                    name="position"
                    label={<FormattedMessage id="position" />}
                    rules={[
                        {
                            required: true,
                            message: <FormattedMessage id="positionMandatory" />,
                        },
                    ]}
                >
                    <Select
                        showArrow={true}
                        placeholder={<FormattedMessage id="selectPosition" />}
                        onChange={onPositionChange}
                        showSearch
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {allPositions &&
                            FormatPositions([...allPositions], props.intl).map(position => (
                                <Option value={position.id} key={position.id}>
                                    {position.name}
                                </Option>
                            ))}
                    </Select>
                </FormItem>
                {experience.positionName === "Other" ? (
                    <FormItem
                        name="positionDescription"
                        label={<FormattedMessage id="positionDescription" />}
                    >
                        <Input.TextArea
                            className="comment-container"
                            rows={3}
                            onChange={onPositionDescriptionChange}
                        />
                    </FormItem>
                ) : (
                    ""
                )}
                <FormItem
                    name="company"
                    label={<FormattedMessage id="company" />}
                    rules={[
                        {
                            required: true,
                            message: (
                                <FormattedMessage
                                    id="positionMandatory"
                                    defaultMessage="Company is mandatory"
                                />
                            ),
                        },
                    ]}
                >
                    <Input onChange={onCompanyChange} />
                </FormItem>
                <FormItem
                    name="location"
                    label={<FormattedMessage id="location" />}
                    rules={[
                        {
                            required: true,
                            message: <FormattedMessage id="locationMandatory" />,
                        },
                    ]}
                >
                    <Select
                        placeholder={
                            <FormattedMessage
                                id="selectLocation"
                                defaultMessage="Select location"
                            />
                        }
                        showArrow={true}
                        onChange={onLocationChange}
                        showSearch
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                        }
                    >
                        {countries &&
                            FormatCountries([...countries], props.intl).map(country => (
                                <Option value={country.id} key={country.id}>
                                    {country.name}
                                </Option>
                            ))}
                    </Select>
                </FormItem>
                <FormItem
                    name="date"
                    label={<FormattedMessage id="date" />}
                    rules={[
                        {
                            required: true,
                            message: <FormattedMessage id="dateFieldMandatory" />,
                        },
                    ]}
                >
                    <RangePicker
                        format="DD/MM/YYYY"
                        value={[
                            moment(experience.start, dateFormat),
                            moment(experience.end, dateFormat),
                        ]}
                        onChange={onDateChange}
                        disabledDate={disabledDate}
                        allowClear={false}
                    />
                </FormItem>
                <FormItem name="comment" label={<FormattedMessage id="comment" />}>
                    <Input.TextArea
                        className="comment-container"
                        rows={3}
                        onChange={onCommentChange}
                    />
                </FormItem>
                <div className="submit-container">
                    <Button className="plain-button" onClick={props.handleClicks}>
                        <LeftOutlined />
                        <FormattedMessage id="back" />
                    </Button>
                    <Button className="button" htmlType="submit">
                        <FormattedMessage id="save" />
                    </Button>
                    <Button className="button" onClick={() => setModalVisibility(true)}>
                        <FormattedMessage id="delete" />
                    </Button>
                    <Modal
                        title={
                            <div className="job-application-modal-title">
                                <Trash className="trash-icon" />
                                <p className="modal-title-paragraph">
                                    <FormattedMessage
                                        id="wantToDeleteExperience"
                                        defaultMessage="Do you want to delete this experience?"
                                    />
                                </p>
                            </div>
                        }
                        footer={
                            <div className="job-application-modal-footer">
                                <Button
                                    className="cancel-button-modal"
                                    onClick={() => setModalVisibility(false)}
                                >
                                    <FormattedMessage id="conversations.cancel" />
                                </Button>
                                <Button className="button" onClick={handleDelete}>
                                    <FormattedMessage id="delete" />
                                </Button>
                            </div>
                        }
                        bodyStyle={{ padding: "18px" }}
                        centered
                        closable={false}
                        visible={visibility}
                        className="job-application-modal"
                    >
                        <p>
                            <FormattedMessage
                                id="ifYouDeleteExperience"
                                defaultMessage="Deleting experience will remove experience
                                permanently"
                            />
                        </p>
                    </Modal>
                </div>
            </Form>
        </div>
    );
}

export default injectIntl(EditExperience);
