import { combineReducers } from 'redux';
import login from './loginReducer';
import user from './userReducer';
import jobApplication from './jobApplicationReducer';
import images from './imageReducer';
import positions from './postionsReducer';
import locations from './locationsReducer';
import languages from './languagesReducer';
import filter from './filterReducer';
import menu from './menuReducer';
import conversations from './conversationsReducer';

const appReducer = combineReducers({
    login,
    user,
    jobApplication,
    images,
    positions,
    locations,
    languages,
    filter,
    menu,
    conversations,
});
export default appReducer;
