import {
    ADD_JOB_OFFER_ID,
    REMOVE_JOB_OFFER_ID,
    ADD_UNREAD_JOB_APPLICATION,
    REMOVE_UNREAD_JOB_APPLICATIONS,
} from "../actions/jobApplicationAction";

const initialState = [];

function jobApplicationReducer(state = initialState, action) {
    switch (action.type) {
        case ADD_JOB_OFFER_ID:
            if (!state.includes(action.jobOfferId)) {
                return [...state, action.jobOfferId];
            }
            return state;
        case REMOVE_JOB_OFFER_ID:
            if (state.includes(action.jobOfferId)) {
                return state.filter(s => s !== action.jobOfferId);
            }
            break;
        case ADD_UNREAD_JOB_APPLICATION:
            if (!state.includes(action.jobAppGuid)) {
                return [...state, action.jobAppGuid];
            }
            return state;
        case REMOVE_UNREAD_JOB_APPLICATIONS:
            return initialState;
        default:
            return state;
    }
}

export default jobApplicationReducer;
