import { TOGGLE_MENU, CLOSE_MENU } from '../actions/menuAction';

const initialState = false;

function menuReducer(state = initialState, action) {
    switch (action.type) {
        case TOGGLE_MENU:
            return !state;
        case CLOSE_MENU:
            return false;
        default:
            return state;
    }
}

export default menuReducer;
