import React, { useState } from "react";
import { ReactComponent as FacebookIcon } from "./FooterIcons/facebook.svg";
import { ReactComponent as InstagramIcon } from "./FooterIcons/instagram.svg";
import { ReactComponent as TwitterIcon } from "./FooterIcons/twitter.svg";
import { ReactComponent as MailIcon } from "./FooterIcons/mail.svg";
import { ReactComponent as CopyrightIcon } from "./FooterIcons/copyright.svg";
import { FormattedMessage, injectIntl } from "react-intl";
import "./CustomFooter.less";
import Modal from "antd/lib/modal/Modal";
import { Button, Input } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useSelector } from "react-redux";
import { sendEmail } from "../../services/contactService";
import { displayMessageToast } from "../../Helpers/ToastHelper";

const contactFormInit = {
    subject: "",
    content: "",
};

const CustomFooter = props => {
    const user = useSelector(state => state.user);
    const [contactFormOpen, setContactFormOpen] = useState(false);
    const [formData, setFormData] = useState(contactFormInit);

    const handleCloseContactForm = () => {
        setContactFormOpen(false);
        setFormData(contactFormInit);
    };

    const handleInputChange = e => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSendFormMessage = async () => {
        const res = await sendEmail(user, formData);
        if (res.ok) {
            handleCloseContactForm();
            displayMessageToast("emailSent", true, props.intl);
        }
    };

    const disabled = formData.subject === "" || formData.content === "";

    return (
        <div className="flex-footer">
            <p id="footer-text">
                <CopyrightIcon />
                <FormattedMessage id="copyright" defaultMessage="Copyright COGO" />
            </p>
            <p
                className="contact-form-text"
                id="footer-text"
                onClick={() => setContactFormOpen(true)}
            >
                <MailIcon />
                <FormattedMessage id="contactUs" defaultMessage="Contact us" />
            </p>
            <p id="footer-text">
                <FormattedMessage id="privacyPolicy" defaultMessage="Privacy policy" />
            </p>
            <p id="footer-text">
                <FormattedMessage id="platformAgreement" defaultMessage="Terms & Conditions" />
            </p>
            <p id="footer-text">
                <FormattedMessage id="codeOfConduct" defaultMessage="Code of conduct" />
            </p>
            <p id="footer-text">
                <FormattedMessage id="impressum" defaultMessage="Impressum" />
            </p>
            <div className="footer-social-media">
                <a
                    href="https://www.facebook.com/Cogo-Healthcare-109624037402226"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <FacebookIcon />
                </a>
                <a
                    href="https://twitter.com/CogoHealthcare"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <TwitterIcon />
                </a>
                <a
                    href="https://www.instagram.com/cogo_healthcare/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <InstagramIcon />
                </a>
            </div>
            <Modal
                title={
                    <p className="contact-form-title-paragraph">
                        <FormattedMessage id="contactUs" defaultMessage="Contact us" />
                    </p>
                }
                footer={
                    <div className="contact-form-modal-footer">
                        <Button className="cancel-button-modal" onClick={handleCloseContactForm}>
                            <FormattedMessage id="conversations.cancel" />
                        </Button>
                        <Button
                            disabled={disabled}
                            className="button"
                            onClick={handleSendFormMessage}
                            htmlType="submit"
                        >
                            <FormattedMessage id="send" defaultMessage="Send" />
                        </Button>
                    </div>
                }
                bodyStyle={{ padding: "18px" }}
                centered
                closable
                visible={contactFormOpen}
                className="contact-form-modal"
                onCancel={handleCloseContactForm}
            >
                <div className="contact-form-field">
                    <label>
                        <FormattedMessage id="subject" defaultMessage="Subject" />
                    </label>
                    <Input
                        name="subject"
                        className="contact-form-input-field"
                        value={formData.subject}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="contact-form-field">
                    <label>
                        <FormattedMessage id="yourMessage" defaultMessage="Your message" />
                    </label>
                    <TextArea
                        name="content"
                        className="contact-form-input-field"
                        value={formData.content}
                        onChange={handleInputChange}
                        rows={7}
                    />
                </div>
            </Modal>
        </div>
    );
};

export default injectIntl(CustomFooter);
