import { take, call, put } from 'redux-saga/effects';
import { loadStripe } from '@stripe/stripe-js';
import { subscribe, unsubscribe, modifySubscription, updatePaymentInfo, confirmPaymentInfo } from '../services/companyService';
import { SET_SUBSCRIBING, CLEAR_SUBSCRIBING } from '../actions/authentificationActions';
import { SUBSCRIBE, UNSUBSCRIBE, MODIFY_SUBSCRIPTION, MODIFY_PAYMENT_METHOD } from '../actions/companyActions';
import { REFRESH_USER } from '../actions/userActions';
import { displayMessageToast } from '../Helpers/ToastHelper';

const publicStripeKey = "pk_test_51IJzcCCsVyPMoRuOLX7ZcE2aIXGkUWoJENlgAdht42Cm8aUZT5Sd7NHk5VZBiiMh0pEe3dZUFiJNkkM6f36PLssy00WVg4YHDc"

export function* subscribeFlow() {
    while (true) {
        const request = yield take(SUBSCRIBE);
        const user = request.user;

        try {
            yield put({ type: SET_SUBSCRIBING });
            const response = yield call(subscribe, user, request.companyId, request.lvl);

            if (!response.success) {
                displayMessageToast(response.errorMsg, false);
            } else {
                if (response.paymentStatus === "succeeded") {
                    displayMessageToast("Subscribed successfully!");
                } else if (response.paymentStatus === "requires_action") {
                    let stripe = yield call(loadStripe, publicStripeKey);
                    const stripeResponse = yield call(stripe.confirmCardPayment, response.paymentIntentSecret);

                    if (stripeResponse.error) {
                        displayMessageToast(stripeResponse.error.message, false);
                    } else if (stripeResponse.paymentIntent && stripeResponse.paymentIntent.status === 'succeeded') {
                        displayMessageToast("Subscribed successfully!");
                    } else {
                        displayMessageToast("Error confirming initial payment!", false);
                    }
                } else if (response.paymentStatus === "requires_payment_method") {
                    displayMessageToast("There were issues setting up your credit card", false);
                }
            }
        } catch (error) {
            displayMessageToast(error.message, false);
        } finally {
            yield put({ type: REFRESH_USER });
            yield put({ type: CLEAR_SUBSCRIBING });
        }
    }
}

export function* unsubscribeFlow() {
    while (true) {
        const request = yield take(UNSUBSCRIBE);
        const user = request.user;

        try {
            yield put({ type: SET_SUBSCRIBING });
            const response = yield call(unsubscribe, user, request.companyId, request.lvl);
            yield put({ type: REFRESH_USER });
            displayMessageToast(response);
        } catch (error) {
            displayMessageToast(error.message, false);
        } finally {
            yield put({ type: CLEAR_SUBSCRIBING });
        }
    }
}

export function* modifySubscriptionFlow() {
    while (true) {
        const request = yield take(MODIFY_SUBSCRIPTION);
        const user = request.user;

        try {
            yield put({ type: SET_SUBSCRIBING });
            const response = yield call(modifySubscription, user, request.companyId, request.newLvl);

            if (!response.success) {
                displayMessageToast(response.errorMsg, false);
            } else {
                if (response.paymentStatus === "succeeded") {
                    displayMessageToast("Subscribtion updated successfully!");
                } else if (response.paymentStatus === "requires_action") {
                    let stripe = yield call(loadStripe, publicStripeKey);
                    const stripeResponse = yield call(stripe.confirmCardPayment, response.paymentIntentSecret);

                    if (stripeResponse.error) {
                        displayMessageToast(stripeResponse.error.message, false);
                    } else if (stripeResponse.paymentIntent && stripeResponse.paymentIntent.status === 'succeeded') {
                        displayMessageToast("Subscribtion updated successfully!");
                    } else {
                        displayMessageToast("Error confirming initial payment!", false);
                    }
                }
                else if (response.paymentStatus === "requires_payment_method") {
                    displayMessageToast("There were issues setting up your credit card", false);
                }
            }
        } catch (error) {
            displayMessageToast(error.message, false);
        } finally {
            yield put({ type: REFRESH_USER });
            yield put({ type: CLEAR_SUBSCRIBING });
        }
    }
}

export function* modifyPaymentMethodFlow() {
    while (true) {
        const request = yield take(MODIFY_PAYMENT_METHOD);
        const user = request.user;

        try {
            yield put({ type: SET_SUBSCRIBING });
            const response = yield call(updatePaymentInfo, user, request.companyId, request.paymentMethod);

            if (!response.success) {
                displayMessageToast(response.errorMsg, false);
            } else {
                if (response.intentStatus === "succeeded") {
                    displayMessageToast("Payment method updated successfully!");
                } else if (response.intentStatus === "requires_action" || response.intentStatus === "requires_confirmation") {
                    let stripe = yield call(loadStripe, publicStripeKey);
                    const stripeResponse = yield call(stripe.confirmCardSetup, response.setupIntentSecret);

                    if (stripeResponse.error) {
                        displayMessageToast(stripeResponse.error.message, false);
                    } else if (stripeResponse.setupIntent && stripeResponse.setupIntent.status === 'succeeded') {
                        displayMessageToast("Payment method updated successfully!");
                        yield call(confirmPaymentInfo, user, request.companyId);
                    } else {
                        displayMessageToast("Error confirming payment method!", false);
                    }
                }
            }
        } catch (error) {
            displayMessageToast(error.message, false);
        } finally {
            yield put({ type: REFRESH_USER });
            yield put({ type: CLEAR_SUBSCRIBING });
        }
    }
}