import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useStripe } from "@stripe/react-stripe-js";
import { getCompanyPayments, getCompanyPaymentMethod } from "../../services/companyService";
import { refreshUser } from "../../actions/userActions";
import { displayMessageToast } from "../../Helpers/ToastHelper";
import { withLoader } from "../../components/WithLoader/withLoader";
import "./CompanyPayments.less";

function CompanyPayments({ goBack, setLoading, companyId, refreshCount }) {
    const [paymentsCountLimit, setPaymentsCountLimit] = useState(10);
    const [payments, setPayments] = useState([]);
    const user = useSelector(state => state.user);
    const dispatch = useDispatch();
    const stripe = useStripe();

    useEffect(() => {
        if (user != null) {
            setLoading(true);
            getCompanyPayments(user, companyId, paymentsCountLimit)
                .then(setPayments)
                .then(() => setLoading(false));
        }
    }, [user, paymentsCountLimit, companyId, refreshCount, setLoading]);

    const paymentsLoadMore = () => {
        setPaymentsCountLimit(paymentsCountLimit + 10);
    };

    const confirmPayment = async (clientSecret, status) => {
        setLoading(true);
        let data = undefined;
        if (status === "requires_payment_method") {
            let payment_method = await getCompanyPaymentMethod(user, companyId);
            data = { payment_method };
        }
        let result = await stripe.confirmCardPayment(clientSecret, data);
        if (!result.error) {
            displayMessageToast("Payment successful!");
            goBack();
        } else {
            displayMessageToast(result.error.message, false);
        }
        dispatch(refreshUser());
        setPayments([]);
        setLoading(false);
    };

    return (
        <div className="company-payments-container">
            <h2>CompanyPayments</h2>

            <div className="payments-table-container">
                {payments && (
                    <table className="payments-table">
                        <thead>
                            <tr>
                                <th>Description</th>
                                <th>Date</th>
                                <th>Amount</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {payments.map(p => {
                                let requiresAction = p.status.includes("requires");
                                return (
                                    <tr key={p.id}>
                                        <td>{p.description}</td>
                                        <td>{new Date(p.timestamp).toLocaleDateString("de-DE")}</td>
                                        <td>
                                            {p.ammount} {p.currency}
                                        </td>
                                        <td className={requiresAction ? "action-required" : null}>
                                            {requiresAction ? "requires action" : p.status}
                                        </td>
                                        <td>
                                            {requiresAction ? (
                                                <span
                                                    className="confirm-payment"
                                                    onClick={confirmPayment.bind(
                                                        null,
                                                        p.clientSecret,
                                                        p.status
                                                    )}
                                                >
                                                    confirm
                                                </span>
                                            ) : null}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                )}
                {payments.length === paymentsCountLimit && (
                    <div className="more" onClick={paymentsLoadMore}>
                        Load more
                    </div>
                )}
            </div>
        </div>
    );
}

export default withLoader(CompanyPayments);
