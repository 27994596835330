import { getAuthorizationProperties } from './authorizationService';

export function getExperiences(user, employeeGuid) {
    return fetch(`/api/Experiences?guid=${employeeGuid}`, {
        method: 'GET',
        ...getAuthorizationProperties(user.token, {
            'Content-Type': 'application/json',
        }),
    }).then((response) => {
        if (!response.ok) {
            return response.text().then((text) => {
                throw Error(text);
            });
        } else {
            return response.json();
        }
    });
}

export function getExperience(user, experienceGuid) {
    return fetch(`/api/Experiences/${experienceGuid}`, {
        method: 'GET',
        ...getAuthorizationProperties(user.token, {
            'Content-Type': 'application/json',
        }),
    }).then(response => response.json());
}

export function updateExperience(user, experienceGuid, obj) {
    return fetch(`api/Experiences/${experienceGuid}`, {
        method: 'PUT',
        ...getAuthorizationProperties(user.token, {
            'Content-Type': 'application/json',
        }),
        body: JSON.stringify(obj),
    });
}

export function postExperience(user, state) {
    return fetch('api/Experiences', {
        method: 'POST',
        ...getAuthorizationProperties(user.token, {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }),
        body: state,
    }).then(response => response.json());
}

export function deleteExperience(user, experienceId) {
    return fetch(`api/Experiences/${experienceId}`, {
        method: 'DELETE',
        ...getAuthorizationProperties(user.token),
    });
}
