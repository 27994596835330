import React from 'react';
import Masonry from 'react-masonry-css';
import './CogoMasonry.less';

const breakpointColumnsObj = {
    default: 3,
    1599: 2,
    1199: 1,
};

export default function CogoMasonry(props) {
    return (
        <Masonry
            breakpointCols={breakpointColumnsObj}
            className={`cogo-masonry ${props.className}`}
            columnClassName={`cogo-masonry-column ${props.className}-column`}
        >
            {props.children}
        </Masonry>
    );
}
