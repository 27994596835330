import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Select, Spin } from 'antd';
import debounce from 'lodash/debounce';
import { searchTags, postTag } from '../../services/tagService';
import { FormattedMessage } from 'react-intl';
import './TagsSelector.css';
import { PlusOutlined } from '@ant-design/icons'

const { Option } = Select;

function getStartValue(props) {
    if (props && props.value) {
        return props.value.map(v => ({ key: v.id, label: v.name }));
    }
    else {
        return [];
    }
}

function TagsSelector(props) {
    let lastFetchId = 0;
    const [data, setData] = useState([]);
    const [value, setValue] = useState(getStartValue(props));
    const [fetching, setFetching] = useState(false);
    const [newTag, setNewTag] = useState('');
    const user = useSelector((state) => state.user);

    let fetchTag = searchString => {
        lastFetchId += 1;
        const fetchId = lastFetchId;
        setData([]);
        setFetching(true);

        if (props.addTags && searchString.length >= 3) {
            setNewTag(searchString);
        }
        else {
            setNewTag('')
        }

        searchTags(user, searchString)
            .then(data => {
                if (fetchId !== lastFetchId) {
                    return;
                }
                setData(data);
                setFetching(false);
            });

    };

    fetchTag = debounce(fetchTag, 800);

    let handleChange = newValues => {
        if (!newValues.some(nv => !nv.key)) {
            if (props.onChange) {
                props.onChange(newValues.map(n => ({ id: n.key, name: n.label })));
            }
            setValue(newValues);
            setData([]);
            setNewTag('');
            setFetching(false);
        }
    };

    let handlePlusClick = () => {
        postTag(user, newTag).then((res) => {
            setNewTag('');

            let newValues = [...value, { label: res.name, key: res.id }];
            setValue(newValues)
            if (props.onChange) {
                props.onChange(newValues.map(n => ({ id: n.key, name: n.label })));
            }
        })
    }

    return (
        <Select
            className="tags-select"
            mode="multiple"
            labelInValue
            value={value}
            placeholder={<FormattedMessage
                id="enterTags"
                defaultMessage="Enter tags"
            />}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            filterOption={false}
            onSearch={fetchTag}
            onChange={handleChange}
        >
            {data.length === 0 && props.addTags && newTag.length > 2 ?
                <Option>
                    <div className="new-tag-option" onClick={handlePlusClick}>
                        {newTag}
                        <PlusOutlined />
                    </div>
                </Option> : 
                data.map(d => (
                    <Option
                        key={d.id}>
                        {d.name}
                    </Option>
            ))}
        </Select>
    );

}

export default TagsSelector;