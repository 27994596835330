import { getAuthorizationProperties } from './authorizationService';

export function getCompany(user, companyId) {
    return fetch(`/api/Companies/${companyId}`, {
        method: 'GET',
        ...getAuthorizationProperties(user.token, {
            'Content-Type': 'application/json'
        })
    }).then(response => response.json());
}

export function getCompanies(user) {
    return fetch('/api/Companies', {
        method: 'GET',
        ...getAuthorizationProperties(user.token, {
            'Content-Type': 'application/json'
        })
    }).then(response => response.json());
}

export function updateCompanyInfo(user, obj) {
    return fetch(`/api/Companies/${user.info}`, {
        method: 'PUT',
        ...getAuthorizationProperties(user.token, {
            'Content-Type': 'application/json'
        }),
        body: JSON.stringify(obj)
    });
}

export function searchCompany(user, value) {
    return fetch(`/api/Companies/search?value=${value}`, {
        method: 'GET',
        ...getAuthorizationProperties(user.token, {
            'Content-Type': 'application/json'
        })
    }).then(response => response.json());
}

export async function addPaymentInfo(user, guid, paymentMethod) {
    return await fetch(`api/Companies/${guid}/addPaymentInfo`, {
        method: "PUT",
        ...getAuthorizationProperties(user.token, {
            "Content-Type": "application/json",
        }),
        body: JSON.stringify(paymentMethod),
    }).then(response => {
        if (!response.ok) {
            return response.json().then((err) => {
                throw Error(err.message);
            });
        } else {
            return true;
        }
    });
}

export async function updatePaymentInfo(user, guid, paymentMethod) {
    return await fetch(`api/Companies/${guid}/updatePaymentInfo`, {
        method: "PATCH",
        ...getAuthorizationProperties(user.token, {
            "Content-Type": "application/json",
        }),
        body: JSON.stringify(paymentMethod),
    }).then(response => {
        if (!response.ok) {
            return response.json().then((err) => {
                throw Error(err.message);
            });
        } else {
            return response.json();
        }
    });
}

export async function getConfirmPaymentInfo(user, guid) {
    return await fetch(`api/Companies/${guid}/confirmPaymentInfo`, {
        method: "GET", ...getAuthorizationProperties(user.token)
    }).then(response => response.text());
}

export async function confirmPaymentInfo(user, guid) {
    return await fetch(`api/Companies/${guid}/confirmPaymentInfo`, {
        method: "PATCH", ...getAuthorizationProperties(user.token)
    }).then(response => {
        if (!response.ok) {
            return response.json().then((err) => {
                throw Error(err.message);
            });
        } else {
            return true;
        }
    });
}

export async function removePaymentInfo(user, guid) {
    return await fetch(`api/Companies/${guid}/removePaymentInfo`, {
        method: "PUT",
        ...getAuthorizationProperties(user.token, {
            "Content-Type": "application/json",
        })
    });
}

export async function subscribe(user, guid, lvl) {
    return await fetch(`api/Companies/${guid}/subscribe`, {
        method: "PUT",
        ...getAuthorizationProperties(user.token, {
            "Content-Type": "application/json",
        }),
        body: JSON.stringify(lvl),
    }).then(response => {
        if (!response.ok) {
            return response.text().then((text) => {
                throw Error(text);
            });
        } else {
            return response.json();
        }
    });
}

export async function modifySubscription(user, guid, lvl) {
    return await fetch(`api/Companies/${guid}/modifySubscription`, {
        method: "PUT",
        ...getAuthorizationProperties(user.token, {
            "Content-Type": "application/json",
        }),
        body: JSON.stringify(lvl),
    }).then(response => {
        if (!response.ok) {
            return response.text().then((text) => {
                throw Error(text);
            });
        } else {
            return response.json();
        }
    });
}

export async function unsubscribe(user, guid, lvl = null) {
    return await fetch(`api/Companies/${guid}/unsubscribe`, {
        method: "PUT",
        ...getAuthorizationProperties(user.token, {
            "Content-Type": "application/json",
        }),
        body: JSON.stringify(lvl ? lvl : ""),
    }).then(response => {
        if (!response.ok) {
            return response.text().then((text) => {
                throw Error(text);
            });
        } else {
            return response.text();
        }
    });
}

export function getCompanyPayments(user, companyId, count) {
    return fetch(`api/Companies/${companyId}/payments?count=${count}`, {
        method: "GET",
        ...getAuthorizationProperties(user.token, {
            "Content-Type": "application/json",
        }),
    }).then((response) => response.json());
}

export function getCompanyPaymentMethod(user, companyId) {
    return fetch(`api/Companies/${companyId}/paymentMethod`, {
        method: "GET",
        ...getAuthorizationProperties(user.token, {
            "Content-Type": "application/json",
        }),
    }).then((response) => {
        if (!response.ok) {
            return response.text().then((text) => {
                throw Error(text);
            });
        } else {
            return response.text();
        }
    });
}

export function buyUnlocks(user, companyId, ammount = 1) {
    return fetch(`api/Companies/${companyId}/unlocks?ammount=${ammount}`, {
        method: "GET", ...getAuthorizationProperties(user.token)
    }).then((response) => {
        if (!response.ok) {
            return response.text().then((text) => {
                throw Error(text);
            });
        } else {
            return response.text();
        }
    });
}