import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Input, Button, DatePicker, Select, Form, Popover } from 'antd';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import moment from 'moment';
import { LeftOutlined } from '@ant-design/icons';
import { InfoCircleOutlined } from '@ant-design/icons';
import { postEducation } from '../../services/educationService';
import './NewEducation.less';

let messages = defineMessages({
    startDate: {
        id: 'startDate',
        defaultMessage: 'Start date',
    },
    endDate: {
        id: 'endDate',
        defaultMessage: 'End date',
    },
    educatioanlInstitution: {
        id: 'educationalInstitution',
        defaultMessage: 'Educational institution',
    },
    educatioanlInstitutionType: {
        id: 'educationalInstitutionType',
        defaultMessage: 'Type of education',
    },
    university: {
        id: 'university',
        defaultMessage: 'University',
    },
    college: {
        id: 'college',
        defaultMessage: 'College',
    },
    highSchool: {
        id: 'highSchool',
        defaultMessage: 'High school',
    },
    apprenticeship: {
        id: 'apprenticeship',
        defaultMessage: 'Apprenticeship',
    },
});

function NewEducation(props) {
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [institution, setInstitution] = useState();
    const [educationType, setEducationType] = useState();
    const [isProfessional, setIsProfessional] = useState();
    const user = useSelector(state => state.user);

    const { Option } = Select;
    const FormItem = Form.Item;
    const { RangePicker } = DatePicker;

    let onFinish = values => {
        onSubmit(values);
    };

    const content = (
        <FormattedMessage
            id="professionalInfo"
            defaultMessage="Professional nurse as defined in Paragraph 71 of the German Social Code (SGB) stating Germany�s Social Law, book #XI, Paragraph 3
        i.e., care specialists, healthcare specialists, qualified nurse, etc. - meaning you earned your profession / specialization / qualification / certification 
        in an apprenticeship or by having studied at a college or university (or equivalent thereof)."
        />
    );

    let onSubmit = () => {
        const sDate = new Date(startDate);
        const eDate = new Date(endDate);
        const state = {
            educationalInstitution: institution,
            educationalInstitutionType: educationType,
            isProfessional: isProfessional,
            start: sDate.toISOString(),
            end: eDate.toISOString(),
        };
        postEducation(user, JSON.stringify(state)).then(props.handleClicks);
    };

    return (user.role === 'Employee' ?
        <div className="new-education-container">
            <Form
                className="new-education-form"
                onFinish={onFinish}
                layout="vertical"
            >
                <FormItem
                    name="institution"
                    label={
                        <FormattedMessage
                            id="educationalInstitution"
                            defaultMessage="Educational institution"
                        />
                    }
                    rules={[
                        {
                            required: true,
                            message: (
                                <FormattedMessage
                                    id="educationalInstitutionMandatory"
                                    defaultMessage="Educational institution is mandatory"
                                />
                            ),
                        },
                    ]}
                >
                    <Input
                        onChange={e => setInstitution(e.target.value)}
                        placeholder={props.intl.formatMessage(
                            messages.educatioanlInstitution
                        )}
                    />
                </FormItem>
                <FormItem
                    name="Type of institution"
                    label={
                        <FormattedMessage
                            id="educationalInstitutionType"
                            defaultMessage="Type of education"
                        />
                    }
                    rules={[
                        {
                            required: true,
                            message: (
                                <FormattedMessage
                                    id="educationalInstitutionTypeMandatory"
                                    defaultMessage="Type of institution is mandatory"
                                />
                            ),
                        },
                    ]}
                >
                    <Select
                        showArrow={true}
                        onChange={e => setEducationType(e)}
                        placeholder={props.intl.formatMessage(
                            messages.educatioanlInstitutionType
                        )}
                    >
                        <Option id={0} value={0}>
                            {props.intl.formatMessage(messages.highSchool)}
                        </Option>
                        <Option id={1} value={1}>
                            {props.intl.formatMessage(messages.college)}
                        </Option>
                        <Option id={2} value={2}>
                            {props.intl.formatMessage(messages.university)}
                        </Option>
                        <Option id={3} value={3}>
                            {props.intl.formatMessage(messages.apprenticeship)}
                        </Option>
                    </Select>
                </FormItem>
                <FormItem
                    name="professional"
                    label={
                        <div>
                            <FormattedMessage
                                id="filter.proffesional"
                                defaultMessage="Proffesional"
                            />
                            <Popover content={content}>
                                <InfoCircleOutlined id="info-icon" />
                            </Popover>
                        </div>
                    }
                >
                    <Select
                        onChange={e => {
                            setIsProfessional(e);
                        }}
                        placeholder={
                            <FormattedMessage
                                id="filter.proffesional"
                                defaultMessage="Proffesional"
                            />
                        }
                        showArrow={true}
                    >
                        <Option value={true}>
                            <FormattedMessage id="yes" />
                        </Option>
                        <Option value={false}>
                            <FormattedMessage id="no" />
                        </Option>
                    </Select>
                </FormItem>
                <FormItem
                    name="date"
                    label={
                        <FormattedMessage
                            id="date"
                            rules={[
                                {
                                    required: true,
                                    message: (
                                        <FormattedMessage
                                            id="dateField"
                                            defaultMessage="Date field is mandatory"
                                        />
                                    ),
                                },
                            ]}
                        />
                    }
                >
                    <RangePicker
                        format="DD/MM/YYYY"
                        disabledDate={e => e && e > moment().endOf('day')}
                        onChange={values => {
                            setStartDate(values[0].format('YYYY-MM-DD'));
                            setEndDate(values[1].format('YYYY-MM-DD'));
                        }}
                        placeholder={[
                            props.intl.formatMessage(messages.startDate),
                            props.intl.formatMessage(messages.endDate),
                        ]}
                    />
                </FormItem>
                <FormItem>
                    <div className="submit-container">
                        {props.numOfEducations !== 0 ? (
                            <Button
                                className="plain-button"
                                onClick={props.handleClicks}
                            >
                                <LeftOutlined />
                                <FormattedMessage id="back" />
                            </Button>
                        ) : (
                                ''
                            )}

                        <Button htmlType="submit" className="button">
                            <FormattedMessage id="newExperience.submitButton" />
                        </Button>
                    </div>
                </FormItem>
            </Form>
        </div> : <FormattedMessage
            id="missingInformation"
            defaultMessage="This information is missing." />
    ); 
}

export default injectIntl(NewEducation);
