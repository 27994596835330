import React, { Component } from "react";
import { Form, Input, Button, Divider, Checkbox } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { clearLoginError } from "../../actions/authentificationActions";
import "./LoginForm.less";

const FormItem = Form.Item;

class NormalLoginForm extends Component {
    constructor(props) {
        super(props);
        this.props.actions.clearLoginError();
    }

    state = { capsLockOn: false };

    onFinish = (values) => {
        this.props.onSubmit(values);
    };

    checkCapsLock(e) {
        if (e.getModifierState) {
            if (e.getModifierState("CapsLock"))
                this.setState({ capsLockOn: true });
            else this.setState({ capsLockOn: false });
        }
    }

    render() {
        return (
            <Form onFinish={this.onFinish} className="login-form">
                <FormItem
                    className="form-item-container"
                    name="username"
                    rules={[
                        { required: true, message: "Email field is mandatory" },
                        { type: "email", message: "Not a valid email" },
                    ]}
                >
                    <Input
                        prefix={<UserOutlined />}
                        type="Email"
                        placeholder="Email"
                    />
                </FormItem>
                <FormItem
                    className="form-item-container"
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: "Password field is mandatory",
                        },
                    ]}
                >
                    <Input.Password
                        prefix={<LockOutlined />}
                        type="password"
                        placeholder="Password"
                        onKeyDown={(e) => this.checkCapsLock(e)}
                        onBlur={(e) => this.checkCapsLock(e)}
                    />
                </FormItem>
                <Link to={"/ForgotPassword"}>Forgot password?</Link>
                <FormItem className="form-item-container">
                    <p id="server-error" className="ant-form-explain">
                        {this.props.serverError
                            ? this.props.serverError.toString()
                            : null}
                        {this.state.capsLockOn && <span>Caps lock is on</span>}
                    </p>
                    <Checkbox className="form-item-container">
                        Remember me
                    </Checkbox>
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="login-button"
                        size="large"
                        loading={this.props.loading}
                        block
                    >
                        {this.props.loading ? "Logging in" : "Log in"}
                    </Button>
                    <Divider>OR</Divider>
                    <Link to={"/Register"}>
                        <Button className="register-button" size="large" block>
                            Register now
                        </Button>
                    </Link>
                </FormItem>
            </Form>
        );
    }
}

function mapStateToProps(state) {
    const { currentlySending } = state.login;
    return { loading: currentlySending };
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators({ clearLoginError }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(NormalLoginForm);
