import React, { useState, useEffect, useCallback } from "react";
import {
    getApplicationsCompany,
    markAllApplicationsCompanyAsRead,
} from "../../services/jobApplicationService";
import ApplicantCard from "../JobOffer/ApplicantCard";
import { Pagination, Collapse, Button, Select, Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { withLoader } from "../../components/WithLoader/withLoader";
import { getJobOffersWithoutPagination } from "../../services/jobOfferService";
import { FormattedMessage } from "react-intl";
import { RemoveUnreadJobApplications } from "../../actions/jobApplicationAction";
import "./JobApplications.less";

function JobApplicationCompany({ setLoading }) {
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState();
    const [jobApplications, setJobApplications] = useState([]);
    const [companyJobOffers, setCompanyJobOffers] = useState([]);
    const user = useSelector(state => state.user);
    const newApplications = useSelector(state => state.jobApplication);
    const searchParamsCompany = new URLSearchParams();
    const [collapseOpen, setCollapseOpen] = useState([]);
    const dispatch = useDispatch();

    const { Panel } = Collapse;
    const { Option } = Select;

    let markApplicationsAsRead = useCallback(() => {
        markAllApplicationsCompanyAsRead(user);
    }, [user]);

    let fetchJobApplications = useCallback(() => {
        setLoading(true);
        getApplicationsCompany(user, page, searchParamsCompany).then(ja => {
            setJobApplications(ja.items);
            setTotal(ja.total);
            setLoading(false);
        });
    }, [setLoading, user, page]);

    useEffect(() => {
        markApplicationsAsRead();
        if (newApplications.length !== 0) dispatch(RemoveUnreadJobApplications());
    }, [markApplicationsAsRead, newApplications.length, dispatch]);

    useEffect(() => {
        fetchJobApplications();
    }, [fetchJobApplications]);

    useEffect(() => {
        let fetchCompanyJobOffers = () => {
            searchParamsCompany.append("Companies", user.info);

            getJobOffersWithoutPagination(user, searchParamsCompany).then(j => {
                setCompanyJobOffers(j.items);
            });
        };

        fetchCompanyJobOffers();
    }, [user]);

    let onPageChange = page => {
        setPage(page);
    };

    let onJobOfferSelect = value => {
        if (value === undefined) {
            searchParamsCompany.append("jobOffer", "00000000-0000-0000-0000-000000000000");
        }
        searchParamsCompany.append("jobOffer", value);
    };

    let filter = () => {
        fetchJobApplications();
        setCollapseOpen([]);
    };

    const handleCollapse = () => {
        collapseOpen.length === 0 ? setCollapseOpen(["0"]) : setCollapseOpen([]);
    };

    return (
        <div className="job-applications-company-container">
            <Collapse activeKey={collapseOpen} onChange={handleCollapse}>
                <Panel header="Filter">
                    <Form.Item>
                        <div className="filter-section-container">
                            <p className="bolded-text-filter">
                                <FormattedMessage id="jobOffer" defaultMessage="Job offer" />:
                            </p>
                            <Select
                                placeholder={
                                    <FormattedMessage
                                        id="chooseJobOffer"
                                        defaultMessage="Choose job offer"
                                    />
                                }
                                showArrow={true}
                                allowClear={true}
                                onChange={onJobOfferSelect}
                            >
                                {companyJobOffers.map(companyJobOffer => (
                                    <Option key={companyJobOffer.id} value={companyJobOffer.id}>
                                        {companyJobOffer.title}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                    </Form.Item>
                    <Button className="button" onClick={filter}>
                        <FormattedMessage id="filter.btnFilter" />
                    </Button>
                </Panel>
            </Collapse>
            <div>
                <div className="job-applications-container">
                    {jobApplications.map(jobApplication => (
                        <ApplicantCard
                            key={jobApplication.created}
                            employeeGuid={jobApplication.employeeId}
                            created={jobApplication.created}
                            jobOffer={jobApplication.jobOffer}
                            user={user}
                            description={jobApplication.description}
                        />
                    ))}
                </div>
                <div className="pagination-container">
                    <Pagination
                        current={page}
                        total={total}
                        hideOnSinglePage={false}
                        onChange={onPageChange}
                    />
                </div>
            </div>
        </div>
    );
}

export default withLoader(JobApplicationCompany);
