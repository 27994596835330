import { getAuthorizationProperties } from './authorizationService';

export function getTags(user, guids) {
    if (guids == null) {
        return fetch('/api/Tags', {
            method: 'GET',
            ...getAuthorizationProperties(user.token, {
                'Content-Type': 'application/json'
            })
        }).then(response => response.json());
    } else {
        var searchParams = new URLSearchParams();
        guids.forEach(guid => {
            searchParams.append('guid', guid);
        });
        searchParams.toString();
        return fetch(`/api/Tags?${searchParams}`, {
            method: 'GET',
            ...getAuthorizationProperties(user.token, {
                'Content-Type': 'application/json'
            })
        }).then(response => response.json());
    }
}

export function searchTags(user, value) {
    return fetch(`/api/tags/search?value=${value}`, {
        method: 'GET',
        ...getAuthorizationProperties(user.token, {
            'Content-Type': 'application/json'
        })
    }).then(response => response.json());
}

export function postTag(user, value) {
    return fetch('/api/Tags', {
        method: 'POST',
        ...getAuthorizationProperties(user.token, {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }),
        body: JSON.stringify(value),
    }).then((response) => response.json());
}