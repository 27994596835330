import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import CustomCookies from "./containers/CustomCookies/CustomCookies";
import CustomFooter from "./containers/CustomFooter/CustomFooter";
import CustomHeader from "./containers/CustomHeader/CustomHeader";
import CustomSider from "./containers/CustomSider/CustomSider";
import CustomContent from "./containers/CustomContent/CustomContent";
import { Layout } from "antd";
import "./App.css";

const { Header, Content, Sider, Footer } = Layout;

class App extends Component {
    render() {
        return (
            <Layout>
                <Header className="header">
                    <CustomHeader />
                </Header>
                <Layout className="layout">
                    <Sider className={"sider " + (this.props.menuOpen ? "menu-open" : "")}>
                        <CustomSider />
                    </Sider>
                    <Layout className="main-content">
                        <Content>
                            <CustomContent />
                        </Content>
                    </Layout>
                </Layout>
                <Footer className="footer">
                    <CustomFooter />
                </Footer>
                <CustomCookies />
            </Layout>
        );
    }
}

function mapStateToProps(state) {
    const menuOpen = state.menu;
    return { menuOpen };
}

export default withRouter(connect(mapStateToProps)(App));
