import React from 'react';
import { useSelector } from 'react-redux';
import JobApplicationsEmployee from './JobApplicationsEmployee';
import JobApplicationCompany from './JobApplicationsCompany';
import { FormattedMessage } from 'react-intl';

function JobApplications() {
    const role = useSelector((state) => state.user.role);

    return (
        <React.Fragment>
            <h2 className='job-applications-heading'><FormattedMessage
                id="jobApplications"
                defaultMessage="Job applications"
            /></h2>
            <div>
                {role === 'Employee' ? (
                    <JobApplicationsEmployee />
                ) : (
                    <JobApplicationCompany />
                )}
            </div>
        </React.Fragment>
    );
}

export default JobApplications;
