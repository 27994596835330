import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ExperienceCard from './ExperienceCard';
import { getExperiences } from '../../services/experienceService';
import { withLoader } from '../../components/WithLoader/withLoader';
import CogoMasonry from '../../components/CogoMasonry/CogoMasonry';
import './Experiences.less';

function Experiences({setLoading, employeeId, handleEdit, handleExperienceDetails}) {
    const [experiences, setExperiences] = useState([]);
    const [error, setError] = useState("");
    const user = useSelector(state => state.user);

    useEffect(() => {
        setLoading(true);
        let fetchExperiences = () => {
            getExperiences(user, employeeId).then(e => {
                setExperiences(e);
                setLoading(false);
            }).catch(e => setError(e.message));
        };
        fetchExperiences();
    }, [user, setLoading, employeeId]);

    return (
        <div className="experiences-container">
            {error ? <p>{error}</p> : <CogoMasonry>
                {experiences.map(experience => (
                    <ExperienceCard
                        experience={experience}
                        key={experience.id}
                        handleEdit={handleEdit}
                        handleExperienceDetails={handleExperienceDetails}
                        employeeId={employeeId}
                    />
                ))}
            </CogoMasonry>}
        </div>
    );
}

export default withLoader(Experiences);
