import React from "react";
import { useSelector } from "react-redux";
import avatar from "./Companies_avatar.svg";
import Image from "../Image/Image";

function CompanyAvatar(props) {
    const userInfo = useSelector((state) => state.user.info);
    const imageGuid =
        props.companyId !== undefined ? props.companyId : userInfo;

    return (
        <div>
            <Image placeholder={avatar} imageId={imageGuid} />
        </div>
    );
}

export default CompanyAvatar;
